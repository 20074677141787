import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { OnRowAction } from "./types";

interface Props {
  onAction: OnRowAction;
}

export const RowMenu: React.FC<Props> = (props) => {
  return (
    <Menu as="div" className="relative flex-shrink-0">
      <Menu.Button className="cursor-pointer p-1 rounded transition-all hover:bg-gray-100">
        <EllipsisVerticalIcon className="h-6" />
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="bg-white border p-1 rounded absolute top-4 right-3 shadow-lg">
          <Menu.Item
            as="div"
            className="flex items-center gap-2 px-4 py-1 rounded cursor-pointer hover:bg-surface-interactive-accent-3 text-text-accent-1 whitespace-nowrap"
            onClick={() => props.onAction("delete")}
          >
            <XMarkIcon className="h-4" />
            <h4>Delete Row</h4>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
RowMenu.displayName = "Dispute.Confirmation.RowMenu";
