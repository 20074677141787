import { Options } from "@segment/analytics-next";
import { PerDiemInvoiceContainerGroup, PerDiemInvoiceGroup, ShipmentGroup } from "./groups";

export enum AnalyticsEvent {
  SendFeedback = "Clicked Send Feedback CTA",
  GoToBlueCargo = "Clicked Go to BlueCargo CTA",
  VerifiedInvoice = "Verified Invoice",
  ContainerDisputeEmailGenerated = "Container Dispute Email Generated",
  EmailViewedWithPlugIn = "Email Viewed with Plug In",
  UploadedInvoice = "Uploaded Invoice",
}

export type EventOptions = Partial<{
  includeUser: boolean;
  includeCompany: boolean;
  slack: boolean;
}>;

type ApplicationSource = "email plug-in" | "ui";

export type EventProperties = {
  [AnalyticsEvent.SendFeedback]: undefined;
  [AnalyticsEvent.GoToBlueCargo]: Partial<PerDiemInvoiceGroup & { containers: PerDiemInvoiceContainerGroup[] }>;
  [AnalyticsEvent.VerifiedInvoice]: {
    invoiceVerifiedSource: ApplicationSource;
    invoice: PerDiemInvoiceGroup;
    containers: (PerDiemInvoiceContainerGroup & ShipmentGroup)[];
  };
  [AnalyticsEvent.ContainerDisputeEmailGenerated]: {
    disputeGeneratedSource: ApplicationSource;
    invoice: PerDiemInvoiceGroup;
    containers: (PerDiemInvoiceContainerGroup & ShipmentGroup)[];
  };
  [AnalyticsEvent.EmailViewedWithPlugIn]: { emailId: string };
  [AnalyticsEvent.UploadedInvoice]: {
    disputeType: string;
    uploadType: string;
    invoiceUploadSource: ApplicationSource;
  };
};

export type AnalyticsOptions = Partial<{
  /** Event level options */
  event: EventOptions;
  /** Segment specfic options */
  segment: Options;
}>;
