import React from "react";
import { useUserContext } from "../../layout/AuthProvider/context";
import { User } from "../../types";

/**
 * The available permissions
 */
export enum Permission {
  EmptyReceivingSchedule = "EMPTY_RECEIVING_SCHEDULE",
  EmptyReturnLocationDownload = "EMPTY_RETURN_LOCATION_DOWNLOAD",
  EmptyReturnLocationView = "EMPTY_RETURN_LOCATION_VIEW",
  FreeTable = "FREE_TABLE",
  FullTagManagementSystem = "FULL_TAG_MANAGEMENT_SYSTEM",
  GateSchedule = "GATE_SCHEDULE",
  NotificationAppointmentsRelease = "NOTIFICATION_APPOINTMENTS_RELEASE",
  NotificationDailyContainerReports = "NOTIFICATION_DAILY_CONTAINER_REPORTS",
  NotificationGateSchedule = "NOTIFICATION_GATE_SCHEDULE",
  NotificationImpossibleToReturn = "NOTIFICATION_IMPOSSIBLE_TO_RETURN",
  NotificationReturnLocationUpdates = "NOTIFICATION_RETURN_LOCATION_UPDATES",
  PerDiemArchive = "PER_DIEM_ARCHIVE",
  PerDiemDisputeTracker = "PER_DIEM_DISPUTE_TRACKER",
  PerDiemSmartPerDiemDownload = "PER_DIEM_SMART_PER_DIEM_DOWNLOAD",
  PerDiemSmartPerDiemView = "PER_DIEM_SMART_PER_DIEM_VIEW",
  PlanAndPricing = "PLAN_AND_PRICING",
  PlanAndPricingContactSales = "PLAN_AND_PRICING_CONTACT_SALES",
  ShipmentWatchList = "SHIPMENT_WATCH_LIST",
  ShipmentWatchListContract = "SHIPMENT_WATCH_LIST_CONTRACT",
  ShipmentWatchListDashboard = "SHIPMENT_WATCH_LIST_DASHBOARD",
  ShipmentWatchListDndFees = "SHIPMENT_WATCH_LIST_DND_FEES",
  ShipmentWatchListReports = "SHIPMENT_WATCH_LIST_REPORTS",
  ShipmentWatchListTabHistory = "SHIPMENT_WATCH_LIST_TAB_HISTORY",
  ShipmentWatchListTabSmartPerDiem = "SHIPMENT_WATCH_LIST_TAB_SMART_PER_DIEM",
  TerminalCredentials = "TERMINAL_CREDENTIALS",
  TerminalScreenshots = "TERMINAL_SCREENSHOT",
  TrackNTrace = "TRACK_N_TRACE",
  TrackNTracePickupTrackChanges = "TRACK_N_TRACE_PICKUP_TRACK_CHANGES",
  VesselSchedule = "VESSEL_SCHEDULE",
}

export type PermissionOverride = boolean | (() => boolean);

interface DetailedPermissionRequirement {
  and?: Permission[];
  or?: Permission[];
}

/**
 * An array of permissions that are AND together,
 * or an object with an optional `and` and/or `or` property
 * that both get anded together
 */
export type PermissionRequirement = Permission[] | DetailedPermissionRequirement;

const checkIndividualPermission: (permission: Permission, permissions: Permission[]) => boolean = (
  permission,
  permissions
) => {
  return permissions.includes(permission);
};

export const checkForPermission: (
  requirement: PermissionRequirement | undefined,
  permissions: Permission[]
) => boolean = (requirement, permissions) => {
  if (!requirement) {
    return true;
  }

  if (Array.isArray(requirement)) {
    return requirement.every((permission) => checkIndividualPermission(permission, permissions));
  }

  const { and, or } = requirement;
  return (
    (!or || or.some((permission) => checkIndividualPermission(permission, permissions))) &&
    (!and || and.every((permission) => checkIndividualPermission(permission, permissions)))
  );
};

export const getPermissions = (user: User | null) => {
  const companyPermissions = user?.company?.permissions || [];
  const userPermissions = user?.extraPermissions || [];
  // Combine arrays and remove duplicates
  return Array.from(new Set([...companyPermissions, ...userPermissions]));
};

/**
 * A hook that returns a list of all permissions the user has
 */
export const usePermissions = (): Permission[] => {
  const { user } = useUserContext();
  return React.useMemo(() => getPermissions(user), [user]);
};

/**
 * A hook that returns a boolean indicating whether the user has the requested permission
 */
export const useHasPermission = (requirement?: PermissionRequirement): boolean => {
  const permissions = usePermissions();

  if (!requirement) {
    return true;
  }

  return checkForPermission(requirement, permissions);
};
