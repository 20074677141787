import * as React from "react";
import { createEmptyInvoice, createEmptyInvoiceContainer, createEmptyRate } from "./utility";
import { InvoiceSection } from "./InvoiceSection";
import { Button } from "../../components/Button";
import { useHandleSaveMappings, useValidateInvoices } from "./hooks";
import { useQuery } from "@tanstack/react-query";
import { DisputesQueryKey, fetchInvoiceUploadJob } from "../../services/disputes";
import { DisputeState, Invoice } from "../../types/dispute";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isAxiosError } from "axios";
import { Routes } from "../../lib/routes";

export const Verification: React.FC = () => {
  const { invoiceJobId } = useParams();
  const navigate = useNavigate();

  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const jobQuery = useQuery({
    queryKey: [DisputesQueryKey.InvoiceJob, invoiceJobId],
    queryFn: ({ signal }) => fetchInvoiceUploadJob(invoiceJobId || "", signal),
    enabled: !!invoiceJobId,
    onSuccess: (data) => {
      // If there are no parsed invoices, create an empty invoice and its children
      if (!data.parsedInvoices.length) {
        setInvoices([createEmptyInvoice(invoiceJobId || "")]);
        return;
      }

      // If any of the containers are not in the verifying state, continue to the dispute opportunity page
      if (
        data.parsedInvoices.some((invoice) =>
          invoice.invoiceContainers.some(
            (container) => container.dispute?.status.status !== DisputeState.VerifyingInvoice
          )
        )
      ) {
        const to = generatePath(Routes.DisputeOpportunity, { invoiceJobId: invoiceJobId as string });
        navigate(to);
      }

      setInvoices(
        data.parsedInvoices.map((i) => ({
          ...i,
          // If there are no invoice containers, create an empty invoice container and its children
          invoiceContainers: i.invoiceContainers.length
            ? i.invoiceContainers.map((ic) => ({
                ...ic,
                // If there are no paid rates, add one
                rates:
                  ic.rates && ic.rates.some((r) => !r.isFreeTime)
                    ? ic.rates
                    : [createEmptyRate(ic.id), ...(ic.rates || [])],
              }))
            : [createEmptyInvoiceContainer(i.id)],
        }))
      );
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { validity, canSave } = useValidateInvoices(invoices);

  const [save, loading, error] = useHandleSaveMappings(undefined, invoices, () =>
    navigate(generatePath(Routes.DisputeOpportunity, { invoiceJobId: invoiceJobId as string }))
  );

  if (jobQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <h4>Loading…</h4>
      </div>
    );
  }

  const errorData =
    error && isAxiosError(error) ? error.response?.data?.non_field_errors || error.response?.data : error;

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 space-y-6 flex-grow overflow-auto">
        {invoices.map((invoice, i) => (
          <InvoiceSection
            key={`${invoice.id}_${i}`}
            invoice={invoice}
            onChange={(invoice) => {
              const newInvoices = [...invoices];
              newInvoices[i] = invoice;
              setInvoices(newInvoices);
            }}
            validity={validity[i]}
          />
        ))}
      </div>
      <div className="w-full px-8 py-4 flex-shrink-0 shadow border-t space-y-2">
        <Button className="w-full" onClick={save} disabled={!canSave} loading={loading}>
          <h4 className="font-medium m-0">Save and continue</h4>
        </Button>
        {error ? (
          <div className="max-h-[100px] overflow-auto space-y-2">
            <h4 className="text-text-informative-critical">Error saving invoices ({invoiceJobId}).</h4>
            <p className="text-sm text-text-informative-critical">{JSON.stringify(errorData)}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
Verification.displayName = "Verification";
