import * as React from "react";
import { ComplianceStatus, ContainerViolation, ViolationType } from "../../../../types/dispute";

interface Props {
  status: ComplianceStatus;
  violation: ContainerViolation;
}

const Label = ({ children }: { children: React.ReactNode }) => (
  <h5 className="font-medium text-xs text-text-secondary">{children}</h5>
);

export const Summary: React.FC<Props> = ({ status, violation }) => {
  switch (status) {
    case ComplianceStatus.NonCompliant:
      switch (violation.type) {
        case ViolationType.InvoiceIssueDateTooLate:
          return <Label>Invoice was issued &gt;60 days after Gate In date.</Label>;
        case ViolationType.InvoiceIssueDateMissing:
          return <Label>Invoice is missing issue date.</Label>;
        default:
          return null;
      }
    case ComplianceStatus.Skipped:
      return <Label>Unable to validate. Container not in Shipment Watchlist.</Label>;
    case ComplianceStatus.Compliant:
    default:
      return null;
  }
};
Summary.displayName = "ContainerDispute.Edit.Summary.UIIA.Summary";
