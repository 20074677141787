import * as React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import * as Page from "./pages";
import { App } from "./layout";
import { FooterWrapper } from "./layout/FooterWrapper";
import { Routes } from "./lib/routes";

export const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: Routes.Root,
        element: <Navigate to={Routes.Read} replace={true} />,
      },
      {
        path: Routes.Read,
        element: (
          <FooterWrapper>
            <Page.Read />
          </FooterWrapper>
        ),
      },
      {
        path: Routes.Process,
        element: (
          <FooterWrapper>
            <Page.Process />
          </FooterWrapper>
        ),
      },
      {
        path: Routes.DisputeOpportunity,
        element: <Page.Opportunity />,
      },
      {
        path: Routes.Verify,
        element: <Page.Verification />,
      },
      {
        path: Routes.ContainerDetails,
        element: <Page.ContainerDetails />,
      },
      {
        path: Routes.AlreadySent,
        element: (
          <FooterWrapper>
            <Page.AlreadySent />
          </FooterWrapper>
        ),
      },
    ],
  },
];
