import { AxiosResponse } from "axios";
import { client } from "./client";
import { objectKeysToCamelCase } from "../lib/common";
import { Port } from "../types/port";

export enum PortQueryKeys {
  Ports = "ports",
}

export async function fetchPorts(values: { signal?: AbortSignal }) {
  const { signal } = values;
  const response: AxiosResponse = await client.get("/core/api/v1/erl/ports", {
    signal,
  });
  const ports: Port[] = objectKeysToCamelCase(response.data);
  return ports;
}
