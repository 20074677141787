import * as React from "react";
import { FooterMenu } from "./FooterMenu";

export const Footer: React.FC = () => {
  return (
    <div className="px-4 py-2 flex-shrink-0 flex flex-row-reverse border-t relative">
      <FooterMenu />
    </div>
  );
};
Footer.displayName = "Footer";
