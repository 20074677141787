import * as React from "react";
import { ErrorType, SignIn } from "./SignIn";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserQuery, fetchProfile, signIn } from "../../services/user";
import { AuthContext } from "./context";
import { LocalStorageKey, LocalStorageUtil } from "../../lib/local-storage";
import { isAxiosError } from "axios";
import { Footer } from "../Footer";
import { useIdentifyAnalytics } from "../../lib/segment";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { LDGate } from "./LDGate";
import _ from "lodash";

interface Props {
  children: React.ReactNode;
}

/**
 * This component serves as both a sign in guard and a
 * context provider for our user auth.
 */
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [error, setError] = React.useState<ErrorType | undefined>(undefined);
  const queryClient = useQueryClient();
  const signInMutation = useMutation({
    mutationKey: [UserQuery.SignIn],
    mutationFn: signIn,
    onMutate: () => {
      setError(undefined);
    },
    onSuccess: (data) => {
      const { accessToken, refreshToken, email } = data;
      LocalStorageUtil.set(LocalStorageKey.AccessToken, accessToken);
      LocalStorageUtil.set(LocalStorageKey.RefreshToken, refreshToken);
      LocalStorageUtil.set(LocalStorageKey.Email, email);
      queryClient.invalidateQueries([UserQuery.Profile]);
    },
    onError: (e: any) => {
      if (isAxiosError(e)) {
        const { non_field_errors } = e.response?.data;
        if (non_field_errors) {
          // incorrect email
          if (non_field_errors.includes("no_account")) {
            setError({ field: "email", message: "Email is not valid." });
            return;
          }
          // incorrect password
          if (non_field_errors.includes("invalid_password")) {
            setError({
              field: "password",
              message: "Password is not valid.",
            });
            return;
          }
          // deactivated
          if (non_field_errors.includes("deactivated")) {
            setError({ field: "email", message: "This email has been deactivated." });
            return;
          }
        }
      }

      setError({ message: "An unknown error occurred." });
    },
  });

  const accessToken: string | null = LocalStorageUtil.get(LocalStorageKey.AccessToken);

  const userQuery = useQuery({
    queryKey: [UserQuery.Profile, accessToken],
    queryFn: ({ signal }) => fetchProfile(signal),
    retry: false,
    enabled: !!accessToken,
  });

  const loading = signInMutation.isLoading || (userQuery.isLoading && !!accessToken);

  const signOut = React.useCallback(() => {
    LocalStorageUtil.remove(LocalStorageKey.AccessToken);
    LocalStorageUtil.remove(LocalStorageKey.RefreshToken);
    LocalStorageUtil.remove(LocalStorageKey.Email);
    queryClient.resetQueries([UserQuery.Profile]);
  }, [queryClient]);

  const user = userQuery.data || null;

  // identify user for analytics whenever it changes
  useIdentifyAnalytics(user);

  const LDClient = useLDClient();
  if (user && LDClient) {
    // @ts-expect-error
    LDClient.identify({
      key: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      custom: {
        company: _.get(user, "company.scac", null),
        pack: _.get(user, "company.pack", null),
        companyType: _.get(user, "company.type", null),
        isCompanyAdmin: _.get(user, "is_admin", false),
      },
    });
  }

  if (!user) {
    return (
      <div className="bg-white h-[100vh] w-[100vw] flex flex-col overflow-hidden">
        <SignIn
          loading={loading}
          onSubmit={(email, password) => signInMutation.mutate({ email, password })}
          error={error}
        />
        <Footer />
      </div>
    );
  }

  return (
    <LDGate>
      <AuthContext.Provider value={{ user, signOut }}>{children}</AuthContext.Provider>
    </LDGate>
  );
};
