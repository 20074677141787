import { flip, offset, shift, useFloating, useHover, useInteractions } from "@floating-ui/react";
import * as React from "react";

interface Props {
  children: React.ReactNode;
  tooltip: React.ReactNode;
}

export const Tooltip: React.FC<Props> = ({ children, tooltip }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={floatingStyles}
          className="rounded bg-gray-400 shadow-md px-2 py-1 z-10"
        >
          {tooltip}
        </div>
      )}
    </>
  );
};
Tooltip.displayName = "Tooltip";
