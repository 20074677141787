import * as React from "react";
import { BilledRates, FreeRate } from "./Rates";
import { InvoiceContainer } from "../../../../types/dispute";
import { createEmptyRate } from "../../utility";
import { ContainerForm } from "./ContainerForm";
import { RowMenu } from "../../RowMenu";
import { Action, ContainerValidity } from "../../types";

interface Props {
  invoiceContainer: InvoiceContainer;
  showMenu: boolean;
  onAction: (action: Action) => void;
  onChange: (invoiceContainer: InvoiceContainer) => void;
  validity?: ContainerValidity;
}

export const ContainerInfo: React.FC<Props> = ({ invoiceContainer, showMenu, onAction, onChange, validity }) => {
  const { free, billed } = React.useMemo(() => {
    const freeRates = invoiceContainer.rates.filter((rate) => rate.isFreeTime);
    const billedRates = invoiceContainer.rates.filter((rate) => !rate.isFreeTime);
    const freeValidity = validity?.rates?.filter((rate) => rate.isFreeTime) || [];
    const billedValidity = validity?.rates?.filter((rate) => !rate.isFreeTime) || [];
    return {
      free: { rates: freeRates, validity: freeValidity },
      billed: { rates: billedRates, validity: billedValidity },
    };
  }, [invoiceContainer]);

  return (
    <div className="space-y-4 pb-4 border-b">
      <div className="flex gap-2 items-center justify-between">
        <h5 className="m-0 uppercase text-text-subdued">Container Information</h5>
        {showMenu && <RowMenu onAction={onAction} />}
      </div>
      <ContainerForm invoiceContainer={invoiceContainer} onChange={onChange} validity={validity} />
      <FreeRate
        rates={free.rates}
        onChange={(rates) => onChange({ ...invoiceContainer, rates: [...rates, ...billed.rates] })}
        timeMode={invoiceContainer.freeTimeDaysMode}
        onTimeModeChange={(freeTimeDaysMode) => onChange({ ...invoiceContainer, freeTimeDaysMode })}
        validity={free.validity}
      />
      <BilledRates
        rates={billed.rates}
        onChange={(rates) => onChange({ ...invoiceContainer, rates: [...free.rates, ...rates] })}
        addRate={() =>
          onChange({ ...invoiceContainer, rates: [...invoiceContainer.rates, createEmptyRate(invoiceContainer.id)] })
        }
        timeMode={invoiceContainer.chargeTimeDaysMode}
        onTimeModeChange={(chargeTimeDaysMode) => onChange({ ...invoiceContainer, chargeTimeDaysMode })}
        validity={billed.validity}
      />
    </div>
  );
};
ContainerInfo.displayName = "Verification.ContainerInfo";
