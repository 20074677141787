import * as React from "react";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { Input, InputProps } from "./Input";

export type LabelProps = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export interface LabeledInputProps {
  inputProps?: Omit<InputProps, "onChange" | "value">;
  labelProps?: LabelProps;
  label: React.ReactNode;
  className?: string;
  value: number;
  onChange?: (value: number) => void;
  /** Used for manipulating the value shown to the user */
  formatter: (value: number) => string;
}

export const LabeledFormattedNumericInput: React.FC<LabeledInputProps> = (props) => {
  const { inputProps, labelProps, label, className, value, onChange, formatter } = props;
  const id = uniqueId("labeled-input");

  const [rawValue, setRawValue] = React.useState<string | null>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const raw = e.target.value;

    // Remove everything that isn't a number or a decimal point
    const cleaned = raw.replace(/[^\d.]/g, "");
    const parsedValue = parseFloat(cleaned);
    if (!Number.isNaN(parsedValue)) {
      onChange?.(parsedValue);
    }
    setRawValue(raw);
  };

  const formattedValue = React.useMemo(() => formatter(value), [formatter, value]);
  const showRaw = rawValue !== null && !!onChange;

  return (
    <div className={classNames("flex flex-col gap-1", className)}>
      <label {...labelProps} htmlFor={id}>
        {label}
      </label>
      <Input
        {...inputProps}
        id={id}
        onBlur={() => setRawValue(null)}
        onFocus={(e) => {
          setRawValue(value === 0 ? "" : formattedValue);
          e.target.setSelectionRange(0, formattedValue.length);
        }}
        onChange={handleChange}
        value={showRaw ? rawValue : formattedValue}
        type="text"
      />
    </div>
  );
};
