import * as React from "react";
import classNames from "classnames";

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  classes?: {
    root?: string;
    input?: string;
  };
}

export const Input: React.FC<InputProps> = (props) => {
  const { classes, startAdornment, endAdornment, value, ...rest } = props;
  return (
    <div
      className={classNames(
        "border rounded flex items-center focus-within:border-blue-800 overflow-hidden bg-white",
        classes?.root
      )}
    >
      {startAdornment && <div className="flex-shrink-0 ml-2">{startAdornment}</div>}
      <input
        className={classNames(
          "focus:ring-0 border-none outline-none flex-grow flex-shrink focus:border-none focus:outline-none min-w-[0] disabled:text-text-disabled-accent-1 h-8 px-2 m-0",
          classes?.input
        )}
        value={value === undefined ? "" : value}
        {...rest}
      />
      {endAdornment && <div className="flex-shrink-0 mr-2">{endAdornment}</div>}
    </div>
  );
};
Input.displayName = "TailwindUI.Input";
