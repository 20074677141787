import { Combobox } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import _ from "lodash";
import { ComboboxSelector } from "./Selector";
import { ShippingLine } from "../../types/shipping-line";
import { ShippingLineFilters, ShippingLineQueryKeys, fetchShippingLines } from "../../services/shipping-line";

interface Props {
  value: string;
  onChange: (value: string) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  shippingLineFilters?: ShippingLineFilters;
  label?: React.ReactNode;
  className?: string;
  onSuccess?: (shippingLines: ShippingLine[]) => void;
  id?: string;
  disabled?: boolean;
}

export const ShippingLineSelector: React.FC<Props> = (props) => {
  const { value, onChange, onSuccess, shippingLineFilters, id, disabled } = props;
  const [filter, setFilter] = React.useState("");

  const shippingLines = useQuery(
    [ShippingLineQueryKeys.ShippingLines, shippingLineFilters],
    ({ signal }) => fetchShippingLines({ signal, ...shippingLineFilters }),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
    }
  );

  const filteredItems = React.useMemo(() => {
    if (!shippingLines.data) {
      return [];
    }

    const data = _.orderBy([...shippingLines.data], "name").filter((size) => {
      if (!filter) {
        return true;
      }

      return (
        size.name.toLowerCase().includes(filter.toLowerCase()) || size.key.toLowerCase().includes(filter.toLowerCase())
      );
    });

    return data.map((containerSize) => {
      return (
        <Combobox.Option
          key={containerSize.key}
          value={containerSize.key}
          className="rounded cursor-pointer hover:bg-surface-interactive-accent-3 py-1 px-2"
        >
          {containerSize.name}
        </Combobox.Option>
      );
    });
  }, [shippingLines, filter]);

  return (
    <ComboboxSelector
      id={id}
      label={props.label || "Shipping Line"}
      value={value}
      onChange={onChange}
      disabled={shippingLines.isLoading || disabled}
      onFilter={(f) => setFilter(f)}
      placeholder="Shipping Line"
      startAdornment={props.startAdornment}
      endAdornment={props.endAdornment}
      displayValue={(item: string) => {
        return shippingLines.data?.find((sl) => sl.key === item)?.name || item;
      }}
      className={props.className}
    >
      {filteredItems}
    </ComboboxSelector>
  );
};
