import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContainerDetailCard from "../Opportunity/ContainerDetails/ContainerDetailCard";
import { CustomAccordion } from "../Opportunity/CustomAccordion";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Summary } from "./Summary";
import { useInvoiceContainer } from "../../hooks/disputes";

export const ContainerDetails: React.FC = () => {
  const { invoiceContainerId } = useParams();

  const { invoiceContainer, banners, acknowledgeBanner } = useInvoiceContainer(invoiceContainerId as string);
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col overflow-y-scroll">
      <div className="flex shrink sticky top-0 bg-white w-full z-50">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="my-4 ml-4 pr-2 pl-1 font-medium hover:bg-gray-50 transition-colors flex items-center gap-1 border rounded border-text-interactive-solid-subdued text-text-interactive-solid-subdued"
        >
          <ChevronLeftIcon className="h-4" />
          <span>Back</span>
        </button>
      </div>
      <div className="mx-5 my-4 flex flex-col flex-grow">
        <div className="flex flex-col space-y-4">
          <div className="border-b pb-4">
            <CustomAccordion title="Container details">
              {invoiceContainer && (
                <ContainerDetailCard
                  invoiceContainerId={invoiceContainer.id}
                  invoice={invoiceContainer.invoice}
                  hideButton
                />
              )}
            </CustomAccordion>
          </div>
          <CustomAccordion title="Dispute details">
            {invoiceContainer && (
              <Summary container={invoiceContainer} showBanner={banners} acknowledgeBanner={acknowledgeBanner} />
            )}
          </CustomAccordion>
        </div>
      </div>
    </div>
  );
};
ContainerDetails.displayName = "ContainerDetails";
