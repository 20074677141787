import { useQuery } from "@tanstack/react-query";
import { DisputesQueryKey, fetchInvoiceUploadJobs } from "../../services/disputes";

export const useInvoiceJobs = (emailId?: string) => {
  // If there is an email ID, check if there are invoice jobs for it
  const enabled = !!emailId;
  const invoiceJobsQuery = useQuery({
    queryKey: [DisputesQueryKey.InvoiceJobs, emailId],
    queryFn: ({ signal }) => fetchInvoiceUploadJobs({ source: "outlook_plugin", emailId: emailId as string }, signal),
    enabled,
    retry: false,
  });

  return [invoiceJobsQuery, enabled] as const;
};
