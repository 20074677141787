import * as React from "react";
import { Range } from "./Range";
import { ViolationDetailRow } from "../../../../../types/dispute";

interface Props {
  details: ViolationDetailRow;
}

export const RatesGroup: React.FC<Props> = ({ details }) => {
  return (
    <div className="flex flex-col space-y-2 py-2">
      <div className="text-text-secondary space-y-2">
        <h4 className="font-bold">{details.header}</h4>
        {details.subHeader && <h4 className="font-medium">{details.subHeader}</h4>}
      </div>
      <div>
        {details.body && typeof details.body === "string" && <h4>{details.body}</h4>}
        {details.body && typeof details.body !== "string" && details.body.length > 0 && (
          <div className="space-y-2">
            {details.body.map((r) => (
              <Range key={r.bodyHeader} {...r} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
RatesGroup.displayName = "OSRA.RatesGroup";
