import { User } from "../../types";
import { Invoice, InvoiceContainer, TaggedInvoiceContainer } from "../../types/dispute";
import { objectKeysToSnakeCase } from "../common";
import { PerDiemInvoiceContainerGroup, PerDiemInvoiceGroup, ShipmentGroup } from "./groups";

/** Transforms the user and company objects into what segment expects */
export const transformUserToSegment = (user: User | null) => {
  const company = user?.company;

  const segmentUser = objectKeysToSnakeCase({
    email: user?.email,
    firstname: user?.firstName || "",
    lastname: user?.lastName || "",
    phone: user?.phoneNumber || "",
    jobtitle: user?.companyRole || "",
    nbContainersAdded: company?.containerCreditsUsed || 0,
    userId: user?.id || "",
    platformJoinedDate: user?.dateJoined || "",
    platformLastLogin: user?.lastLogin || "",
    isPlatformAdministrator: user?.isAdmin || false,
    djangoLink: `https://api.erl.bluecargo.io/core/admin/users/customuser/?q=${user?.email}`,
    mixpanelLink: `https://mixpanel.com/project/2588732/view/3127896/app/profile#distinct_id=${user?.id}`,
    emailPlatformValidated: user?.emailValidated || false,
    companyPlan: company?.packDisplayName || "",
    stripeBucket: company?.packContainerVolume || 0,
    betaAccess: user?.betaAccess || false,
    company: company?.name || "",
    companyPermissions: company?.permissions || [],
    userPermissions: user?.extraPermissions || [],
    companyScac: company?.scac || "",
    companyNature: company?.companyTypeDetail || "",
    containerCreditsLimit: company?.containerCreditsLimit || 0,
  });

  const segmentGroup = {
    name: company?.name || "",
    company_nature: company?.companyTypeDetail || "",
    company_scac: company?.scac || "",
    billind_adress: company?.address || "",
    company_plan: company?.packDisplayName || "",
    strip_bucket: company?.packContainerVolume || 0,
    container_credits_limit: company?.containerCreditsLimit || 0,
    container_credits_used: company?.containerCreditsUsed || 0,
    nb_users: user?.teamMembers?.length || 0,
    subscription_period: user?.subscriptionPeriod || "",
    subscription_renewal_date: user?.subscriptionRenewalDate || "",
    billing_email: user?.billingEmail || "",
    trial_end_date: company?.trialEnd || "",
    per_diem_report_credit_left: user?.perDiemReportCreditLeft || 0,
    per_diem_report_credit_consumed: company?.containerCreditsUsed || 0,
    pack_id: company?.pack || "",
    maxUser: user?.permissions.max_user,
    website: "",
    autoJoinEmailDomain: company?.autoJoinEmailDomain || false,
    otherType: company?.otherType || "",
    companyType: company?.companyTypeDetail || "",
    expectedContainerVolume: company?.expectedContainerVolume || 0,
    portCollectionCodes: company?.portCollections.map((p) => p.code) || [],
  };

  return [segmentUser, segmentGroup] as const;
};

export const transformUserToEventProperties = (user: User | null) => {
  const userProperties = {
    email: user?.email || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phoneNumber || "",
    companyRole: user?.companyRole || "",
    currentPlan: user?.pack || "",
  };

  const companyProperties = {
    companyType: user?.company?.companyTypeDetail || "",
    companyName: user?.company?.name || "",
    autoJoinEmailDomain: user?.company?.autoJoinEmailDomain || false,
    scac: user?.company?.scac || "",
    otherType: user?.company?.otherType || "",
    expectedContainerVolume: user?.company?.expectedContainerVolume || 0,
    portCollectionCodes: user?.company?.portCollections || [],
  };

  return { user: userProperties, company: companyProperties };
};

export const invoiceToInvoiceGroup = (invoice?: Invoice): PerDiemInvoiceGroup => ({
  billingParty: invoice?.invoicerName || "",
  disputeType: "Per Diem",
  invoiceIssueDate: invoice?.invoiceIssueDate || "",
  invoiceNumber: invoice?.invoiceNumber || "",
  portOfDestination: invoice?.portOfDelivery?.id || "",
  ssl: invoice?.ssl?.key || "",
  totalPerDiem: invoice?.totalChargesCents || 0,
  uploadType: "email",
});

export const invoiceContainerToInvoiceContainerGroup = (container: InvoiceContainer): PerDiemInvoiceContainerGroup => ({
  totalContainerPerDiem: container.totalChargesCents || 0,
  rates: container.rates.map((r) => ({
    fromDate: r.startDate || "",
    toDate: r.endDate || "",
    days: r.days || 0,
    rate: r.rateCents || 0,
    perDiemAmount: r.rateTotalCents || 0,
  })),
});

export const invoiceContainerToShipmentGroup = (container?: TaggedInvoiceContainer): ShipmentGroup => ({
  bol: container?.bol || "",
  containerNumber: container?.containerNumber || "",
  containerType: container?.containerType || "",
  ssl: container?.invoice?.ssl?.key || "",
});
