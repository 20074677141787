import * as React from "react";
import { CollapsableSection } from "../../CollapsableSection";
import { ContainerInfo } from "./ContainerInfo";
import { Invoice } from "../../../../types/dispute";
import { AddButton } from "../../AddButton";
import pluralize from "pluralize";
import { createEmptyInvoiceContainer } from "../../utility";
import { ContainerValidity } from "../../types";

interface Props {
  invoice: Invoice;
  onChange: (invoice: Invoice) => void;
  validity?: ContainerValidity[];
}

export const ContainerSection: React.FC<Props> = ({ invoice, onChange, validity }) => {
  const { invoiceContainers } = invoice;
  const count = invoiceContainers.length;
  return (
    <CollapsableSection
      title={
        <h4 className="font-bold m-0">
          {pluralize("Container", count)} ({count})
        </h4>
      }
      className="space-y-4 pb-4"
    >
      {invoiceContainers.map((container, i) => {
        return (
          <ContainerInfo
            invoiceContainer={container}
            key={`${container.id}_${i}`}
            showMenu={count > 1}
            onAction={(action) => {
              switch (action) {
                case "delete":
                  onChange({ ...invoice, invoiceContainers: invoiceContainers.filter((_, j) => i !== j) });
                  break;
              }
            }}
            onChange={(invoiceContainer) => {
              const newInvoiceContainers = [...invoiceContainers];
              newInvoiceContainers[i] = invoiceContainer;
              onChange({ ...invoice, invoiceContainers: newInvoiceContainers });
            }}
            validity={validity?.[i]}
          />
        );
      })}
      <AddButton
        onClick={() => {
          onChange({ ...invoice, invoiceContainers: [...invoiceContainers, createEmptyInvoiceContainer(invoice.id)] });
        }}
      >
        + Add Container
      </AddButton>
    </CollapsableSection>
  );
};
ContainerSection.displayName = "Verification.ContainerSection";
