import classNames from "classnames";
import React from "react";
import { Date } from "./Date";
import { DateStatus } from "./DateStatus";
import { ComplianceStatus, ViolationDetails, ViolationType } from "../../../../types/dispute";

interface Props {
  type: ViolationType;
  status: ComplianceStatus;
  details: ViolationDetails;
}

export const DateSection: React.FC<Props> = ({ type, status, details }) => {
  return (
    <div
      className={classNames("p-2 py-4 space-y-1", {
        "bg-surface-secondary": status === ComplianceStatus.Compliant,
        "bg-surface-subdued": status === ComplianceStatus.Skipped,
        "bg-surface-tertiary": status === ComplianceStatus.NonCompliant,
      })}
    >
      <div className="space-y-4">
        {details.rows?.map((e) => {
          return <Date key={e.header} details={e} />;
        })}
      </div>
      <DateStatus status={status} type={type} reasonDefault={details.warning} />
    </div>
  );
};
DateSection.displayName = "Sumamry.Dates.DateSection";
