import { DisputeEmailTemplate } from "../services/disputes";
import { ShippingLine } from "./shipping-line";
import { Tag } from "./tag";

export enum DisputeJobStatus {
  Parsing = "parsing",
  Validation = "validation",
  DisputeOpportunity = "dispute_opportunity",
  ParsingError = "parsing_error",
  GeneralError = "error",
}

export enum DisputeState {
  CreditHold = "credit_hold",
  VerifyingInvoice = "verifying",
  RequiresReview = "requires_review",
  ReadyToDispute = "ready",
  PendingInvoice = "pending_invoice",
  PendingSSLReply = "pending_reply",
  RequiresReply = "requires_reply",
  ResolvedWon = "resolved_won",
  ResolvedPending = "resolved_pending",
  ResolvedLost = "resolved_lost",
}

export enum DisputeOrigin {
  Email = "email",
  Upload = "upload",
  Manual = "manual",
}

export enum JobStatus {
  Error = "error",
  Success = "success",
  Pending = "pending",
  Duplicate = "duplicate",
  InProgress = "in_progress",
  Retrying = "retrying",
}

export enum DayAccrual {
  WorkingDays = "working_days",
  CalendarDays = "calendar_days",
}

export interface ParseJob {
  id: string;
}

export enum DisputeFileType {
  Invoice = "invoice",
  Screenshot = "screenshot",
}

export interface DisputeDocument {
  id: number;
  dispute: number;
  uploadedFilename: string;
  fileExtension: string;
  fileType: DisputeFileType;
  attachmentUrl: string;
  createdAt: string;
}

export interface Dispute {
  company: string;
  createdAt: string;
  createdFrom: DisputeOrigin;
  id: number;
  invoices: Invoice[];
  status: DisputeStatus;
  updatedAt: string;
  user: number;
  documents: DisputeDocument[];
  paidAmountCents: number;
}

export interface Invoice {
  id: string | null;
  createdAt: string | null;
  document: string | null;
  invoiceContainers: InvoiceContainer[];
  invoiceIssueDate: string | null;
  invoiceNumber: string | null;
  portOfDelivery: {
    id: string;
    name: string;
    nameMappings: string[];
    portCode: string;
    portCollection: string;
    throughput: number;
    timeZone: string;
  } | null;
  portOfDeliveryId: string | null;
  ssl: ShippingLine | null;
  sslId: string | null;
  totalChargesCents: number | null;
  invoicerName: string | null;
  parseJob: ParseJob | null;
}

export enum ComplianceStatus {
  Compliant = "compliant",
  NonCompliant = "non_compliant",
  Skipped = "skipped",
}

export interface InvoiceContainer {
  id: string | null;
  invoice: string | null;
  transaction: string | null;
  container: string | null;
  containerNumber: string | null;
  containerType: string | null;
  totalChargesCents: number;
  rates: InvoiceContainerRate[];
  bol: string;
  dispute: Dispute | null;
  freeTimeDaysMode: DayAccrual;
  chargeTimeDaysMode: DayAccrual;
  uiiaComplianceStatus: ComplianceStatus | null;
  osraComplianceStatus: ComplianceStatus | null;
  suggestedTemplate: [DisputeEmailTemplate, string] | null;
}

export interface InvoiceUploadJob {
  company: string;
  completedAt: string | null;
  document: number;
  id: string;
  parsedInvoices: Invoice[];
  startedAt: string | null;
  status: JobStatus;
  user: number;
  createdAt: string;
  externalId: string | null;
  secondaryExternalId: string | null;
}

export interface InvoiceContainerRate {
  id: number | null;
  startDate: string;
  endDate: string;
  days: number;
  rateCents: number;
  rateTotalCents: number;
  invoiceContainer: InvoiceContainer["id"] | null;
  isFreeTime: boolean;
}

export interface TaggedInvoiceContainer extends Omit<InvoiceContainer, "invoice"> {
  invoice: Invoice | null;
  tags: Tag[];
  disputeOpportunity: null | {
    estSavingsCents: number | null;
    estSavingsPercent: number | null;
    totalChargesCents: number | null;
  };
}

export interface DisputeStatus {
  id: number;
  status: DisputeState;
  updatedAt: string;
  updatedBy: string;
}

export enum DisputeReason {
  PortRestrictions = "PortRestrictions",
  UIIANonCompliance = "UIIANonCompliance",
  OSRANonCompliance = "OSRANonCompliance",
  CustomProof = "CustomProof",
}

export const ReasonCopyMap: Record<DisputeReason, string> = {
  [DisputeReason.PortRestrictions]: "Port Restrictions",
  [DisputeReason.UIIANonCompliance]: "Noncompliant with UIIA",
  [DisputeReason.OSRANonCompliance]: "Noncompliant with OSRA",
  [DisputeReason.CustomProof]: "Custom Proof",
};

export enum Disputability {
  Disputable = "disputable",
  RequiresDocumentation = "requires-documentation",
  CreditHold = "credit-hold",
}

export interface DisputableContainer {
  status: Disputability.Disputable;
  reasons: DisputeReason[];
}

export interface IndisputableContainer {
  status: Disputability.RequiresDocumentation;
}

export interface CreditHoldContainer {
  status: Disputability.CreditHold;
}

export type ContainerDisputability = DisputableContainer | IndisputableContainer | CreditHoldContainer;

export const TemplateToDisputeReasonMap: Record<DisputeEmailTemplate, DisputeReason | null> = {
  [DisputeEmailTemplate.CustomProof]: DisputeReason.CustomProof,
  [DisputeEmailTemplate.FirstRebuttalCalifornia]: DisputeReason.PortRestrictions,
  [DisputeEmailTemplate.FirstRebuttalUs]: DisputeReason.PortRestrictions,
  [DisputeEmailTemplate.NoReturnCalifornia]: DisputeReason.PortRestrictions,
  [DisputeEmailTemplate.NoReturnUs]: DisputeReason.PortRestrictions,
  [DisputeEmailTemplate.Past30Days]: DisputeReason.PortRestrictions,
  [DisputeEmailTemplate.OSRANonCompliance]: DisputeReason.OSRANonCompliance,
  [DisputeEmailTemplate.UIIANonCompliance]: DisputeReason.UIIANonCompliance,
};

export const determineReasonFromTemplate = (template: DisputeEmailTemplate | null): DisputeReason | null => {
  if (!template) {
    return null;
  }
  return TemplateToDisputeReasonMap[template];
};

export interface DisputeType {
  name: string;
  template: DisputeEmailTemplate;
  sent: boolean;
}

export interface EmailMapping {
  [key: string]: DisputeEmailTemplate | undefined;
}

export enum ContractType {
  UIIA = "UIIA",
  OSRA = "OSRA",
}

export enum ViolationType {
  InvoiceIssueDateMissing = "invoice_issue_date_missing",
  InvoiceIssueDateTooLate = "invoice_issue_date_too_late",
  RatesMissing = "rates_missing",
  ContractRatesMismatch = "contract_rates_mismatch",
  StandardRatesMismatch = "standard_rates_mismatch",
  GatedInDateMissing = "gated_in_date_missing",
  GatedInDateMismatch = "gated_in_date_mismatch",
  GatedOutDateMissing = "gated_out_date_missing",
  GatedOutDateMismatch = "gated_out_date_mismatch",
}

export interface ViolationDetailRow {
  header: string;
  subHeader?: string;
  body: string | { bodyHeader: string; bodyValue: string }[];
}

export interface ViolationDetails {
  warning?: string;
  rows?: ViolationDetailRow[];
}

export interface ContainerViolation {
  status: ComplianceStatus;
  contract: ContractType;
  type: ViolationType;
  text: string;
  details: ViolationDetails;
}
