import { TruckIcon } from "@heroicons/react/20/solid";
import * as React from "react";

interface Props {
  title: React.ReactNode;
  value: React.ReactNode;
  loading?: boolean;
}

export const Stat: React.FC<Props> = (props) => {
  const { title, value, loading } = props;
  return (
    <div className="flex justify-between gap-4 py-2.5 bg-surface-primary items-center">
      <div className="text-left text-text-interactive-solid-secondary">{title}</div>
      <div className="text-right text-text-secondary">
        {loading ? <TruckIcon className="h-6 animate-bounce absolute" /> : value}
      </div>
    </div>
  );
};
Stat.displayName = "ContainerDispute.Edit.Summary.Stat";
