import { ExclamationCircleIcon, LightBulbIcon, XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import * as React from "react";

/** The type of banner */
type Variant = "warning" | "informative" | "exclamation";

/** Classes to apply to different parts of the banner component */
interface Classes {
  icon: string;
  container: string;
  title: string;
  message: string;
}

interface Props {
  /**
   * The variant of the banner; i.e. warning, success, etc
   * TODO: Add more variants as we need them
   */
  variant: Variant;
  /** The title to display; already wrapped in an h4 */
  title?: React.ReactNode;
  /** The message to display; already wrapped in an h4 */
  children: React.ReactNode;
  /** Optional class overrides */
  classes?: Partial<Classes>;
  /** Puts an X icon when provided */
  onDismiss?: () => void;
}

const CLASSES: Record<Variant, Classes> = {
  warning: {
    icon: "text-icon-informative-warning",
    container: "bg-surface-informative-warning",
    title: "text-text-interactive-outline-secondary",
    message: "text-text-informative-warning",
  },
  informative: {
    icon: "text-icon-informative-success",
    container: "bg-surface-informative-success",
    title: "text-text-secondary",
    message: "text-text-primary",
  },
  exclamation: {
    icon: "text-icon-interactive-secondary",
    container: "bg-transparent",
    title: "text-text-interactive-outline-primary",
    message: "text-text-interactive-outline-primary",
  },
};

/**
 * Renders a banner with an icon, title, and message
 * Can be used for success, warning, and error messages depending on variant used
 */
export const BannerAlert: React.FC<Props> = ({ variant, title, children, classes: overrideClasses, onDismiss }) => {
  const defaultClasses = CLASSES[variant];

  let icon: React.ReactNode = null;
  switch (variant) {
    case "warning":
      icon = (
        <ExclamationCircleIcon
          className={classNames(defaultClasses.icon, overrideClasses?.icon, "flex-shrink-0 h-6")}
        />
      );
      break;
    case "informative":
      icon = <LightBulbIcon className={classNames(defaultClasses.icon, overrideClasses?.icon, "flex-shrink-0 h-6")} />;
      break;
    case "exclamation":
      icon = (
        <ExclamationCircleIcon
          className={classNames(defaultClasses.icon, overrideClasses?.icon, "flex-shrink-0 h-6")}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className={classNames(defaultClasses.container, overrideClasses?.container, "p-4 rounded")}>
      <div className="flex gap-2">
        {icon}
        <div className="space-y-2 flex-grow">
          <div className="flex items-center justify-between">
            {title && (
              <h4 className={classNames(defaultClasses.title, overrideClasses?.title, "font-bold")}>{title}</h4>
            )}
            {onDismiss && <XMarkIcon className="cursor-pointer h-4 flex-shrink-0" onClick={onDismiss} />}
          </div>
          <div className={classNames(defaultClasses.message, overrideClasses?.message)}>{children}</div>
        </div>
      </div>
    </div>
  );
};
BannerAlert.displayName = "BannerAlert";
