import { Invoice, InvoiceContainer, InvoiceContainerRate } from "../../types/dispute";
import { ShippingLine } from "../../types/shipping-line";
import { isFinite } from "lodash";
import { DateTime } from "luxon";
import { ContainerType } from "../../types/container";
import { ContainerValidity, InvoiceValidity, RateValidity, ValidityStatus } from "./types";

const MISSING_REQUIRED = "This is a required field.";
const MISSING_WARNING = "If your invoice is missing this information, you may leave it blank and continue onwards.";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkIsDefined = (value: any, required?: boolean): ValidityStatus => {
  if (value === null || value === undefined) {
    return {
      status: required ? "error" : "warning",
      reason: required ? MISSING_REQUIRED : MISSING_WARNING,
    };
  }

  return { status: "valid" };
};

const checkIsFinite = (value: number | null | undefined, required?: boolean): ValidityStatus => {
  if (!isFinite(value)) {
    return {
      status: required ? "error" : "warning",
      reason: required ? MISSING_REQUIRED : MISSING_WARNING,
    };
  }

  if ((value as number) < 0) {
    return { status: "error", reason: "Must be greater than or equal to 0." };
  }

  return { status: "valid" };
};

const checkIsEmptyString = (
  value: string | null | undefined,
  required: boolean,
  regExp?: RegExp,
  regExpReason?: string
): ValidityStatus => {
  if (value === null || value === undefined || !value) {
    if (required) {
      return { status: "error", reason: MISSING_REQUIRED };
    }
    return {
      status: "warning",
      reason: MISSING_WARNING,
    };
  }

  if (regExp) {
    if (!regExp.test(value)) {
      return { status: "error", reason: regExpReason || "Invalid format." };
    }
  }

  return { status: "valid" };
};

const checkDateRange = (
  startDate: string,
  endDate: string,
  current: "start" | "end",
  required: boolean
): ValidityStatus => {
  if (startDate && endDate) {
    if (DateTime.fromISO(endDate) < DateTime.fromISO(startDate)) {
      return {
        status: "error",
        reason: current === "start" ? "Start date must be before end date." : "End date must be after start date.",
      };
    }
  }
  if (current === "start") {
    return checkIsEmptyString(startDate, required);
  }
  return checkIsEmptyString(endDate, required);
};

/** Validates an invoice container rate */
export const validateRate = (rate: InvoiceContainerRate): RateValidity => {
  if (rate.isFreeTime) {
    return {
      startDate: checkDateRange(rate.startDate, rate.endDate, "start", false),
      endDate: checkDateRange(rate.startDate, rate.endDate, "end", false),
      rateCents: { status: "valid" },
      rateTotalCents: { status: "valid" },
      days: { status: "valid" },
      isFreeTime: true,
    };
  }

  return {
    // Start date is before end date, required
    startDate: checkDateRange(rate.startDate, rate.endDate, "start", true),
    // End date is after start date, required
    endDate: checkDateRange(rate.startDate, rate.endDate, "end", true),
    // If freetime, don't care; otherwise, must be a finite number and is required
    rateCents: checkIsFinite(rate.rateCents, false),
    // If freetime, don't care; otherwise must be a finite number and is required
    rateTotalCents: checkIsFinite(rate.rateTotalCents, false),
    // If a finite number and is required
    days: checkIsFinite(rate.days, false),
    isFreeTime: false,
  };
};

/** Check whether a rate should be considered valid; i.e. the user may proceed */
export const isRateValid = (rate: RateValidity): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isFreeTime, ...rest } = rate;
  return Object.values(rest).every((v) => v.status !== "error");
};

/** Validate an invoice container */
export const validateInvoiceContainer = (
  invoiceContainer: InvoiceContainer,
  containerTypes?: ContainerType[]
): ContainerValidity => {
  let validContainerType = checkIsDefined(invoiceContainer.containerType, true);
  if (validContainerType.status === "valid" && containerTypes) {
    if (!containerTypes || !containerTypes.some((c) => c.key === invoiceContainer.containerType)) {
      validContainerType = { status: "error", reason: "Invalid container type" };
    }
  }

  return {
    bol: checkIsEmptyString(invoiceContainer.bol, true),
    containerNumber: checkIsEmptyString(
      invoiceContainer.containerNumber,
      true,
      /^[\w-]{10,}$/,
      "Must be at least 10 characters"
    ),
    containerType: validContainerType,
    totalChargesCents: checkIsFinite(invoiceContainer.totalChargesCents, true),
    rates: invoiceContainer.rates?.map((rate) => validateRate(rate)) || [],
  };
};

/** Check whether an invoice container should be considered valid; i.e. the user may proceed */
export const isInvoiceContainerValid = (invoiceContainer: ContainerValidity): boolean => {
  return (
    Object.keys(invoiceContainer).every(
      (k) => k === "rates" || invoiceContainer[k as keyof Omit<ContainerValidity, "rates">].status !== "error"
    ) &&
    invoiceContainer.rates.length > 0 &&
    invoiceContainer.rates.every((r) => isRateValid(r)) &&
    // Need at least one paid rate
    invoiceContainer.rates.some((r) => !r.isFreeTime)
  );
};

/** Validate an invoice */
export const validateInvoice = (
  invoice: Invoice,
  shippingLines?: ShippingLine[],
  containerTypes?: ContainerType[]
): InvoiceValidity => {
  let validSsl = checkIsDefined(invoice.ssl || invoice.sslId, true);
  if (validSsl.status === "valid" && shippingLines) {
    if (!shippingLines || !shippingLines?.some((sl) => sl.key === invoice.ssl?.key || sl.key === invoice.sslId)) {
      validSsl = { status: "error", reason: "Invalid SSL" };
    }
  }

  const validPortOfDelivery = checkIsDefined(invoice.portOfDelivery || invoice.portOfDeliveryId, true);

  return {
    invoiceNumber: checkIsEmptyString(invoice.invoiceNumber, false),
    invoiceIssueDate: checkIsEmptyString(invoice.invoiceIssueDate, false),
    invoicerName: checkIsEmptyString(invoice.invoicerName, false),
    ssl: validSsl,
    portOfDelivery: validPortOfDelivery,
    totalChargesCents: checkIsFinite(invoice.totalChargesCents, true),
    invoiceContainers: invoice.invoiceContainers.map((invoiceContainer) =>
      validateInvoiceContainer(invoiceContainer, containerTypes)
    ),
  };
};

/** Check whether an invoice should be considered valid; i.e. the user may proceed */
export const isInvoiceValid = (invoice: InvoiceValidity): boolean => {
  return (
    Object.keys(invoice).every(
      (key) =>
        key === "invoiceContainers" ||
        invoice[key as keyof Omit<InvoiceValidity, "invoiceContainers">].status !== "error"
    ) &&
    invoice.invoiceContainers.length > 0 &&
    invoice.invoiceContainers.every((ic) => isInvoiceContainerValid(ic))
  );
};
