import React from "react";

interface Props {
  title: string;
  value: string | React.ReactNode;
  description: string;
}

export const KeyMetricCard: React.FC<Props> = (props) => {
  const { title, value, description } = props;

  return (
    <div className="rounded bg-white border border-gray-300 flex flex-col py-3 px-2 space-y-1.5">
      <h3 className="truncate text-lg font-bold text-text-interactive-solid-subdued">{title}</h3>
      <h2 className="font-bold text-2xl text-surface-interactive-solid-secondary">{value}</h2>
      <h5 className="text-xs text-text-subdued">{description}</h5>
    </div>
  );
};
