import * as React from "react";
import { IndicatedInput, IndicatedMonetaryInput } from "../../../../../components/IndicatedInput";
import { InvoiceContainerRate } from "../../../../../types/dispute";
import { Action, RateValidity } from "../../../types";
import { getReason, isValid } from "../../../utility";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface Props {
  rate: InvoiceContainerRate;
  index: number;
  onChange: (rate: InvoiceContainerRate) => void;
  onAction: (action: Action) => void;
  validity?: RateValidity;
  showMenu?: boolean;
}

export const BilledRate: React.FC<Props> = ({ rate, index, onChange, onAction, validity, showMenu }) => {
  return (
    <div className="space-y-4 bg-surface-secondary p-2 rounded">
      <div className="flex items-center justify-between gap-2">
        <h4 className="m-0 text-text-primary font-bold">Rate {index + 1}</h4>
        {showMenu && (
          <button
            onClick={() => onAction("delete")}
            className="flex items-center gap-1 font-medium text-text-interactive-accent-2"
          >
            <XMarkIcon className="h-5 flex-shrink-0" />
            <h4>Delete row</h4>
          </button>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <IndicatedInput
          label="From date"
          inputProps={{
            value: rate.startDate || "",
            type: "date",
            onChange: (e) => onChange({ ...rate, startDate: e.target.value }),
          }}
          valid={isValid(validity, "startDate")}
          reason={getReason(validity, "startDate")}
          required
        />
        <IndicatedInput
          label="To date"
          inputProps={{
            value: rate.endDate || "",
            type: "date",
            onChange: (e) => onChange({ ...rate, endDate: e.target.value }),
          }}
          valid={isValid(validity, "endDate")}
          reason={getReason(validity, "endDate")}
          required
        />
        <IndicatedInput
          label="Days"
          inputProps={{
            value: rate.days || "",
            type: "number",
            onChange: (e) => onChange({ ...rate, days: e.target.valueAsNumber }),
          }}
          valid={isValid(validity, "days")}
          reason={getReason(validity, "days")}
        />
        <IndicatedMonetaryInput
          label="Rate ($)"
          value={(rate.rateCents || 0) / 100}
          onChange={(value) => onChange({ ...rate, rateCents: value * 100 })}
          valid={isValid(validity, "rateCents")}
          reason={getReason(validity, "rateCents")}
        />
        <IndicatedMonetaryInput
          label="Per Diem amount ($)"
          value={(rate.rateTotalCents || 0) / 100}
          onChange={(value) => onChange({ ...rate, rateTotalCents: value * 100 })}
          valid={isValid(validity, "rateTotalCents")}
          reason={getReason(validity, "rateTotalCents")}
          className="col-span-2"
        />
      </div>
    </div>
  );
};
BilledRate.displayName = "BilledRate";
