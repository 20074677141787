import classNames from "classnames";
import * as React from "react";
import { ComplianceStatus, ViolationDetails, ViolationType } from "../../../../../types/dispute";
import { RatesGroup } from "./RatesGroup";
import { RatesStatus } from "./RatesStatus";

interface Props {
  type: ViolationType;
  status: ComplianceStatus;
  details: ViolationDetails;
}

export const RateSection: React.FC<Props> = ({ type, status, details }) => {
  return (
    <div
      className={classNames("space-y-2 p-2", {
        "bg-surface-secondary": status === ComplianceStatus.Compliant,
        "bg-surface-subdued": status === ComplianceStatus.Skipped,
        "bg-surface-tertiary": status === ComplianceStatus.NonCompliant,
      })}
    >
      <div className="space-y-2">
        {details.rows?.map((e) => {
          return <RatesGroup key={e.header} details={e} />;
        })}
      </div>
      <RatesStatus status={status} type={type} />
    </div>
  );
};
RateSection.displayName = "OSRA.Rates.RateSection";
