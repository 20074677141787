import * as React from "react";
import { BilledRate } from "./BilledRate";
import { DayAccrual, InvoiceContainerRate } from "../../../../../types/dispute";
import { RateValidity } from "../../../types";
import { AddButton } from "../../../AddButton";
import { TimeMode } from "./TimeMode";

interface Props {
  rates: InvoiceContainerRate[];
  onChange: (rates: InvoiceContainerRate[]) => void;
  addRate: () => void;
  validity?: RateValidity[];
  timeMode?: DayAccrual;
  onTimeModeChange: (timeMode: DayAccrual) => void;
}

export const BilledRates: React.FC<Props> = ({ addRate, rates, onChange, validity, timeMode, onTimeModeChange }) => {
  return (
    <div className="space-y-4">
      <h5 className="m-0 text-text-subdued uppercase">Billed Dates</h5>
      <TimeMode checked={timeMode} onChange={onTimeModeChange} />
      <div className="space-y-4">
        {rates.map((r, i) => {
          const key = `${r.id}_${i}`;
          return (
            <BilledRate
              key={key}
              rate={r}
              index={i}
              onChange={(rate) => {
                const newRates = [...rates];
                newRates[i] = rate;
                onChange(newRates);
              }}
              onAction={(action) => {
                switch (action) {
                  case "delete":
                    onChange(rates.filter((_, j) => j !== i));
                    break;
                  default:
                    break;
                }
              }}
              validity={validity?.[i]}
              showMenu={rates.length > 1}
            />
          );
        })}
      </div>
      <AddButton onClick={addRate}>+ Add Rate</AddButton>
    </div>
  );
};
BilledRates.displayName = "BilledRates";
