import * as React from "react";
import { Button } from "../../../components/Button";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { AnalyticsEvent, useAnalytics } from "../../../lib/segment";

/* global Office */

interface Props {
  attachments: Office.AttachmentDetails[];
  loading?: boolean;
  processInvoice: (attachment: Office.AttachmentDetails) => void;
}

export const MultipleAttachments: React.FC<Props> = ({ attachments, loading, processInvoice }) => {
  const [selected, setSelected] = React.useState(attachments[0]);
  const track = useAnalytics();

  return (
    <div className="w-full space-y-8">
      <div className="w-full relative space-y-2">
        <h4 className="font-medium text-text-secondary">Select a new attachment to process</h4>
        <Listbox value={selected} onChange={setSelected} disabled={loading}>
          <Listbox.Button
            className={
              "relative w-full cursor-default rounded-lg bg-white py-4 pl-2 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
            }
          >
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {attachments.map((a) => (
              <Listbox.Option
                key={a.id}
                value={a}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${active && "bg-gray-100"}`
                }
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{a.name}</span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      <Button
        className="w-full"
        onClick={() => {
          processInvoice(selected);
          track(AnalyticsEvent.UploadedInvoice, {
            disputeType: "per-diem",
            uploadType: "email plug-in",
            invoiceUploadSource: "email plug-in",
          });
        }}
        loading={loading}
      >
        <h3 className="font-medium text-lg">Process invoice</h3>
      </Button>
    </div>
  );
};
MultipleAttachments.displayName = "Read.WithAttachments.MultipleAttachments";
