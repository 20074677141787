import React from "react";
import { Envelope } from "../assets/Envelope";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchEmailsForContainer } from "../services/disputes";
import { useEmailContext } from "../lib/email-context";
import { useUploadJob } from "./Opportunity/hooks";
import { DateTime } from "luxon";
import type { InvoiceContainer } from "../types/dispute";

export const AlreadySent: React.FC = () => {
  const { invoiceJobId } = useParams();
  const { emailId } = useEmailContext();
  const { invoiceUploadJob } = useUploadJob(invoiceJobId || "");

  const [sentInvoiceContainer, setSentInvoiceContainer] = React.useState<InvoiceContainer | undefined>();

  /**
   * Fetches emails for every invoice container in the invoiceUploadJob
   * @returns The first successful response, or null if all requests resulted in 404
   */
  const fetchEmailsForContainerWithRetry = async () => {
    if (!invoiceUploadJob) return;
    let fetchPromises: any = [];

    invoiceUploadJob.parsedInvoices.forEach((parsedInvoice) => {
      parsedInvoice.invoiceContainers.forEach((invoiceContainer) => {
        fetchPromises.push(
          fetchEmailsForContainer(invoiceContainer.id)
            .then((response) => {
              if (response.status !== 404) {
                setSentInvoiceContainer(invoiceContainer);
                return response;
              }
              return null;
            })
            .catch(() => null)
        );
      });
    });

    const responses = await Promise.all(fetchPromises);

    const successfulResponse = responses.find((response) => response !== null);

    if (successfulResponse) {
      return successfulResponse;
    } else {
      throw new Error("All requests resulted in 404");
    }
  };

  const emails = useQuery({
    queryKey: ["emails", invoiceJobId],
    queryFn: fetchEmailsForContainerWithRetry,
    enabled: !!invoiceUploadJob?.id,
  });

  const emailSent = React.useMemo(() => {
    return emails.data?.events.find((email: any) => email.externalId === emailId);
  }, [emails.data, emailId, emails]);

  const dateStr = React.useMemo(
    () => (emailSent ? DateTime.fromISO(emailSent?.datetime).toFormat("ccc LLL d") : "Loading..."),
    [emailSent]
  );

  return (
    <div className="flex flex-col items-center p-8 space-y-8 flex-grow">
      <div className="flex items-center justify-center">
        <Envelope />
      </div>
      <div className="text-text-interactive-disabled text-lg flex flex-col space-y-1 items-center text-center">
        <h3>
          You already sent a dispute email for{" "}
          <em>{sentInvoiceContainer?.dispute?.documents[0].uploadedFilename || "Loading..."}</em> on{" "}
        </h3>
        <h3 className="font-bold">{dateStr}</h3>
      </div>
      <a
        href={`https://erl.bluecargo.io/dispute/tracker?focusedInvoice=${sentInvoiceContainer?.id}`}
        target="_blank"
        rel="noreferrer"
        className="w-full m-4"
      >
        <button className="w-full py-3 hover:bg-blue-100 text-text-interactive-outline-primary bg-white border border-text-interactive-outline-primary rounded shadow">
          Show in Dispute Tracker
        </button>
      </a>
    </div>
  );
};
