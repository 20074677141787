import * as React from "react";
import { ViolationDetailRow } from "../../../../types/dispute";

interface DateProps {
  details: ViolationDetailRow;
}

export const Date: React.FC<DateProps> = ({ details }) => {
  const { header, subHeader, body } = details;
  return (
    <div className="flex justify-between gap-2 items-start">
      <h4 className="text-text-secondary font-bold">{header}</h4>
      <div className="space-y-2 text-right">
        {body && typeof body === "string" && <h4 className="font-medium text-text-secondary">{body}</h4>}
        {subHeader}
      </div>
    </div>
  );
};
Date.displayName = "Summary.Date";
