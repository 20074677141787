import { AxiosResponse } from "axios";
import { joinArrayParams, objectKeysToCamelCase } from "../lib/common";
import { client } from "./client";
import { ShippingLine } from "../types/shipping-line";

export const enum ShippingLineQueryKeys {
  ShippingLines = "shippingLines",
}

export interface ShippingLineFilters {
  portCollectionCodes?: string[];
}

interface ShippingLineArgument extends ShippingLineFilters {
  signal?: AbortSignal;
}

export async function fetchShippingLines({ portCollectionCodes, signal = undefined }: ShippingLineArgument) {
  const response: AxiosResponse = await client.get("/core/api/v1/erl/shipping_line", {
    signal,
    params: {
      port_collection_codes: joinArrayParams(portCollectionCodes),
    },
  });
  const shippingLines = objectKeysToCamelCase<ShippingLine[]>(response.data);
  return shippingLines;
}
