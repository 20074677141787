import React from "react";
import { FeatureFlag, FeatureFlagPayload, useFeatureFlag } from "../lib/permissions/feature-flags";
import {
  ComplianceStatus,
  ContainerDisputability,
  Disputability,
  DisputeFileType,
  DisputeReason,
  DisputeState,
  ReasonCopyMap,
  TaggedInvoiceContainer,
  determineReasonFromTemplate,
} from "../types/dispute";
import { DisputeEmailTemplate, DisputesQueryKey, fetchInvoiceContainer } from "../services/disputes";
import { useQuery } from "@tanstack/react-query";

/** The primary logic for determining a container's disputability */
export const determineDisputability = (
  container: TaggedInvoiceContainer,
  options: {
    checkOSRA: Partial<FeatureFlagPayload["osraValidation"]>;
    checkUIIA: boolean;
  }
): ContainerDisputability => {
  if (container.dispute?.status.status === DisputeState.CreditHold) {
    return { status: Disputability.CreditHold };
  }
  const reasons: DisputeReason[] = [];

  if (Object.values(options.checkOSRA).some((v) => !!v)) {
    // We expect that the BE honors the FF and do not perform a granular check here
    if (container.osraComplianceStatus === ComplianceStatus.NonCompliant) {
      reasons.push(DisputeReason.OSRANonCompliance);
    }
  }

  if (options.checkUIIA) {
    if (container.uiiaComplianceStatus === ComplianceStatus.NonCompliant) {
      reasons.push(DisputeReason.UIIANonCompliance);
    }
  }

  if (
    !!container.disputeOpportunity?.estSavingsPercent ||
    (container.dispute?.documents.filter((f) => f.fileType !== DisputeFileType.Invoice).length || 0) > 0
  ) {
    reasons.push(DisputeReason.PortRestrictions);
  }

  if (reasons.length > 0) {
    return { status: Disputability.Disputable, reasons };
  }

  return { status: Disputability.RequiresDocumentation };
};

/**
 * Returns the value of all the feature flags for the enhanced disputability
 * TODO: Remove this when the feature flags are removed
 */
export const useEnhancedDisputability = () => {
  const enhancedInvoiceUpload = useFeatureFlag(FeatureFlag.InvoiceUploadEnhancements) || false;
  const osra = useFeatureFlag(FeatureFlag.OSRAValidation) || {};
  const uiia = useFeatureFlag(FeatureFlag.UIIAValidation) || false;

  return {
    checkOSRA: {
      customRates: false,
      dates: false,
      standardRates: false,
      ...(enhancedInvoiceUpload ? osra : {}),
    },
    checkUIIA: enhancedInvoiceUpload ? uiia : false,
  };
};

/** Check if all containers are in credit hold */
export const useAllCreditHold = (containers: TaggedInvoiceContainer[]) => {
  const { checkOSRA, checkUIIA } = useEnhancedDisputability();
  return React.useMemo(() => {
    return containers.every((c) => {
      const { status } = determineDisputability(c, { checkOSRA, checkUIIA });
      return status === Disputability.CreditHold;
    });
  }, [containers, checkOSRA, checkUIIA]);
};

export const useDisputability = (container: TaggedInvoiceContainer) => {
  const { checkOSRA, checkUIIA } = useEnhancedDisputability();
  return determineDisputability(container, { checkOSRA, checkUIIA });
};

export const determineDisputeReasons = (
  container: TaggedInvoiceContainer,
  checkOSRA: Partial<FeatureFlagPayload["osraValidation"]>,
  checkUIIA: boolean,
  template: DisputeEmailTemplate | null = null
) => {
  const disputability = determineDisputability(container, { checkOSRA, checkUIIA });

  const reasonFromTemplate = determineReasonFromTemplate(template || container.suggestedTemplate?.[0] || null);

  if (disputability.status !== Disputability.Disputable) {
    return [null, null];
  }

  let { reasons } = disputability;
  if (reasonFromTemplate) {
    reasons = [reasonFromTemplate, ...reasons.filter((r) => r !== reasonFromTemplate)];
  }

  return reasons.map((r) => ({ reason: r, label: ReasonCopyMap[r] }));
};

/**
 * Returns the dispute reason(s) for a container, taking into account
 * its suggested template type or a template override (should the user
 * wish to use a different one instead)
 */
export const useDisputeReasons = (
  /** The container to get the dispute reasons for */
  container: TaggedInvoiceContainer,
  /** The selected template, if there is one; overrides the container's */
  template: DisputeEmailTemplate | null = null
) => {
  const osra = useFeatureFlag(FeatureFlag.OSRAValidation);
  const uiia = useFeatureFlag(FeatureFlag.UIIAValidation);

  return React.useMemo(() => {
    return determineDisputeReasons(container, osra || {}, uiia || false, template);
  }, [osra, uiia, container, template]);
};

export const useInvoiceContainer = (invoiceContainerId: string) => {
  const icQuery = useQuery({
    queryKey: [DisputesQueryKey.TaggedInvoiceContainer],
    queryFn: ({ signal }) => fetchInvoiceContainer(invoiceContainerId, signal),
    enabled: !!invoiceContainerId,
    refetchOnWindowFocus: false,
  });
  const invoiceContainer = icQuery.data;

  const [banners, setBanners] = React.useState<{ uiia: boolean; osra: boolean }>({ osra: false, uiia: false });

  React.useEffect(() => {
    if (!invoiceContainer) return;

    setBanners({
      osra: invoiceContainer.osraComplianceStatus === ComplianceStatus.NonCompliant,
      uiia: invoiceContainer.uiiaComplianceStatus === ComplianceStatus.NonCompliant,
    });
  }, [invoiceContainer, banners]);

  const acknowledgeBanner = React.useCallback(
    (banner: "osra" | "uiia") => {
      setBanners((prevBanners) => {
        const newBanners = { ...prevBanners };
        newBanners[banner] = false;
        return newBanners;
      });
    },
    [banners]
  );

  return {
    invoiceContainer,
    banners,
    acknowledgeBanner,
  };
};
