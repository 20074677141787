import { Combobox } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { ComboboxSelector } from "./Selector";
import { ContainerType } from "../../types/container";
import { ContainerTypeQueryKeys, fetchContainerTypes } from "../../services/container";

interface Props {
  value: string;
  onChange: (value: string) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  label?: React.ReactNode;
  className?: string;
  onSuccess?: (containerTypes: ContainerType[]) => void;
  id?: string;
  disabled?: boolean;
}

export const ContainerSizeSelector: React.FC<Props> = (props) => {
  const { value, onChange, onSuccess, id, disabled } = props;
  const [filter, setFilter] = React.useState("");

  const containerSizes = useQuery(
    [ContainerTypeQueryKeys.ContainerTypes],
    ({ signal }) => fetchContainerTypes({ signal }),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      enabled: !disabled,
    }
  );

  const filteredItems = React.useMemo(() => {
    if (!containerSizes.data) {
      return [];
    }

    const data = [...containerSizes.data]
      .filter((size) => {
        if (!filter) {
          return true;
        }

        return (
          size.name.toLowerCase().includes(filter.toLowerCase()) ||
          size.key.toLowerCase().includes(filter.toLowerCase())
        );
      })
      .sort((a, b) => a.order - b.order);

    return data.map((containerSize) => {
      return (
        <Combobox.Option
          key={containerSize.key}
          value={containerSize.key}
          className="rounded cursor-pointer hover:bg-surface-interactive-accent-3 py-1 px-2"
        >
          {containerSize.name}
        </Combobox.Option>
      );
    });
  }, [containerSizes, filter]);

  return (
    <ComboboxSelector
      label={props.label || "Container Size"}
      value={value}
      onChange={onChange}
      disabled={containerSizes.isLoading || disabled}
      onFilter={(f) => setFilter(f)}
      placeholder="Size"
      startAdornment={props.startAdornment}
      endAdornment={props.endAdornment}
      displayValue={(item: string) => {
        return containerSizes.data?.find((sl) => sl.key === item)?.name || item;
      }}
      className={props.className}
      id={id}
    >
      {filteredItems}
    </ComboboxSelector>
  );
};
ContainerSizeSelector.displayName = "TailwindUI.ContainerSizeSelector";
