import { useFlags } from "launchdarkly-react-client-sdk";

/**
 * An enum containing all our current launch darkly
 * feature flags
 */
export enum FeatureFlag {
  // Allow users to access previous days in the ERL table when enabled
  AllowHistoricalStopTheClock = "allowHistoricalStopTheClock",
  // Remove AccessHistoricalErlTable after business model migration is complete
  AccessHistoricalErlTable = "accessHistoricalErlTable",
  AccessHistoricalPerDiem = "accessHistoricalPerDiem",
  DisableBanners = "disableBanners",
  DisplayReturnBookedAppointments = "displayReturnBookedAppointments",
  DisputeDemo = "disputeDemo",
  DisputeInvoiceUploadQty = "disputeInvoiceUploadQty",
  DisputePerDiemArchive = "disputePerDiemArchive",
  DynamicBanner = "dynamicBanner",
  FeatureLifecycleIndication = "featureLifecycleIndication",
  ImportExportJobs = "importExportJobs",
  InvoiceUploadEnhancements = "invoiceUploadEnhancements",
  Maintenance = "maintenance",
  NewBusinessModel = "newBusinessModel",
  OSRAValidation = "osraValidation",
  OutlookIntegration = "outlookIntegration",
  PandaContracts = "pandaContracts",
  ShippersDashboard = "shippersDashboard",
  ShippersWatchlist = "shippersWatchlist",
  ShippersWatchlistHistoryTab = "shippersWathlistHistoryTab",
  SmartPerDiem = "smartPerDiem",
  SmartPerDiemApptScreenshots = "smartPerDiemApptScreenshots",
  SmartPerDiemOverrides = "smartPerDiemOverrides",
  UIIAValidation = "uiiaValidation",
}

/**
 * Define the shape of any feature flags that are NOT booleans.
 *
 * It is recommended that any nested objects {} are marked partial.
 *
 * Note that the types are not guaranteed to be correct since the
 * payload can be potentially incorrectly set in LaunchDarkly, which
 * is why the getFeaureFlag and useFeatureFlag return partials of
 * these. However even that does not provide full protection as sub
 * fields may still be missing or fields may be of differing types.
 */
export type FeatureFlagPayload = {
  [FeatureFlag.OSRAValidation]: { customRates: boolean; dates: boolean; standardRates: boolean };
  [FeatureFlag.SmartPerDiemOverrides]: { mode: "all" } | { mode: "inactive" } | { mode: "selection"; codes: string[] };
  [FeatureFlag.Maintenance]: { message?: string };
  [FeatureFlag.DynamicBanner]:
    | {
        enabled: true;
        bannerId?: string;
        message?: string;
        kind?: "alert" | "warning" | "notification";
      }
    | { enabled: false };
  [FeatureFlag.DisputeInvoiceUploadQty]: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FeatureFlags = Partial<Record<FeatureFlag, any>>;

// The return type of our feature flags; not it can be undefined if not defined in LD
type FeatureFlagReturnType<F extends FeatureFlag> =
  | (F extends keyof FeatureFlagPayload ? Partial<FeatureFlagPayload[F]> : boolean)
  | undefined;

/**
 * Checks a record of flags for the specified flag and returns
 * its value with optional type casting (defaults to boolean)
 * @param flag the flag to request
 * @param flags the collection of flags
 * @returns the value of the requested flag with all fields
 * marked optional in case they were not properly defined
 * in the flag variation
 */
export function getFeatureFlag<F extends FeatureFlag>(flag: F, flags: FeatureFlags): FeatureFlagReturnType<F> {
  return flags[flag];
}

/**
 * Returns the value of the specified feature flag
 * with optional type casting (defaults to boolean)
 * @param flag the flag to request
 * @returns the value of the requested flag with all fields
 * marked optional in case they were not properly defined
 * in the flag variation
 */
export function useFeatureFlag<F extends FeatureFlag>(flag: F): FeatureFlagReturnType<F> {
  const flags = useFlags();
  return getFeatureFlag(flag, flags);
}
