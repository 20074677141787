import axios from "axios";
import { LocalStorageKey, LocalStorageUtil } from "../lib/local-storage";

// eslint-disable-next-line
export const BACKEND_URL = process.env.API_URL || "https://stg.api.bluecargo.io";

/**
 * This is the axios client for making requests to our backend.
 * It has an interceptor built in to automatically update the access token when it expires.
 */
export const client = axios.create({
  baseURL: BACKEND_URL,
  "Content-Type": "application/json",
  headers: {
    // eslint-disable-next-line
    // @ts-ignore
    Authorization: {
      toString() {
        const token = LocalStorageUtil.get(LocalStorageKey.AccessToken);
        if (token) {
          return `Bearer ${token}`;
        }
        return "";
      },
    },
  },
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const refreshToken = LocalStorageUtil.get(LocalStorageKey.RefreshToken);
    const email = LocalStorageUtil.get(LocalStorageKey.Email);
    if (
      error.response &&
      error.response.status === 401 &&
      error.config &&
      // eslint-disable-next-line
      !error.config.__isRetryRequest &&
      refreshToken
    ) {
      // eslint-disable-next-line
      originalRequest._retry = true;

      return axios
        .post<{ access: string }>(`${BACKEND_URL}/core/api/v1/auth/refresh`, {
          refresh: refreshToken,
          email,
        })
        .then((res) => {
          LocalStorageUtil.set(LocalStorageKey.AccessToken, res.data.access);
          originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
          return axios(originalRequest);
        });
    }
    return Promise.reject(error);
  }
);
