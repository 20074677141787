import { useQuery } from "@tanstack/react-query";
import { uniqueIdForAttachment } from "../../../services/utility";
import { useEmailContext } from "../../../lib/email-context";

/* global Office */

const getJobMapping = async (
  attachments: Office.AttachmentDetails[],
  emailId: string
): Promise<{ attachment: Office.AttachmentDetails; secondaryExternalId: string }[]> => {
  const secondaryIds = await Promise.all(attachments.map((a) => uniqueIdForAttachment(a, emailId)));
  const mapping = attachments.map((a, i) => ({ attachment: a, secondaryExternalId: secondaryIds[i] }));
  return mapping;
};

export const useJobMapping = (attachments: Office.AttachmentDetails[]) => {
  const { emailId } = useEmailContext();
  const mapping = useQuery({
    queryKey: ["job-mapping", attachments, emailId],
    queryFn: () => getJobMapping(attachments, emailId as string),
    enabled: !!attachments?.length && !!emailId,
  });
  return mapping.data;
};
