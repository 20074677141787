import * as React from "react";
import { TaggedInvoiceContainer } from "../../../types/dispute";
import { FeatureFlag, useFeatureFlag } from "../../../lib/permissions/feature-flags";
import { Stats } from "./Stats";
import { OSRABanner } from "./OSRA";
import { UIIABanner } from "./UIIA";

interface Props {
  container: TaggedInvoiceContainer;
  showBanner: {
    osra: boolean;
    uiia: boolean;
  };
  acknowledgeBanner: (banner: "osra" | "uiia") => void;
}

export const Summary: React.FC<Props> = (props) => {
  const { container, showBanner, acknowledgeBanner } = props;

  const uiiaEnabled = useFeatureFlag(FeatureFlag.UIIAValidation);
  const osraFlag = useFeatureFlag(FeatureFlag.OSRAValidation) || {};
  const osraEnabled = Object.values(osraFlag).includes(true);

  return (
    <>
      {uiiaEnabled && <UIIABanner show={showBanner.uiia} onDismiss={() => acknowledgeBanner("uiia")} />}
      {osraEnabled && <OSRABanner show={showBanner.osra} onDismiss={() => acknowledgeBanner("osra")} />}
      <Stats container={container} />
    </>
  );
};
Summary.displayName = "ContainerDispute.Edit.Summary";
