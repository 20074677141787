import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { TaggedInvoiceContainer } from "../types/dispute";
import { DisputeEmailTemplate, DisputesQueryKey, fetchContainerTemplates } from "../services/disputes";
import { ListboxSelector } from "./ListBox";

interface Props {
  container: TaggedInvoiceContainer;
  template?: DisputeEmailTemplate;
  setTemplate?: (template: DisputeEmailTemplate) => void;
  onError?: (error: Error) => void;
}

/**
 * An improvement upon the old AvailableTemplates component
 * that performs additional logic to filter and label templates
 * according to their relavance for the given container
 */
export const DisputeTypeSelector: React.FC<Props> = (props) => {
  const { container, template, setTemplate, onError } = props;

  const availableTemplatesQuery = useQuery({
    queryKey: [DisputesQueryKey.ContainerTemplates, container.id],
    queryFn: ({ signal }) => fetchContainerTemplates(container.id as string, signal),
    onSuccess: (data) => {
      if (data.length && !template) {
        setTemplate?.(data[0].template);
      }
    },
    onError,
  });

  const values = React.useMemo(() => {
    const templateLabelMap = availableTemplatesQuery.data;
    if (!templateLabelMap) return [];

    const templates = availableTemplatesQuery.data.map((t, i) => {
      const label = [t.name, ...(i === 0 ? ["(recommended)"] : []), ...(t.sent ? ["(already sent)"] : [])].join(" ");
      return {
        label,
        value: t.template,
        key: t.template,
      };
    });

    return templates;
  }, [availableTemplatesQuery.data]);

  return (
    <ListboxSelector
      label=""
      value={template || null}
      onChange={(value) => setTemplate?.(value as DisputeEmailTemplate)}
      options={values}
      classes={{ button: "w-1/2", root: "space-y-1" }}
      fallbackButtonLabel={(value) => {
        if (availableTemplatesQuery.isLoading) return "Loading dispute types…";
        return value || "Select a dispute type";
      }}
    />
  );
};
DisputeTypeSelector.displayName = "DisputeTypeSelector";
