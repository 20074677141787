import * as React from "react";
import { InvoiceUploadJob } from "../../../types/dispute";
import { Button } from "../../../components/Button";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "../../../lib/routes";
import { useJobMapping } from "./hooks";
import { HashQueryMapping } from "./WithAttachments";

/* global Office */

interface Props {
  jobs: InvoiceUploadJob[];
  attachments: Office.AttachmentDetails[];
  hashQueryMapping: HashQueryMapping[];
}

/** Show existing invoice upload jobs for selecting */
export const ExistingJobs: React.FC<Props> = ({ jobs, attachments, hashQueryMapping }) => {
  const navigate = useNavigate();

  const mapping = useJobMapping(attachments);

  if (jobs.length === 0 || !mapping) {
    return null;
  }

  return (
    <div className="w-full space-y-2">
      <h4>Existing jobs</h4>
      <ul className="space-y-2">
        {jobs.map((job) => {
          // First try to find the attachment from the hash query mapping
          // If that doesn't exist, try to find it from the job mapping
          // If that doesn't exist, return null
          const attachment = hashQueryMapping.length
            ? hashQueryMapping.find((m) => m.job.id === job.id)?.attachment
            : mapping.find((m) => m.secondaryExternalId === job.secondaryExternalId)?.attachment;
          if (!attachment) return null;
          return (
            <Button
              key={job.id}
              variant="outlined"
              size="sm"
              className="w-full"
              onClick={() => {
                const to = generatePath(Routes.Process, { invoiceJobId: job.id });
                navigate(to);
              }}
            >
              {attachment.name}
            </Button>
          );
        })}
      </ul>
    </div>
  );
};
ExistingJobs.displayName = "Read.WithAttachments.ExistingJobs";
