import type { Invoice, InvoiceContainer, InvoiceContainerRate } from "../../types/dispute";
import { DayAccrual } from "../../types/dispute";
import { ValidityStatus } from "./types";

export const createEmptyRate = (
  invoiceContainerId: InvoiceContainer["id"],
  overrides?: Partial<InvoiceContainerRate>
): InvoiceContainerRate => {
  return {
    id: null,
    startDate: "",
    endDate: "",
    rateCents: 0,
    invoiceContainer: invoiceContainerId,
    rateTotalCents: 0,
    days: 0,
    isFreeTime: false,
    ...overrides,
  };
};

export const createEmptyInvoiceContainer = (invoiceId: Invoice["id"]): InvoiceContainer => {
  return {
    id: null,
    invoice: invoiceId,
    bol: "",
    container: null,
    containerNumber: null,
    containerType: null,
    totalChargesCents: 0,
    transaction: null,
    rates: [createEmptyRate(null, { isFreeTime: true }), createEmptyRate(null)],
    dispute: null,
    freeTimeDaysMode: DayAccrual.WorkingDays,
    chargeTimeDaysMode: DayAccrual.WorkingDays,
    uiiaComplianceStatus: null,
    osraComplianceStatus: null,
    suggestedTemplate: null,
  };
};

export const createEmptyInvoice = (parseJobId: string): Invoice => {
  return {
    id: null,
    createdAt: null,
    parseJob: { id: parseJobId },
    document: null,
    invoiceContainers: [createEmptyInvoiceContainer(null)],
    invoiceIssueDate: null,
    invoiceNumber: null,
    invoicerName: null,
    portOfDelivery: null,
    portOfDeliveryId: null,
    ssl: null,
    sslId: null,
    totalChargesCents: null,
  };
};

export const isValid = <T extends Record<any, ValidityStatus>>(
  value: T | undefined,
  key: keyof T
): boolean | undefined => {
  if (!value) return undefined;
  const val = value[key] as ValidityStatus;
  return val.status === "valid";
};

export const getReason = <T extends Record<any, ValidityStatus>>(
  value: T | undefined,
  key: keyof T
): string | undefined => {
  if (!value) return undefined;
  const val = value[key] as ValidityStatus;
  return val.status !== "valid" ? val.reason : undefined;
};
