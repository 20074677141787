import { Disclosure } from "@headlessui/react";
import * as React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Details } from "./Details";
import { ComplianceBadge } from "../ComplianceBadge";
import { Stat } from "../Stat";
import { ComingSoonBadge } from "../ComingSoonBadge";
import { Summary } from "./Summary";
import { InformationalTooltip } from "../../../../components/InformationalTooltip";
import { ComplianceStatus, ContainerViolation } from "../../../../types/dispute";
import { FeatureFlag, useFeatureFlag } from "../../../../lib/permissions/feature-flags";

interface Props {
  violations: ContainerViolation[];
}

export const OSRA: React.FC<Props> = ({ violations }) => {
  const osraFlag = useFeatureFlag(FeatureFlag.OSRAValidation) || {};
  const osraEnabled = Object.values(osraFlag).includes(true);

  const status = React.useMemo(() => {
    if (violations.some((v) => v.status === ComplianceStatus.NonCompliant)) {
      return ComplianceStatus.NonCompliant;
    }
    if (violations.some((v) => v.status === ComplianceStatus.Skipped) || violations.length === 0) {
      return ComplianceStatus.Skipped;
    }
    return ComplianceStatus.Compliant;
  }, [violations]);

  if (!osraEnabled) {
    return (
      <Stat
        title={
          <div className="flex gap-1.5 items-center">
            <h3 className="font-medium">OSRA Compliance</h3>
            <InformationalTooltip>
              We validate if your invoice meets all of the Ocean Shipping Reform Act of 2022 (OSRA) invoice
              requirements.
            </InformationalTooltip>
          </div>
        }
        value={<ComingSoonBadge />}
      />
    );
  }

  return (
    <Disclosure>
      <Disclosure.Button as="div" className="bg-white py-4 flex items-center justify-between gap-2 cursor-pointer">
        <div className="space-y-2">
          <div className="flex gap-1.5 items-center">
            <h3 className="font-medium">OSRA Compliance</h3>
            <InformationalTooltip>
              We validate if your invoice meets all of the Ocean Shipping Reform Act of 2022 (OSRA) invoice
              requirements.
            </InformationalTooltip>
            <ComplianceBadge status={status || ComplianceStatus.Skipped} />
          </div>
          <Summary status={status || ComplianceStatus.Skipped} violations={violations} />
        </div>
        <div className="flex gap-2 items-center">
          <ChevronDownIcon className="h-4 text-icon-interactive-subdued flex-shrink-0 ui-open:rotate-180 ui-open:transform transition" />
        </div>
      </Disclosure.Button>
      <Disclosure.Panel>
        <Details violations={violations} />
      </Disclosure.Panel>
    </Disclosure>
  );
};
OSRA.displayName = "ContainerDispute.Edit.Summary.OSRA";
