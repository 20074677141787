/* global Office */

import { objectKeysToCamelCase, objectKeysToSnakeCase } from "../lib/common";
import { uniqueIdForAttachment } from "./utility";
import { InvoiceUploadJob } from "../types/dispute";
import { client } from "./client";

interface AttachmentToken {
  attachmentToken: string;
  ewsUrl: string;
}

/** Gets the token from MS for getting an attachment */
export const fetchAttachmentToken = async (): Promise<AttachmentToken> => {
  // Sanity check
  if (!Office.context?.mailbox?.getCallbackTokenAsync || !Office.context?.mailbox?.ewsUrl) {
    return Promise.reject("Office API not available");
  }

  // Get the attachment token and EWS URL
  // Promisify the callback for the token
  const attachmentToken = await new Promise<string>((resolve, reject) =>
    Office.context.mailbox.getCallbackTokenAsync({}, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) resolve(result.value);
      else reject(result.error);
    })
  );
  const ewsUrl = Office.context.mailbox.ewsUrl;

  return { attachmentToken, ewsUrl };
};

export const uploadAttachment = async (
  data: { attachment: Office.AttachmentDetails; emailId: string },
  signal?: AbortSignal
): Promise<InvoiceUploadJob> => {
  const { attachment, emailId } = data;
  // Generate a unique attachment id
  const secondaryExternalId = await uniqueIdForAttachment(attachment, emailId);
  const externalId = emailId;

  // Collect the important data
  const { attachmentToken, ewsUrl } = await fetchAttachmentToken();

  // Make the request
  const url = "/core/api/v1/disputes/outlook/upload-attachment";
  const body = { attachmentToken, ewsUrl, attachmentId: attachment.id, externalId, secondaryExternalId };
  const response = await client.post(url, objectKeysToSnakeCase(body), { signal });

  return objectKeysToSnakeCase<InvoiceUploadJob>(response.data);
};

export const fetchInvoiceUploadJobFromHash = async (
  data: { attachment: Office.AttachmentDetails },
  signal?: AbortSignal
) => {
  const { attachment } = data;

  // Collect the important data
  const { attachmentToken, ewsUrl } = await fetchAttachmentToken();

  // Make the request
  const url = "/core/api/v1/disputes/outlook/hash-attachment";
  const body = { attachmentToken, ewsUrl, attachmentId: attachment.id };
  const response = await client.post(url, objectKeysToSnakeCase(body), { signal });

  return objectKeysToCamelCase(response.data);
};
