import * as React from "react";
import { Navigate, generatePath, useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../lib/routes";
import { useQuery } from "@tanstack/react-query";
import { DisputesQueryKey, fetchInvoiceUploadJob } from "../../services/disputes";
import { JobStatus } from "../../types/dispute";
import { Envelope } from "../../assets/Envelope";
import ProgressBar from "../../components/ProgressBar";
import { Button } from "../../components/Button";

/** Renders a page that handles the invoice job processing, error, and success states */
export const Process: React.FC = () => {
  const { invoiceJobId } = useParams<{ invoiceJobId: string }>();
  const jobQuery = useQuery({
    queryKey: [DisputesQueryKey.InvoiceJob, invoiceJobId],
    queryFn: ({ signal }) => fetchInvoiceUploadJob(invoiceJobId as string, signal),
    enabled: !!invoiceJobId,
    retry: false,
    refetchInterval: 5000,
  });

  const navigate = useNavigate();

  // No id, nothing to do
  if (!invoiceJobId) {
    return <Navigate to={Routes.Read} />;
  }

  if (jobQuery.isError) {
    return (
      <div className="flex-grow flex items-center justify-center text-center flex-col space-y-2">
        <h1 className="text-red">Could not check the status of the invoice job</h1>
        <Button onClick={() => navigate(Routes.Read)}>Back to selection</Button>
      </div>
    );
  }

  switch (jobQuery.data?.status) {
    case JobStatus.Success:
    case JobStatus.Duplicate:
      // continue to next step
      return <Navigate to={generatePath(Routes.Verify, { invoiceJobId })} />;
    case JobStatus.Error:
      // render error
      return <h1>Something went wrong while processing the invoice</h1>;
    default:
    case JobStatus.Pending:
    case JobStatus.InProgress:
    case JobStatus.Retrying:
      // render loading bar
      return (
        <div className="flex-grow flex flex-col items-center p-8 space-y-8">
          <Envelope />
          <h3 className="m-0 font-medium text-text-interactive-disabled text-lg">
            Hang tight, we&apos;re processing your invoice…
          </h3>
          <ProgressBar />
        </div>
      );
  }
};
Process.displayName = "Process";
