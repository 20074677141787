import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { Tooltip } from "../Tooltip";

interface Props {
  valid?: boolean;
  /** Why there is an error */
  reason?: string;
}

export const Indicator: React.FC<Props> = ({ valid, reason }) => {
  if (valid || valid === undefined) return null;
  return (
    <Tooltip tooltip={reason}>
      <ExclamationTriangleIcon className="w-4 h-4 ml-1 text-icon-informative-warning cursor-pointer" />
    </Tooltip>
  );
};
Indicator.displayName = "Dispute.Confirmation.Indicator";
