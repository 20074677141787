import * as React from "react";
import { Indicator } from "./Indicator";
import { InputProps, LabeledInput } from "../Input";

interface Props {
  label: React.ReactNode;
  valid?: boolean;
  inputProps?: InputProps;
  reason?: string;
  required?: boolean;
}

export const IndicatedInput: React.FC<Props> = (props) => {
  const { label, valid, inputProps, reason, required } = props;

  return (
    <LabeledInput
      label={
        <h4 className="text-text-accent-1 font-medium">
          {label}
          {required && <span className="text-text-informative-critical">*</span>}
        </h4>
      }
      inputProps={{ ...inputProps, endAdornment: valid !== undefined && <Indicator valid={valid} reason={reason} /> }}
    />
  );
};
IndicatedInput.displayName = "Dispute.Confirmation.IndicatedInput";
