import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import * as React from "react";

export const CollapsableSection: React.FC<{
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}> = ({ title, children, className }) => {
  return (
    <Disclosure as="div" defaultOpen>
      <Disclosure.Button className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between gap-2 w-full py-2 border-b">
          {title}
          <ChevronDownIcon className="h-5 flex-shrink-0 ui-open:rotate-180 transition" />
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className={classNames("py-4", className)}>{children}</Disclosure.Panel>
    </Disclosure>
  );
};
CollapsableSection.displayName = "Verification.CollapsableSection";
