import * as React from "react";
import { ComplianceStatus, ContainerViolation, ViolationType } from "../../../../types/dispute";

interface Props {
  status: ComplianceStatus;
  violations: ContainerViolation[];
}

const Label = ({ children }: { children: React.ReactNode }) => (
  <h5 className="font-medium text-xs text-text-secondary">{children}</h5>
);

export const Summary: React.FC<Props> = ({ status, violations }) => {
  if (violations.length === 0) {
    return null;
  }

  switch (status) {
    case ComplianceStatus.Skipped:
      return <Label>Unable to validate. Container not in Shipment Watchlist.</Label>;
    case ComplianceStatus.NonCompliant: {
      const nonCompliant = violations.filter((v) => v.status === ComplianceStatus.NonCompliant);
      if (
        nonCompliant.some((v) =>
          [
            ViolationType.GatedInDateMismatch,
            ViolationType.GatedOutDateMismatch,
            ViolationType.GatedInDateMissing,
            ViolationType.GatedOutDateMissing,
          ].includes(v.type)
        ) &&
        nonCompliant.some((v) =>
          [
            ViolationType.ContractRatesMismatch,
            ViolationType.StandardRatesMismatch,
            ViolationType.RatesMissing,
          ].includes(v.type)
        )
      ) {
        return <Label>Invoice has incorrect or missing dates and rates.</Label>;
      }
      if (nonCompliant.some((v) => v.type === ViolationType.RatesMissing)) {
        return <Label>Invoice is missing Issue Date.</Label>;
      }
      if (nonCompliant.some((v) => v.type === ViolationType.ContractRatesMismatch)) {
        return <Label>Invoice rates do not match contract rates.</Label>;
      }
      if (nonCompliant.some((v) => v.type === ViolationType.StandardRatesMismatch)) {
        return <Label>Invoice rates do not match standard rates.</Label>;
      }
      if (
        nonCompliant.some((v) => [ViolationType.GatedInDateMissing, ViolationType.GatedOutDateMissing].includes(v.type))
      ) {
        return <Label>Invoice dates are missing.</Label>;
      }
      if (
        nonCompliant.some((v) =>
          [ViolationType.GatedInDateMismatch, ViolationType.GatedOutDateMismatch].includes(v.type)
        )
      ) {
        return <Label>Invoice dates are incorrect.</Label>;
      }
      break;
    }
    case ComplianceStatus.Compliant:
    default:
      return null;
  }

  return null;
};
Summary.displayName = "ContainerDispute.Edit.Summary.OSRA.Summary";
