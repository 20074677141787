import { InputProps } from "../Input";
import * as React from "react";
import { LabeledFormattedNumericInput } from "../Input/LabeledFormattedNumericInput";
import { Indicator } from "./Indicator";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

interface Props {
  label: React.ReactNode;
  valid?: boolean;
  inputProps?: Omit<InputProps, "value" | "onChange">;
  value: number;
  onChange?: (value: number) => void;
  reason?: string;
  required?: boolean;
  className?: string;
}

export const IndicatedMonetaryInput: React.FC<Props> = (props) => {
  const { label, valid, inputProps, value, onChange, reason, required, className } = props;

  return (
    <LabeledFormattedNumericInput
      label={
        <h4 className="text-text-accent-1 font-medium">
          {label}
          {required && <span className="text-text-informative-critical">*</span>}
        </h4>
      }
      inputProps={{
        endAdornment: valid !== undefined && <Indicator valid={valid || false} reason={reason} />,
        min: 0,
        step: 0.01,
        placeholder: "$",
        ...inputProps,
      }}
      value={value}
      onChange={onChange}
      formatter={currencyFormatter.format}
      className={className}
    />
  );
};
IndicatedMonetaryInput.displayName = "Dispute.Confirmation.IndicatedMonetaryInput";
