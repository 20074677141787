import * as React from "react";
import { DisputeEmailTemplate } from "../services/disputes";
import { EmailMapping } from "../types/dispute";

const TemplateContext = React.createContext<{
  templateMapping: EmailMapping;
  setTemplateMapping: React.Dispatch<React.SetStateAction<EmailMapping>>;
}>({
  templateMapping: {},
  setTemplateMapping: () => {},
});

export const TemplateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [templateMapping, setTemplateMapping] = React.useState<EmailMapping>({});

  return (
    <TemplateContext.Provider value={{ templateMapping, setTemplateMapping }}>{children}</TemplateContext.Provider>
  );
};

export const useTemplateContext = () => {
  const context = React.useContext(TemplateContext);
  if (!context) {
    throw new Error("useTemplateContext must be used within a TemplateProvider");
  }
  return context;
};

export const useTemplate = (invoiceContainerId?: string) => {
  const { templateMapping, setTemplateMapping } = useTemplateContext();
  return React.useMemo(() => {
    if (!invoiceContainerId) return { template: undefined, setTemplate: () => {} };
    const template = templateMapping[invoiceContainerId];
    const setTemplate = (template: DisputeEmailTemplate) => {
      setTemplateMapping((prev) => ({ ...prev, [invoiceContainerId]: template }));
    };
    return { template, setTemplate };
  }, [invoiceContainerId, templateMapping, setTemplateMapping]);
};
