import React from "react";
import { Invoice, TaggedInvoiceContainer } from "../../../types/dispute";
import { useDisputability } from "../../../hooks/disputes";
import { StatusPill } from "./StatusPill";
import { DisputeEmailTemplate, DisputesQueryKey, fetchInvoiceContainer } from "../../../services/disputes";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { DisputeTypeSelector } from "../../../components/DisputeType";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDisputeOpportunity } from "../hooks";
import { currencyFormatterNoCents, percentageFormatterNoDecimal } from "../Opportunity";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "../../../lib/routes";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/Button";
import { useTemplate } from "../../../lib/template-context";

interface Props {
  invoiceContainerId: TaggedInvoiceContainer["id"];
  invoice?: Invoice | null;
  hideButton?: boolean;
}

const Information: React.FC<{ label: string; value: string | number | null | undefined }> = ({ label, value }) => (
  <div className="flex gap-0.5 items-center">
    <h6 className="text-xs font-medium uppercase text-text-subdued">{label}:</h6>
    <h4 className="text-text-interactive-solid-secondary text-sm font-medium">{value}</h4>
  </div>
);

const MiniStat: React.FC<{ label: string; value: string | null | undefined }> = ({ label, value }) => (
  <div className="flex flex-col space-y-0.5 border border-[#AC5605] p-1 py-0.5 rounded shadow-sm">
    <h6 className="text-xs font-medium uppercase text-text-subdued">{label}</h6>
    <h4 className="text-[#AC5605] text-sm font-medium">{value}</h4>
  </div>
);

const ContainerDetailCard: React.FC<Props> = (props) => {
  const { invoiceContainerId, invoice, hideButton } = props;

  const { template, setTemplate } = useTemplate(invoiceContainerId as string);

  const taggedICQuery = useQuery({
    queryKey: [DisputesQueryKey.TaggedInvoiceContainer, invoiceContainerId],
    queryFn: ({ signal }) => fetchInvoiceContainer(invoiceContainerId, signal),
  });

  const invoiceContainer = taggedICQuery.data || ({} as TaggedInvoiceContainer);

  const disputeOpportunity = useDisputeOpportunity({ invoiceContainers: [invoiceContainer] });
  const disputability = useDisputability(invoiceContainer as TaggedInvoiceContainer);
  const navigate = useNavigate();
  const [hadTemplateError, setHadTemplateError] = React.useState(false);
  const queryClient = useQueryClient();

  const regenerateTemplate = React.useCallback(() => {
    queryClient.invalidateQueries([[DisputesQueryKey.ContainerTemplates, invoiceContainer.id]]);
    setHadTemplateError(false);
  }, [invoiceContainer.id, queryClient]);

  if (!invoiceContainer || !invoice) return null;

  const { ssl } = invoice;

  return (
    <div className="flex flex-col">
      <div className={`flex flex-col space-y-2 ${!hideButton && "border border-b-0 rounded-t px-2 py-5"}`}>
        <div className="flex gap-2 items-center">
          {ssl && ssl.logo && <img src={ssl.logo} alt={ssl.name} className="max-h-8" />}
          <StatusPill disputability={disputability} />
        </div>
        <div className="grid grid-cols-2 gap-2 border-b py-1">
          <Information label="Invoice" value={invoice.invoiceNumber} />
          <Information label="Issued By" value={invoice.invoicerName} />
          <Information label="Container" value={invoiceContainer.containerNumber} />
          <Information label="Size" value={invoiceContainer.containerType} />
        </div>
        {hadTemplateError && (
          <div className="flex flex-col space-y-3 pt-2 items-center justify-center">
            <ExclamationTriangleIcon className="w-8 h-8 text-icon-interactive-tertiary" />
            <h6 className="text-[10px] font-medium uppercase text-text-informative-warning text-center leading-tight">
              There was an error generating an email template for this container.
            </h6>
            <Button
              onClick={regenerateTemplate}
              className="flex !py-1 !px-2.5 transition-all !text-black !bg-surface-interactive-solid-secondary hover:opacity-90 rounded"
            >
              <h3 className="font-medium text-xs">Regenerate dispute email</h3>
            </Button>
            <h5 className="text-xs font-medium text-text-interactive-solid-secondary">
              Or review your dispute on BlueCargo{" "}
              <a
                className="text-text-interactive-outline-primary"
                href={`https://erl.bluecargo.io/dispute/tracker?focusedInvoice=${invoiceContainer?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                Dispute Tracker
              </a>
            </h5>
          </div>
        )}
        {!hadTemplateError && (
          <>
            <div className="grid grid-cols-2 gap-2 py-1">
              <MiniStat
                label="Disputable opportunity"
                value={percentageFormatterNoDecimal.format(disputeOpportunity.metrics.disputeOpportunity)}
              />
              <MiniStat
                label="Disputable amount"
                value={currencyFormatterNoCents.format(disputeOpportunity.metrics.totalSavings / 100)}
              />
            </div>
            <div className="flex flex-col pt-1">
              <h6 className="text-xs font-medium uppercase text-text-subdued">Dispute Email Template:</h6>
              <DisputeTypeSelector
                container={invoiceContainer}
                template={template}
                setTemplate={setTemplate}
                onError={() => setHadTemplateError(true)}
              />
            </div>
          </>
        )}
      </div>
      {!hideButton && (
        <div
          onClick={() => {
            const to = generatePath(Routes.ContainerDetails, { invoiceContainerId: invoiceContainerId });
            navigate(to);
          }}
          className="group cursor-pointer flex justify-center gap-2 items-center p-2 px-5 bg-gray-100 hover:bg-gray-200 transition-colors border border-gray-300 rounded-lg rounded-t-none"
        >
          <div className="truncate text-sm font-medium text-text-secondary">See details</div>
          <ChevronRightIcon className="w-4 h-4 text-text-secondary transition-all group-hover:ml-1" />
        </div>
      )}
    </div>
  );
};

export default ContainerDetailCard;
