import * as React from "react";
import { ContractType, TaggedInvoiceContainer } from "../../../types/dispute";
import { useQuery } from "@tanstack/react-query";
import { Stat } from "./Stat";
import { OSRA } from "./OSRA";
import { UIIA } from "./UIIA";
import { percentageFormatterNoDecimal } from "../../Opportunity";
import { calculateInvoiceContainerDateRange } from "../../../lib/common";
import { DisputesQueryKey, fetchContainerViolations } from "../../../services/disputes";

interface Props {
  container: TaggedInvoiceContainer;
}

export const Stats: React.FC<Props> = (props) => {
  const { container } = props;

  const stats = React.useMemo(() => {
    const { days } = calculateInvoiceContainerDateRange(container);
    return {
      invoicedDays: days,
      // TODO: I think this needs to be corrected
      disputableDays: Math.floor((days || 0) * (container.disputeOpportunity?.estSavingsPercent || 0)),
      disputeOpportunity: container.disputeOpportunity?.estSavingsPercent || 0,
    };
  }, [container]);

  const violationsQuery = useQuery({
    queryKey: [DisputesQueryKey.ContainerViolations, container.id],
    queryFn: ({ signal }) => fetchContainerViolations(container.id as string, signal),
  });

  const violations = React.useMemo(() => {
    const osra = violationsQuery.data?.filter((v) => v.contract === ContractType.OSRA) || [];
    const uiia = violationsQuery.data?.filter((v) => v.contract === ContractType.UIIA) || [];
    return { osra, uiia };
  }, [violationsQuery.data]);

  return (
    <div className="divide-y border-b">
      <UIIA violations={violations.uiia} />
      <OSRA violations={violations.osra} />
      <Stat
        title={<h3 className="font-medium text-sm"># of invoiced days</h3>}
        value={<h3 className="font-medium text-lg">{stats.invoicedDays}</h3>}
      />
      <Stat
        title={<h3 className="font-medium text-sm"># of disputable days</h3>}
        value={<h3 className="font-medium text-lg">{stats.disputableDays}</h3>}
      />
      <Stat
        title={<h3 className="font-medium text-sm">Dispute opportunity</h3>}
        value={<h3 className="font-medium text-lg">{percentageFormatterNoDecimal.format(stats.disputeOpportunity)}</h3>}
      />
    </div>
  );
};
Stats.displayName = "ContainerDispute.Edit.Summary.Stats";
