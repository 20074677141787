import * as React from "react";
import { Button } from "../../components/Button";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { KeyMetricCard } from "./KeyMetricCard";
import { BACKEND_URL } from "../../services/client";
import { DisputeEmailTemplate, updateDisputeStatus } from "../../services/disputes";
import { generateEmail, useDisputeOpportunity, useUploadJob } from "./hooks";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  AnalyticsEvent,
  invoiceContainerToInvoiceContainerGroup,
  invoiceContainerToShipmentGroup,
  invoiceToInvoiceGroup,
  useAnalytics,
} from "../../lib/segment";
import { DisputeState, EmailMapping, TaggedInvoiceContainer } from "../../types/dispute";
import { Routes } from "../../lib/routes";
import { useEmailContext } from "../../lib/email-context";
import { CustomAccordion } from "./CustomAccordion";
import ContainerDetailCard from "./ContainerDetails/ContainerDetailCard";
import { useTemplateContext } from "../../lib/template-context";
import UpgradeBanner from "../../components/UpgradeBanner";

/* global Office */

export const currencyFormatterNoCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const percentageFormatterNoDecimal = Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 0,
});

export const Opportunity: React.FC = () => {
  const { invoiceJobId } = useParams();
  const { invoiceUploadJob } = useUploadJob(invoiceJobId || "");
  const navigate = useNavigate();
  const { emailId } = useEmailContext();

  const anyHaveBeenSent = invoiceUploadJob?.parsedInvoices.some((invoice) =>
    invoice.invoiceContainers.some(
      (ic) => ic.dispute?.status.status && ic.dispute.status.status === DisputeState.PendingSSLReply
    )
  );
  if (anyHaveBeenSent) {
    const to = generatePath(Routes.AlreadySent, { invoiceJobId: invoiceJobId as string });
    navigate(to);
  }

  const { metrics } = useDisputeOpportunity({ invoiceUploadJob });

  const canFightAll = invoiceUploadJob?.parsedInvoices.every((invoice) =>
    invoice.invoiceContainers.every(
      (ic) => ic.dispute?.status.status && ic.dispute.status.status !== DisputeState.CreditHold
    )
  );

  const track = useAnalytics();

  const { templateMapping, setTemplateMapping } = useTemplateContext();

  React.useEffect(() => {
    if (templateMapping) return; // Don't overwrite existing templates
    const templates: EmailMapping = {};
    invoiceUploadJob?.parsedInvoices.forEach((invoice) => {
      if (invoice && invoice.invoiceContainers) {
        invoice.invoiceContainers.forEach((ic) => {
          if (ic.id) {
            templates[ic.id] = undefined;
          }
        });
      }
    });
    setTemplateMapping(templates);
  }, [invoiceUploadJob]);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    invoiceUploadJob?.parsedInvoices.forEach((parsedInvoice) => {
      parsedInvoice.invoiceContainers.forEach(async (ic) => {
        const attachment = ic.dispute?.documents[0];
        if (!attachment || !ic.id) return;

        // Template *should* always be defined by now
        const email = await generateEmail(ic.id, templateMapping[ic.id] as DisputeEmailTemplate, emailId);

        if (ic.dispute?.status) {
          updateDisputeStatus({
            disputeStatus: {
              id: ic.dispute.status.id,
              status: DisputeState.PendingSSLReply,
            },
          });
        }

        Office.context.mailbox.displayNewMessageFormAsync({
          subject: email.subject,
          toRecipients: email.recipients,
          htmlBody: email.htmlBody,
          attachments: [
            {
              type: "file",
              name: attachment.uploadedFilename,
              // Removes trailing slash from BACKEND_URL if present
              url: `${BACKEND_URL.replace(/\/$/, "")}${attachment.attachmentUrl}`,
            },
          ],
        });
      });
    });

    const invoice = invoiceUploadJob?.parsedInvoices[0];
    track(AnalyticsEvent.ContainerDisputeEmailGenerated, {
      disputeGeneratedSource: "email plug-in",
      invoice: invoiceToInvoiceGroup(invoice),
      containers: (invoice?.invoiceContainers || []).map((c) => ({
        ...invoiceContainerToInvoiceContainerGroup(c),
        ...invoiceContainerToShipmentGroup(c as TaggedInvoiceContainer),
      })),
    });
  };

  return (
    <div className="w-full h-full flex flex-col overflow-y-scroll">
      <div className="mx-5 my-4 flex flex-col flex-grow pb-20">
        {!canFightAll ? (
          <UpgradeBanner
            title="You have reached your monthly Per Diem dispute amount limit!"
            description="Don't worry, we've saved your invoices in the Dispute Tracker. Upgrade now to finish your
            disputes and continue validating new invoices."
          />
        ) : (
          <div className="flex flex-col space-y-3">
            <CustomAccordion title="Dispute opportunity">
              <KeyMetricCard
                title="Dispute opportunity"
                value={percentageFormatterNoDecimal.format(metrics.disputeOpportunity)}
                description="Based on number of disputable days"
              />
              <KeyMetricCard
                title="Estimated savings"
                value={currencyFormatterNoCents.format(metrics.totalSavings / 100)}
                description="Based on standard per diem rates"
              />
            </CustomAccordion>
            <CustomAccordion title="Dispute reports">
              {invoiceUploadJob?.parsedInvoices.map((invoice) =>
                invoice.invoiceContainers.map((ic) => (
                  <ContainerDetailCard key={ic.id} invoice={invoice} invoiceContainerId={ic.id} />
                ))
              )}
            </CustomAccordion>
          </div>
        )}
      </div>
      <div className="px-5 py-4 flex border-t absolute bottom-0 bg-white z-50 w-full">
        <Button
          onClick={handleSubmit}
          disabled={!canFightAll}
          className="flex !py-1 w-full transition-all !text-black !bg-surface-interactive-solid-secondary hover:opacity-90 rounded"
        >
          <div className="flex items-center gap-1">
            {!canFightAll && <LockClosedIcon className="w-4 h-4 text-icon-interactive-disabled-1" />}
            <h3 className="font-medium">Generate dispute email</h3>
          </div>
        </Button>
      </div>
    </div>
  );
};
Opportunity.displayName = "Dispute.Opportunity";
