import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PillBadge } from "../../../components/Badges/PillBadge";
import * as React from "react";
import { ComplianceStatus } from "../../../types/dispute";

interface Props {
  status: ComplianceStatus;
}

export const ComplianceBadge: React.FC<Props> = ({ status }) => {
  switch (status) {
    case ComplianceStatus.Compliant:
      return <PillBadge color="green" icon={<CheckIcon />} label="Compliant" />;
    case ComplianceStatus.NonCompliant:
      return <PillBadge color="orange" icon={<ExclamationCircleIcon />} label="Noncompliant" />;
    case ComplianceStatus.Skipped:
    default:
      return <PillBadge color="gray" icon={<XMarkIcon />} label="Skipped" />;
  }
};
ComplianceBadge.displayName = "Summary.ComplianceBadge";
