import * as React from "react";
import { Envelope } from "../../../assets/Envelope";
import { TruckIcon } from "@heroicons/react/24/solid";
import { useInvoiceJobs } from "../hooks";
import { ExistingJobs } from "./ExistingJobs";
import { NewJob } from "./NewJob";
import { Navigate, generatePath } from "react-router-dom";
import { Routes } from "../../../lib/routes";
import { useQuery } from "@tanstack/react-query";
import { DisputesQueryKey } from "../../../services/disputes";
import { fetchInvoiceUploadJobFromHash } from "../../../services/outlook";
import { InvoiceUploadJob } from "../../../types/dispute";

/* global Office */

interface Props {
  emailId: string;
  attachments: Office.AttachmentDetails[];
}

export interface HashQueryMapping {
  attachment: Office.AttachmentDetails;
  job: InvoiceUploadJob;
}

export const WithAttachments: React.FC<Props> = ({ emailId, attachments }) => {
  const [invoiceJobsQuery, enabled] = useInvoiceJobs(emailId);
  const [attachmentsFromHashQuery, setAttachmentsFromHashQuery] = React.useState<HashQueryMapping[]>([]);

  const fetchInvoiceUploadJobsByHashes = async () => {
    let fetchPromises: Promise<InvoiceUploadJob>[] = [];

    attachments.forEach((attachment) => {
      fetchPromises.push(
        fetchInvoiceUploadJobFromHash({ attachment })
          .then((response) => {
            if (response.status !== 404) {
              // Matches the attachment to the existing job if it exists
              setAttachmentsFromHashQuery((a) => [...a, { attachment, job: response }]);
              return response;
            }
            return null;
          })
          .catch(() => null)
      );
    });

    return await Promise.all(fetchPromises);
  };

  const hashQuery = useQuery({
    queryKey: [DisputesQueryKey.InvoiceUploadJobsByHashes, attachments],
    queryFn: fetchInvoiceUploadJobsByHashes,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const uniqueJobs = React.useMemo(() => {
    const jobs = [...(invoiceJobsQuery.data || []), ...(hashQuery.data || [])].filter((job) => job !== null);
    return Array.from(new Set(jobs.map((job) => job.id))).map((id) => {
      return jobs.find((job) => job.id === id);
    }) as InvoiceUploadJob[];
  }, [invoiceJobsQuery.data, hashQuery.data]);

  // if we're still checking for jobs show the loader…
  if ((invoiceJobsQuery.isLoading && enabled) || hashQuery.isLoading) {
    return (
      <div className="flex-grow">
        <div className="flex flex-col items-center justify-center h-full p-8">
          <TruckIcon className="h-6 animate-bounce" />
          <h1 className="text-lg text-text-subdued">Checking for existing invoice uploads…</h1>
        </div>
      </div>
    );
  }

  if (uniqueJobs.length === 1 && attachments.length === 1) {
    return <Navigate to={generatePath(Routes.Process, { invoiceJobId: uniqueJobs[0].id })} />;
  }

  return (
    <div className="flex flex-col items-center p-8 gap-8">
      <div className="flex items-center justify-center">
        <Envelope />
      </div>
      <h3 className="text-lg">Attachments found</h3>
      <div className="flex flex-col items-center gap-8 w-full">
        <NewJob
          attachments={attachments}
          jobs={uniqueJobs}
          additionalExistingAttachments={attachmentsFromHashQuery.map((a) => a.attachment)}
          emailId={emailId}
        />
        <ExistingJobs jobs={uniqueJobs} hashQueryMapping={attachmentsFromHashQuery} attachments={attachments} />
      </div>
    </div>
  );
};
WithAttachments.displayName = "Read.WithAttachments";
