import classNames from "classnames";
import * as React from "react";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

export const AddButton: React.FC<Props> = (props) => {
  const { onClick, children, className } = props;

  return (
    <h4
      className={classNames(
        "text-blue-900 font-medium cursor-pointer select-none hover:bg-gray-100 inline-block rounded p-1 transition-all",
        className
      )}
      onClick={onClick}
    >
      {children}
    </h4>
  );
};
AddButton.displayName = "Dispute.Confirmation.AddButton";
