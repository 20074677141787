import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import * as React from "react";
import { ContainerDisputability, Disputability } from "../../../types/dispute";

interface Props {
  disputability: ContainerDisputability;
}

export const StatusPill: React.FC<Props> = ({ disputability }) => {
  const { icon, label } = React.useMemo(() => {
    switch (disputability.status) {
      case Disputability.Disputable:
        return { icon: <CheckIcon className="h-4" />, label: "Ready to dispute" };
      case Disputability.RequiresDocumentation:
      default:
        return { icon: <ExclamationCircleIcon className="h-4" />, label: "More docs required" };
    }
  }, [disputability]);

  if (disputability.status === Disputability.CreditHold) {
    return null;
  }

  return (
    <div
      className={classNames("flex items-center space-x-2 px-3 py-1 rounded", {
        "bg-surface-informative-warning text-text-informative-warning":
          disputability.status === Disputability.RequiresDocumentation,
        "bg-green-50 text-green-900": disputability.status === Disputability.Disputable,
      })}
    >
      {icon}
      <h4>{label}</h4>
    </div>
  );
};
