import * as React from "react";
import { ComplianceStatus, ViolationType } from "../../../../types/dispute";

interface Props {
  status: ComplianceStatus;
  type: ViolationType;
  reasonDefault?: string;
}

export const DateStatus: React.FC<Props> = ({ status, type, reasonDefault }) => {
  let nonComplianceReason = reasonDefault;
  switch (type) {
    case ViolationType.GatedInDateMissing:
    case ViolationType.GatedOutDateMissing:
      nonComplianceReason = "Date missing";
      break;
    case ViolationType.GatedInDateMismatch:
    case ViolationType.GatedOutDateMismatch:
      nonComplianceReason = "Date mismatch";
      break;
    default:
      break;
  }

  let label: React.ReactNode = null;
  switch (status) {
    case ComplianceStatus.Skipped:
      label = <h4 className="text-text-subdued">Validation Skipped</h4>;
      break;
    case ComplianceStatus.Compliant:
      label = <h4 className="text-text-informative-success">{reasonDefault || "Date Matched"}</h4>;
      break;
    case ComplianceStatus.NonCompliant:
      if (nonComplianceReason) {
        label = <h4 className="text-text-informative-warning">{nonComplianceReason}</h4>;
      }
      break;
    default:
      break;
  }

  if (!label) {
    return null;
  }

  return <div className="font-medium flex justify-end text-right">{label}</div>;
};
DateStatus.displayName = "Summary.DateStatus";
