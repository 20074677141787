import * as React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@fluentui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./AuthProvider";
import { EmailProvider } from "../lib/email-context";
import { datadogLogs } from "@datadog/browser-logs";
import { TemplateProvider } from "../lib/template-context";

datadogLogs.init({
  clientToken: "pub05c9e5605a929975c462fd632c77cbd1",
  service: "outlook-addin",
  env: "development",
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

const queryClient = new QueryClient();

/**
 * The root component of the app; is responsible for
 * rendering everything via its outlet
 */
export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <EmailProvider>
              <TemplateProvider>
                <div id="App" className="bg-white h-[100vh] w-[100vw] flex flex-col overflow-hidden">
                  <Outlet />
                </div>
              </TemplateProvider>
            </EmailProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
App.displayName = "App";
