import { useLDClient } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { useUserContext } from "./context";
import { TruckIcon } from "@heroicons/react/20/solid";

/* global window */

/**
 * Gates the application with a loader until the user has been
 * identified successfully with LaunchDarkly. Note this MUST be placed
 * such that it does not block the identification from occuring.
 */
export const LDGate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const ldClient = useLDClient();

  // FAIL SAFE: if we can't identify for whatever reason we'll bypass it after 5 seconds
  // as to not block the entire application
  const [overrideIdentification, setOverrideIdentification] = React.useState(false);
  React.useEffect(() => {
    const overrideTimer = window.setTimeout(() => {
      setOverrideIdentification(true);
    }, 5000);
    return () => {
      window.clearTimeout(overrideTimer);
    };
  });

  // This must remain to trigger a re-render when appropriate
  const { user } = useUserContext();
  const hasIdentified = user ? ldClient?.getContext()?.key !== "anonymous" : true;
  if (!hasIdentified && !overrideIdentification) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <TruckIcon className="animate-spin h-10 w-10 text-gray-500" />
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
LDGate.displayName = "LDGate";
