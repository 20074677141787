import * as React from "react";
import { createPortal } from "react-dom";

interface Props {
  children?: React.ReactNode;
}

/**
 * This component is for when you explicitly want its children
 * to cover the entire application, including the navigation.
 * Use deliberately.
 */
export const TopLevelPortal: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line
  const element = document.getElementById("App");
  if (!element) {
    return null;
  }

  return createPortal(
    // This pointer events nonsense is since there are cases where this
    // component may always be mounted and without it, it will block all
    // pointer events to the page.
    <div className="fixed inset-0 z-20 pointer-events-none [&>*]:pointer-events-auto">{children}</div>,
    element
  );
};
TopLevelPortal.displayName = "Layouts.TopLevelPortal";
