import * as React from "react";
import { TopLevelPortal } from "../TopLevelPortal";
import { useUserContext } from "../AuthProvider/context";
import { Button } from "../../components/Button";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AccountModal: React.FC<Props> = ({ open, onClose }) => {
  const { user } = useUserContext();

  if (!open) {
    return null;
  }
  return (
    <TopLevelPortal>
      <div className="fixed inset-0 bg-black bg-opacity-10 px-6 py-16" onClick={onClose}>
        <div
          className="bg-white shadow-lg p-4 space-y-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="space-y-2">
            <p className="font-medium">
              {user?.firstName} {user?.lastName}
            </p>
            <p>{user?.email}</p>
          </div>
          <Button onClick={onClose} className="w-full">
            <h4>Close</h4>
          </Button>
        </div>
      </div>
    </TopLevelPortal>
  );
};
AccountModal.displayName = "Footer.AccountModal";
