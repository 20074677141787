import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
}

export const CustomAccordion: React.FC<Props> = (props) => {
  const { title, children } = props;

  return (
    <Disclosure as="div" className="flex flex-col space-y-4" defaultOpen>
      <Disclosure.Button as="div" className="py-2 flex items-center justify-between gap-2 cursor-pointer border-b">
        <h4 className="font-bold text-sm">{title}</h4>
        <ChevronDownIcon className="h-5 text-icon-interactive-subdued flex-shrink-0 ui-open:rotate-180 ui-open:transform transition" />
      </Disclosure.Button>
      <Disclosure.Panel className="flex flex-col space-y-4">{children}</Disclosure.Panel>
    </Disclosure>
  );
};
