import * as React from "react";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { Input, InputProps } from "./Input";

export type LabelProps = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export interface LabeledInputProps {
  inputProps?: InputProps;
  labelProps?: LabelProps;
  label: React.ReactNode;
  className?: string;
}

export const LabeledInput: React.FC<LabeledInputProps> = (props) => {
  const { inputProps, labelProps, label, className } = props;
  const id = uniqueId("labeled-input");
  return (
    <div className={classNames("flex flex-col gap-1", className)}>
      <label {...labelProps} htmlFor={id}>
        {label}
      </label>
      <Input {...inputProps} id={id} />
    </div>
  );
};
