import * as React from "react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./Routes";

import "./index.css";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { BACKEND_URL } from "./services/client";

// This is some weird MS import nonsense
/* global document, Office, module, require */

initializeIcons();

const router = createBrowserRouter(routes);
const routerProvider = () => <RouterProvider router={router} />;

// TODO: Refactor into config files
let ldClientKey = "61817c96cac553630b901cb0"; // Default to staging

if (BACKEND_URL.includes("localhost")) {
  ldClientKey = "6182e8a2823209608fbf1054";
} else if (BACKEND_URL == "https://api.bluecargo.io") {
  ldClientKey = "61817bf701d27d6405892a8a";
}

const LDProvider = withLDProvider({
  clientSideID: ldClientKey,
  user: {
    // Avoid using a MAU credit for each anonymous user
    anonymous: false,
    key: "anonymous",
  },
})(routerProvider);

/* Render application after Office initializes */
Office.onReady(() => {
  createRoot(document.getElementById("container") as HTMLElement).render(<LDProvider />);
});
