export enum LocalStorageKey {
  AccessToken = "accessToken",
  RefreshToken = "refreshToken",
  Email = "email",
}

// eslint-disable-next-line
const storage = window.localStorage;

export const LocalStorageUtil = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(storageItemName: LocalStorageKey | string, value: any) {
    const stringifiedValue = JSON.stringify(value);
    storage.setItem(storageItemName, stringifiedValue);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T = any>(storageItemName: LocalStorageKey | string): T | null {
    const storageItemValue = storage.getItem(storageItemName);

    if (storageItemValue == null) {
      return null;
    }

    try {
      return JSON.parse(storageItemValue);
    } catch (error) {
      return null;
    }
  },

  remove(storageItemName: string) {
    storage.removeItem(storageItemName);
  },
};
