import * as React from "react";
import Logo from "../../assets/bluecargo.svg";
import { Button } from "../../components/Button";

const SIGN_UP_LINK = "https://erl.bluecargo.io/signup?source=Email+Plug-in&medium=Outlook";

export interface ErrorType {
  field?: "email" | "password";
  message: string;
}

interface Props {
  onSubmit: (email: string, password: string) => void;
  loading: boolean;
  error?: ErrorType;
}

export const SignIn: React.FC<Props> = ({ onSubmit, loading, error }) => {
  return (
    <div className="flex flex-col items-center py-16 px-6 space-y-8 flex-grow">
      <div className="flex flex-col items-center space-y-8">
        <img src={Logo} alt="BlueCargo" />
        <h1 className="text-2xl text-center leading-10">
          The market leading solution for Detention and Demurrage mitigation
        </h1>
      </div>
      <div className="space-y-8 w-full max-w-md">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // @ts-ignore
            const email: string = e.target[0].value;
            // @ts-ignore
            const password: string = e.target[1].value;
            onSubmit(email, password);
          }}
          className="flex flex-col space-y-8"
        >
          <div className="space-y-4">
            <div className="space-y-1 w-full">
              <label htmlFor="login-email">Email</label>
              <input
                id="login-email"
                type="email"
                placeholder="Email"
                className="border p-2 rounded block w-full"
                disabled={loading}
                required
              />
              {error?.field === "email" && <p className="text-red">{error.message}</p>}
            </div>
            <div className="space-y-1 w-full">
              <label htmlFor="login-password">Password</label>
              <input
                id="login-password"
                type="password"
                placeholder="Password"
                className="border p-2 rounded block w-full"
                disabled={loading}
                required
              />
              {error?.field === "password" && <p className="text-red">{error.message}</p>}
            </div>
          </div>
          <div className="space-y-4">
            <Button type="submit" className="w-full" loading={loading}>
              <h3 className="font-medium">Log in</h3>
            </Button>
            {!error?.field && error?.message && <p className="text-red">{error.message}</p>}
            <a href={SIGN_UP_LINK} target="_blank" rel="noreferrer" className="block w-full underline text-center">
              Sign up for free
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};
SignIn.displayName = "SignIn";
