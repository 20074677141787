import React from "react";
import { Listbox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

export type ListboxOption = {
  key: string;
  label: string;
  value: unknown;
};

interface Props {
  label: React.ReactNode;
  value: string | null;
  rootClassNames?: string | null;
  onChange: (value: string) => void;
  options: ListboxOption[];
  additionalItems?: React.ReactNode;
  fallbackButtonLabel?: (value: string | null) => React.ReactNode;
  classes?: Partial<{
    root: string;
    button: string;
  }>;
}

export const ListboxSelector: React.FC<Props> = (props) => {
  const { label, value, onChange, options, rootClassNames, additionalItems, fallbackButtonLabel, classes } = props;

  let buttonLabel: React.ReactNode = options.find((option) => option.value === value)?.label;
  if (!buttonLabel) {
    buttonLabel = fallbackButtonLabel?.(value);
  }

  return (
    <Listbox
      value={value}
      onChange={onChange}
      as="div"
      className={classNames("relative", rootClassNames, classes?.root)}
    >
      <Listbox.Label className="block font-medium text-left text-text-accent-1">{label}</Listbox.Label>
      <Listbox.Button
        className={classNames(
          "relative w-full rounded-md pl-4 py-2 pr-10 text-left focus:outline-none border shadow-sm border-border-default cursor-pointer bg-surface-primary",
          classes?.button
        )}
      >
        <h4 className="block truncate text-text-accent-1">{buttonLabel}</h4>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Listbox.Button>
      <Listbox.Options className="absolute bottom-8 w-full">
        {() => {
          return (
            <div className="overflow-y-auto overflow-x-hidden rounded-md bg-white max-h-60 w-full py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {options.map((option) => (
                <Listbox.Option
                  key={option.key}
                  value={option.value}
                  className="w-full list-none overflow-auto pl-4 py-2 hover:bg-surface-interactive-hover-accent-3 cursor-pointer"
                >
                  <h4 className="font-medium text-text-accent-1">{option.label}</h4>
                </Listbox.Option>
              ))}
              {additionalItems}
            </div>
          );
        }}
      </Listbox.Options>
    </Listbox>
  );
};
