import React from "react";

/* global window */

// These are outside of the component so that they don't get re-generated on every render,
// which would cause the progress bar to stop and start again
const randomPercentage = Math.floor(Math.random() * 11) + 75; // Generate a random percentage between 75% and 85%
const randomDuration = Math.floor(Math.random() * 26) + 25; // Generate a random duration between 25 and 50 seconds

const ProgressBar: React.FC = () => {
  const [startProgress, setStartProgress] = React.useState(false);
  React.useEffect(() => {
    window.setTimeout(() => setStartProgress(true), 1);
  }, []);
  return (
    <div className={`w-full h-[10px] bg-blue-300 rounded-full ${startProgress ? "opacity-100" : "opacity-0"}`}>
      <div
        className={`h-full bg-surface-interactive-solid-primary rounded-full ${
          startProgress ? "transition-all ease-in-out" : "w-0"
        }`}
        style={{
          width: startProgress ? `${randomPercentage}%` : "0%",
          transitionDuration: startProgress ? `${randomDuration}s` : "0s",
        }}
      />
    </div>
  );
};

export default ProgressBar;
