import * as React from "react";
import { Invoice } from "../../../types/dispute";
import { IndicatedInput, IndicatedMonetaryInput, Indicator } from "../../../components/IndicatedInput";
import { ShippingLineSelector } from "../../../components/Selectors/ShippingLineSelector";
import { PortSelector } from "../../../components/Selectors/PortSelector";
import { InvoiceValidity } from "../types";

interface Props {
  invoice: Invoice;
  onChange: (invoice: Invoice) => void;
  disabled?: boolean;
  validity?: InvoiceValidity;
}

export const InvoiceForm: React.FC<Props> = ({ invoice, onChange, disabled, validity }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <IndicatedInput
        label="Invoice #"
        valid={validity !== undefined ? validity.invoiceNumber.status === "valid" : undefined}
        reason={validity?.invoiceNumber.status !== "valid" ? validity?.invoiceNumber.reason : undefined}
        inputProps={{
          value: invoice.invoiceNumber || "",
          onChange: (e) => onChange({ ...invoice, invoiceNumber: e.target.value }),
          disabled,
        }}
        required
      />
      <IndicatedInput
        label="Invoice Issue Date"
        valid={validity !== undefined ? validity.invoiceIssueDate.status === "valid" : undefined}
        reason={validity?.invoiceIssueDate.status !== "valid" ? validity?.invoiceIssueDate.reason : undefined}
        inputProps={{
          value: invoice.invoiceIssueDate || "",
          onChange: (e) => onChange({ ...invoice, invoiceIssueDate: e.target.value }),
          type: "date",
          disabled,
        }}
        required
      />
      <IndicatedInput
        label="Billing Party"
        valid={validity !== undefined ? validity.invoicerName.status === "valid" : undefined}
        reason={validity?.invoicerName.status !== "valid" ? validity?.invoicerName.reason : undefined}
        inputProps={{
          value: invoice.invoicerName || "",
          onChange: (e) => onChange({ ...invoice, invoicerName: e.target.value }),
          disabled,
        }}
        required
      />
      <PortSelector
        label={
          <span>
            Port of Destination<span className="text-text-informative-critical">*</span>
          </span>
        }
        use="id"
        value={invoice.portOfDeliveryId || ""}
        onChange={(value) => onChange({ ...invoice, portOfDeliveryId: value })}
        disabled={disabled}
        endAdornment={
          validity !== undefined && (
            <Indicator
              valid={validity.portOfDelivery.status === "valid"}
              reason={validity.portOfDelivery.status !== "valid" ? validity.portOfDelivery.reason : undefined}
            />
          )
        }
      />
      <ShippingLineSelector
        label={
          <span>
            SSL<span className="text-text-informative-critical">*</span>
          </span>
        }
        value={invoice.sslId || ""}
        onChange={(key) => onChange({ ...invoice, sslId: key })}
        disabled={disabled}
        endAdornment={
          validity !== undefined && (
            <Indicator
              valid={validity.ssl.status === "valid"}
              reason={validity.ssl.status !== "valid" ? validity.ssl.reason : undefined}
            />
          )
        }
      />
      <IndicatedMonetaryInput
        required
        label="Total Per Diem ($)"
        valid={validity !== undefined ? validity.totalChargesCents.status === "valid" : undefined}
        reason={validity?.totalChargesCents.status !== "valid" ? validity?.totalChargesCents.reason : undefined}
        value={(invoice.totalChargesCents || 0) / 100}
        inputProps={{ disabled }}
        onChange={(value) => onChange({ ...invoice, totalChargesCents: value * 100 })}
      />
    </div>
  );
};
InvoiceForm.displayName = "InvoiceForm";
