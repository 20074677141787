import * as React from "react";
import { RateSection } from "./Rates";
import { DateSection } from "../Dates";
import { ContainerViolation, ViolationType } from "../../../../types/dispute";
import { FeatureFlag, useFeatureFlag } from "../../../../lib/permissions/feature-flags";

interface Props {
  violations: ContainerViolation[];
}

export const Details: React.FC<Props> = ({ violations }) => {
  const osraFeature = useFeatureFlag(FeatureFlag.OSRAValidation) || {};

  if (violations.length === 0) {
    return (
      <div className="bg-white p-4">
        <h4 className="font-bold text-text-subdued">No violation data</h4>
      </div>
    );
  }

  const ratesData: ContainerViolation | undefined = violations.filter((v) =>
    [
      ...(osraFeature.customRates ? [ViolationType.ContractRatesMismatch] : []),
      ...(osraFeature.standardRates ? [ViolationType.StandardRatesMismatch] : []),
      ...(osraFeature.customRates || osraFeature.standardRates ? [ViolationType.RatesMissing] : []),
    ].includes(v.type)
  )[0];

  const gatedInData: ContainerViolation | undefined = violations.filter((v) =>
    [ViolationType.GatedInDateMismatch, ViolationType.GatedInDateMissing].includes(v.type)
  )[0];

  const gatedOutData: ContainerViolation | undefined = violations.filter((v) =>
    [ViolationType.GatedOutDateMismatch, ViolationType.GatedOutDateMissing].includes(v.type)
  )[0];

  return (
    <div className="divide-y bg-surface-secondary space-y-2">
      {ratesData && <RateSection status={ratesData.status} details={ratesData.details} type={ratesData.type} />}
      {gatedOutData && osraFeature.dates && (
        <DateSection status={gatedOutData.status} details={gatedOutData.details} type={gatedOutData.type} />
      )}
      {gatedInData && osraFeature.dates && (
        <DateSection status={gatedInData.status} details={gatedInData.details} type={gatedInData.type} />
      )}
    </div>
  );
};
Details.displayName = "OSRA.Details";
