import * as React from "react";
import { Invoice } from "../../../types/dispute";
import { CollapsableSection } from "../CollapsableSection";
import { InvoiceForm } from "./InvoiceForm";
import { ContainerSection } from "./Container";
import { InvoiceValidity } from "../types";

interface Props {
  invoice: Invoice;
  onChange: (invoice: Invoice) => void;
  validity: InvoiceValidity;
}

/**
 * The root level object in the verification page that displays
 * invoice details and all of its children (invoice containers,
 * their rates, etc)
 */
export const InvoiceSection: React.FC<Props> = ({ invoice, onChange, validity }) => {
  return (
    <CollapsableSection title={<h4 className="font-bold m-0">Invoice details</h4>} className="space-y-4">
      <InvoiceForm invoice={invoice} onChange={onChange} validity={validity} />
      <ContainerSection invoice={invoice} onChange={onChange} validity={validity.invoiceContainers} />
    </CollapsableSection>
  );
};
InvoiceSection.displayName = "InvoiceSection";
