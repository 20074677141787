import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "./routes";
import { AnalyticsEvent, useAnalytics } from "./segment";

/* global Office */

/** The extensions to accept for attachments */
const ACCEPTED_EXTENSIONS = ["pdf", "csv"];
const isAcceptableAttachment = (attachment: Office.AttachmentDetails): boolean => {
  return ACCEPTED_EXTENSIONS.includes(attachment.name.split(".").pop()?.toLowerCase() ?? "");
};

/** Helper for getting and filtering the file attachments of an email */
const getAttachments = () => {
  return Office?.context?.mailbox?.item?.attachments.filter(
    (a) => a.attachmentType === Office.MailboxEnums.AttachmentType.File && isAcceptableAttachment(a)
  );
};

interface State {
  attachments?: Office.AttachmentDetails[];
  emailId?: string;
}

/**
 * THIS SHOULD BE TREATED AS A SINGLETON
 * This is because the event handlers are global and will race and
 * cause issues if the add and remove are called multiple times
 */
const useEmailData = () => {
  const [state, setState] = React.useState<State>({
    attachments: getAttachments(),
    emailId: Office.context.mailbox?.item?.internetMessageId,
  });

  // Watch for changes to the currently read message
  React.useEffect(() => {
    const handleItemChange = () => {
      const emailId = Office.context.mailbox?.item?.internetMessageId;
      setState({ emailId, attachments: getAttachments() });
    };
    Office.context.mailbox?.addHandlerAsync?.(Office.EventType.ItemChanged, handleItemChange);
    return Office.context.mailbox?.removeHandlerAsync?.(Office.EventType.ItemChanged, handleItemChange);
  }, []);

  return state;
};

const EmailContext = React.createContext<State>({ emailId: undefined, attachments: [] });

export const EmailProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = useEmailData();
  const navigate = useNavigate();
  const track = useAnalytics();

  React.useEffect(() => {
    if (value.emailId) {
      track(AnalyticsEvent.EmailViewedWithPlugIn, { emailId: value.emailId });
    }
    navigate(Routes.Read);
  }, [value.emailId]);

  return <EmailContext.Provider value={value}>{children}</EmailContext.Provider>;
};

export const useEmailContext = () => React.useContext(EmailContext);
