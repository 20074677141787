import { DayAccrual } from "../../../../../types/dispute";
import { RadioButton } from "./RadioButton";
import * as React from "react";

interface Props {
  checked?: DayAccrual;
  onChange: (checked: DayAccrual) => void;
}

export const TimeMode: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <fieldset className="space-y-4">
      <RadioButton
        label="Working days (excl. weekend & holiday)"
        checked={checked === DayAccrual.WorkingDays}
        onCheck={() => onChange(DayAccrual.WorkingDays)}
        classes={{ label: "!text-text-interactive-solid-subdued" }}
      />
      <RadioButton
        label="Calendar days"
        checked={checked === DayAccrual.CalendarDays}
        onCheck={() => onChange(DayAccrual.CalendarDays)}
        classes={{ label: "!text-text-interactive-solid-subdued" }}
      />
    </fieldset>
  );
};
TimeMode.displayName = "TimeMode";
