import { useQuery } from "@tanstack/react-query";
import {
  DisputeEmailTemplate,
  DisputesQueryKey,
  fetchInvoiceUploadJob,
  getDisputeOpportunityForContainerIds,
} from "../../services/disputes";
import { InvoiceContainer, InvoiceUploadJob, TaggedInvoiceContainer } from "../../types/dispute";
import { client } from "../../services/client";
import { calculateInvoiceContainerDateRange, objectKeysToCamelCase, objectKeysToSnakeCase } from "../../lib/common";
import React from "react";
import { DateTime } from "luxon";

export const useUploadJob = (invoiceJob: string) => {
  const query = useQuery({
    queryKey: [DisputesQueryKey.InvoiceJob, invoiceJob],
    queryFn: ({ signal }) => fetchInvoiceUploadJob(invoiceJob, signal),
    enabled: !!invoiceJob,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return { invoiceJob, invoiceUploadJob: query.data, query };
};

export const useDisputeOpportunity = ({
  invoiceUploadJob,
  invoiceContainers,
}: {
  invoiceUploadJob?: InvoiceUploadJob;
  invoiceContainers?: TaggedInvoiceContainer[];
}) => {
  const { startDate, endDate } = React.useMemo(() => {
    let finalInvoiceContainers: TaggedInvoiceContainer[] = [];
    if (invoiceUploadJob) {
      finalInvoiceContainers = invoiceUploadJob.parsedInvoices.flatMap(
        (i) => i.invoiceContainers
      ) as unknown as TaggedInvoiceContainer[];
    } else if (invoiceContainers) {
      finalInvoiceContainers = invoiceContainers;
    }
    const startDates = finalInvoiceContainers
      ?.map((ic) => calculateInvoiceContainerDateRange(ic))
      .map((d) => d.startDate)
      .filter((d) => d) as DateTime[];
    const endDates = finalInvoiceContainers
      ?.map((ic) => calculateInvoiceContainerDateRange(ic))
      .map((d) => d.endDate)
      .filter((d) => d) as DateTime[];
    return {
      startDate: DateTime.min(...(startDates as DateTime[])),
      endDate: DateTime.max(...(endDates as DateTime[])),
    };
  }, [invoiceUploadJob, invoiceContainers]);
  const noDataResult = {
    averageDaysInPerDiem: 0,
    averagePerDiemPerDay: 0,
    totalAccrued: 0,
    totalNumberOfContainers: 0,
    totalSavings: 0,
    disputeOpportunity: 0,
  };
  let containerInvoiceIds: string[] = [];
  if (invoiceUploadJob) {
    containerInvoiceIds = invoiceUploadJob.parsedInvoices.flatMap((invoice) =>
      invoice.invoiceContainers.map((ic) => ic.id)
    ) as string[];
  } else if (invoiceContainers) {
    containerInvoiceIds = invoiceContainers.map((ic) => ic.id) as string[];
  }
  const disputeOpportunityQuery = useQuery({
    queryKey: [DisputesQueryKey.DisputeOpportunity, containerInvoiceIds],
    queryFn: () => getDisputeOpportunityForContainerIds(containerInvoiceIds),
  });

  return {
    metrics: disputeOpportunityQuery.data || noDataResult,
    startDate: startDate as DateTime | undefined,
    endDate: endDate as DateTime | undefined,
  };
};

export const generateEmail = async (
  id: InvoiceContainer["id"],
  emailTemplate: DisputeEmailTemplate,
  externalId?: string,
  signal?: AbortSignal
): Promise<{
  subject: string;
  recipients: string[];
  htmlBody: string;
}> => {
  const url = `/core/api/v1/disputes/email/generate/invoice-container/${id}`;
  const response = await client.post(
    url,
    objectKeysToSnakeCase({
      emailTemplate,
      includeEras: true,
      externalId,
    }),
    { signal }
  );
  return objectKeysToCamelCase(response.data);
};
