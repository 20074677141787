import {
  ContainerViolation,
  Dispute,
  DisputeStatus,
  DisputeType,
  Invoice,
  InvoiceContainer,
  InvoiceContainerRate,
  InvoiceUploadJob,
  TaggedInvoiceContainer,
} from "../types/dispute";
import { DateTime } from "luxon";
import { objectKeysToCamelCase, objectKeysToSnakeCase } from "../lib/common";
import { client } from "./client";

export enum DisputesQueryKey {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ActivityFeed = "activityFeed",
  UploadInvoice = "uploadInvoiceDispute",
  InvoiceJob = "invoiceJob",
  InvoiceJobs = "invoiceJobs",
  "InvoiceContainer" = "invoiceContainer",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Dispute = "dispute",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Invoice = "invoice",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  TaggedInvoiceContainer = "taggedInvoiceContainer",
  InvoiceContainerEmailPreview = "invoiceContainerEmailPreview",
  InvoiceUploadJobsByHashes = "invoiceUploadJobsByHashes",
  DisputeTrackerKeyMetrics = "disputeTrackerKeyMetrics",
  DisputeTrackerFilterValues = "disputeTrackerFilterValues",
  DisputeOpportunity = "disputeOpportunity",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  DisputeDocument = "disputeDocument",
  DisputeEmailTemplates = "disputeEmailTemplates",
  LinkedPerDiemScreenshots = "linkedPerDiemScreenshots",
  ContainerViolations = "containerViolations",
  ContainerTemplates = "containerTemplates",
}

type EmailTemplateTuple = [DisputeEmailTemplate, string];

export const fetchInvoiceUploadJobs = async (
  filters: { source: "outlook_plugin"; emailId: string; attachmentId?: string },
  signal?: AbortSignal
) => {
  const url = "/core/api/v1/disputes/invoice-upload-parse-jobs/";
  const response = await client.get(url, {
    params: objectKeysToSnakeCase({
      source: filters.source,
      externalId: filters.emailId,
      secondaryExternalId: filters.attachmentId,
    }),
    signal,
  });
  return objectKeysToCamelCase<InvoiceUploadJob[]>(response.data);
};

export const fetchInvoiceUploadJob = async (id: string, signal?: AbortSignal) => {
  const url = `/core/api/v1/disputes/invoice-upload-parse-jobs/${id}/`;
  const response = await client.get(url, { signal });
  const data = objectKeysToCamelCase(response.data) as InvoiceUploadJob;

  return {
    ...data,
    parsedInvoices: data.parsedInvoices.map((invoice) => ({
      ...invoice,
      invoiceContainers: invoice.invoiceContainers.map((invoiceContainer) => ({
        ...invoiceContainer,
        // We want the rates to be sorted by start date ascending upon completing the intial fetch
        rates: invoiceContainer.rates?.sort((a, b) => {
          if (!a) {
            return 1;
          }
          if (!b) {
            return -1;
          }
          return DateTime.fromISO(a.startDate) < DateTime.fromISO(b.startDate) ? -1 : 1;
        }),
      })),
    })),
  } as InvoiceUploadJob;
};

const createInvoice = async (payload: Partial<Invoice>, signal?: AbortSignal): Promise<Invoice> => {
  const url = "/core/api/v1/disputes/invoices/";
  const response = await client.post(url, objectKeysToSnakeCase(payload), { signal });
  return objectKeysToCamelCase(response.data) as Invoice;
};

const deleteInvoice = async (id: Invoice["id"], signal?: AbortSignal): Promise<void> => {
  const url = `/core/api/v1/disputes/invoices/${id}/`;
  await client.delete(url, { signal });
};

const updateInvoice = async (
  payload: { id: Invoice["id"]; data: Partial<Invoice> },
  signal?: AbortSignal
): Promise<Invoice> => {
  const url = `/core/api/v1/disputes/invoices/${payload.id}/`;
  const response = await client.patch(url, objectKeysToSnakeCase(payload.data), { signal });
  return objectKeysToCamelCase(response.data) as Invoice;
};

// BEGIN INVOICE CONTAINER

const createInvoiceContainer = async (
  invoiceContainer: Omit<InvoiceContainer, "id">,
  signal?: AbortSignal
): Promise<InvoiceContainer> => {
  const url = "/core/api/v1/disputes/invoice-containers/";
  const response = await client.post(url, objectKeysToSnakeCase(invoiceContainer), {
    signal,
  });
  return objectKeysToCamelCase(response.data) as InvoiceContainer;
};

const updateInvoiceContainer = async (
  invoiceContainer: InvoiceContainer,
  signal?: AbortSignal
): Promise<InvoiceContainer> => {
  const url = `/core/api/v1/disputes/invoice-containers/${invoiceContainer.id}/`;
  const response = await client.put(url, objectKeysToSnakeCase(invoiceContainer), {
    signal,
  });
  return objectKeysToCamelCase(response.data) as InvoiceContainer;
};

const deleteInvoiceContainer = async (id: InvoiceContainer["id"], signal?: AbortSignal): Promise<void> => {
  const url = `/core/api/v1/disputes/invoice-containers/${id}/`;
  await client.delete(url, { signal });
};

export const fetchInvoiceContainer = async (
  id: InvoiceContainer["id"],
  signal?: AbortSignal
): Promise<TaggedInvoiceContainer> => {
  const url = `/core/api/v1/disputes/invoice-containers/${id}/`;
  const response = await client.get(url, { signal });
  return objectKeysToCamelCase(response.data) as TaggedInvoiceContainer;
};

// BEGIN INVOICE CONTAINER RATE

/** Sets the rates for a given invoice container ID and returns the new invoice containers */
const setInvoiceContainerRates = async (
  id: InvoiceContainer["id"],
  rates: InvoiceContainerRate[],
  signal?: AbortSignal
) => {
  const url = `/core/api/v1/disputes/invoice-containers/${id}/set-rates/`;
  const response = await client.post(
    url,
    rates.map((r) => objectKeysToSnakeCase({ ...r, invoiceContainer: id })),
    { signal }
  );
  return objectKeysToSnakeCase(response.data) as InvoiceContainer;
};

// BEGIN INVOICE MAPPINGS

const saveInvoiceMappings = async (
  data: { original?: Invoice; modified: Invoice },
  signal?: AbortSignal
): Promise<{ invoice: Invoice; invoiceContainers: InvoiceContainer[] }> => {
  const { original, modified } = data;

  // Create or update the invoice
  let invoice: Invoice;
  if (!modified.id) {
    // First create if there is no ID; we must await since the invoice containers need it to exist
    invoice = await createInvoice({ ...modified, id: undefined }, signal);
  } else {
    // Otherwise if there is an ID we are just updating an existing invoice
    invoice = await updateInvoice({ id: modified.id, data: modified }, signal);
  }

  // Remove any invoice containers that no longer exist
  const deletePromises: Promise<void>[] = [];
  if (original) {
    original.invoiceContainers.forEach((invoiceContainer) => {
      const hasBeenRemoved = !modified.invoiceContainers.find((c) => c.id === invoiceContainer.id);
      if (hasBeenRemoved) {
        deletePromises.push(deleteInvoiceContainer(invoiceContainer.id, signal));
      }
    });
  }

  // Create or update any invoice containers that exist
  const updatedInvoiceContainers: InvoiceContainer[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const ic of modified.invoiceContainers) {
    let icId = ic.id;
    if (icId) {
      // If there is an ID, we are updating an existing invoice container
      // eslint-disable-next-line no-await-in-loop
      await updateInvoiceContainer(ic, signal);
    } else {
      // Otherwise we are creating a new invoice container
      // eslint-disable-next-line no-await-in-loop
      const created = await createInvoiceContainer(
        {
          ...ic,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          invoiceId: invoice.id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          invoice: undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rates: undefined,
          id: undefined,
        },
        signal
      );
      icId = created.id;
    }

    // Create and update invoice container rates for this invoice container
    // eslint-disable-next-line no-await-in-loop
    const newIc = await setInvoiceContainerRates(icId, ic.rates || [], signal);
    updatedInvoiceContainers.push(newIc);
  }

  // Allow all the deletions to finish
  await Promise.all(deletePromises);

  return { invoice, invoiceContainers: updatedInvoiceContainers };
};

export const saveAllInvoiceMappings = async (
  // original should only be undefined for new invoices
  invoicePairs: ({ original?: Invoice; modified: Invoice } | { original: Invoice; modified?: Invoice })[],
  signal?: AbortSignal
): Promise<{ invoice: Invoice; invoiceContainers: InvoiceContainer[] }[]> => {
  const invoiceContainerPromises: Promise<{
    invoice: Invoice;
    invoiceContainers: InvoiceContainer[];
  }>[] = [];
  const otherPromises: Promise<void>[] = [];

  // For each invoice pair (original and modified), we need to either save or delete the invoice
  invoicePairs.forEach((invoicePair) => {
    if (invoicePair.modified) {
      // If there is a modified, we are either creating or updating an invoice and all its children
      invoiceContainerPromises.push(
        saveInvoiceMappings(invoicePair as { original?: Invoice; modified: Invoice }, signal)
      );
    } else if (invoicePair.original) {
      // If there is ONLY an original, then that means we are deleting the invoice and its children
      otherPromises.push(deleteInvoice(invoicePair.original.id, signal));
    }
  });
  await Promise.all(otherPromises);
  return (await Promise.all(invoiceContainerPromises)).flat();
};

// END

export interface InvoiceDisputeOpportunity {
  savings: number;
  totalCharges: number;
  avgDays: number;
  numContainers: number;
  disputablePercentage: number;
  avgDailyCharges: number;
}

export interface FormattedInvoiceDisputeOpportunity {
  totalAccrued: number;
  totalSavings: number;
  disputeOpportunity: number;
  averagePerDiemPerDay: number;
  averageDaysInPerDiem: number;
  totalNumberOfContainers: number;
}

export const getDisputeOpportunityForContainerIds = async (
  invoiceContainerIds: InvoiceContainer["id"][],
  signal?: AbortSignal
): Promise<FormattedInvoiceDisputeOpportunity> => {
  const url = `/core/api/v1/disputes/invoice-containers/dispute-opportunity/`;
  const response = await client.post(url, { invoice_container_ids: invoiceContainerIds }, { signal });
  const data = objectKeysToCamelCase(response.data) as InvoiceDisputeOpportunity;
  return {
    totalAccrued: data.totalCharges,
    totalSavings: data.savings,
    disputeOpportunity: data.disputablePercentage,
    averagePerDiemPerDay: data.avgDailyCharges,
    averageDaysInPerDiem: data.avgDays,
    totalNumberOfContainers: data.numContainers,
  };
};

export const updateDispute = async (payload: Partial<Dispute>, signal?: AbortSignal) => {
  const url = `/core/api/v1/disputes/disputes/${payload.id}/`;
  const response = await client.patch(url, objectKeysToSnakeCase(payload), { signal });
  return objectKeysToCamelCase(response.data) as Dispute;
};

interface UpdateDisputeStatusPayload {
  amount?: { disputeId: Dispute["id"]; amount: number };
  disputeStatus: Partial<DisputeStatus> & { id: DisputeStatus["id"] };
}

export const updateDisputeStatus = async (
  payload: UpdateDisputeStatusPayload,
  signal?: AbortSignal
): Promise<DisputeStatus> => {
  const url = `/core/api/v1/disputes/dispute-status/${payload.disputeStatus.id}/`;
  const response = await client.patch(url, objectKeysToSnakeCase(payload.disputeStatus), {
    signal,
  });

  // TODO: This prevents the paid amount going from ResolvedWon to ResolvedPending or RequiresReply
  if (payload.amount !== undefined) {
    await updateDispute({ id: payload.amount.disputeId, paidAmountCents: payload.amount.amount }, signal);
  }

  return objectKeysToCamelCase(response.data) as DisputeStatus;
};

export enum DisputeEmailTemplate {
  NoReturnCalifornia = "no_return_cali",
  NoReturnUs = "no_return_us",
  FirstRebuttalCalifornia = "first_rebuttal_cali",
  FirstRebuttalUs = "first_rebuttal_us",
  CustomProof = "custom_proof",
  Past30Days = "past_30_days",
  OSRANonCompliance = "osra_violation",
  UIIANonCompliance = "uiia_violation",
}

export const fetchEmailsForContainer = async (invoiceContainerId: InvoiceContainer["id"], signal?: AbortSignal) => {
  const url = `/core/api/v1/disputes/invoice-containers/${invoiceContainerId}/activity-feed/`;
  const response = await client.get(url, {
    params: { type: "email" },
    signal,
  });
  return objectKeysToCamelCase(response.data);
};

interface ContainerTemplatesIntermediate {
  suggestedTemplate: EmailTemplateTuple | null;
  sentTemplates: EmailTemplateTuple[];
  availableTemplates: EmailTemplateTuple[];
}

export const fetchContainerTemplates: (containerId: string, signal?: AbortSignal) => Promise<DisputeType[]> = async (
  containerId,
  signal
) => {
  const url = `/core/api/v1/disputes/invoice-containers/${containerId}/templates/`;
  const response = await client.get(url, { signal });
  const intermediate = objectKeysToCamelCase(response.data) as ContainerTemplatesIntermediate;

  // Restructure the data to how we like it
  return intermediate.availableTemplates
    .map((template) => ({
      name: template[1],
      template: template[0],
      sent: !!intermediate.sentTemplates.find((t) => t[0] === template[0]),
    }))
    .sort((a) => {
      if (a.template === intermediate.suggestedTemplate?.[0]) {
        return -1;
      }
      return 0;
    });
};

export const fetchContainerViolations: (
  containerId: string,
  signal?: AbortSignal
) => Promise<ContainerViolation[]> = async (containerId, signal) => {
  const url = `/core/api/v1/disputes/invoice-containers/${containerId}/violations/`;
  const response = await client.get(url, { signal });
  return objectKeysToCamelCase(response.data) as ContainerViolation[];
};
