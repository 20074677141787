import * as React from "react";
import { TimeMode } from "./TimeMode";
import { IndicatedInput } from "../../../../../components/IndicatedInput";
import { DayAccrual, InvoiceContainerRate } from "../../../../../types/dispute";
import { RateValidity } from "../../../types";
import { getReason, isValid } from "../../../utility";

interface Props {
  rates: InvoiceContainerRate[];
  onChange: (rates: InvoiceContainerRate[]) => void;
  validity?: RateValidity[];
  timeMode?: DayAccrual;
  onTimeModeChange: (timeMode: DayAccrual) => void;
}

export const FreeRate: React.FC<Props> = ({ rates, onChange, validity, timeMode, onTimeModeChange }) => {
  return (
    <div className="space-y-4">
      <h5 className="m-0 text-text-subdued uppercase">Container Free Time</h5>
      <TimeMode checked={timeMode} onChange={onTimeModeChange} />
      {rates.map((rate, i) => {
        const v = validity?.[i];
        const onRateChange = (rate: InvoiceContainerRate) => {
          const newRates = [...rates];
          newRates[i] = rate;
          onChange(newRates);
        };

        return (
          <div className="grid grid-cols-2 gap-4 bg-surface-secondary p-2" key={`${rate.id}_${i}`}>
            <IndicatedInput
              label="From date"
              inputProps={{
                value: rate.startDate || "",
                type: "date",
                onChange: (e) => onRateChange({ ...rate, startDate: e.target.value }),
              }}
              valid={isValid(v, "startDate")}
              reason={getReason(v, "startDate")}
              required
            />
            <IndicatedInput
              label="To date"
              inputProps={{
                value: rate.endDate || "",
                type: "date",
                onChange: (e) => onRateChange({ ...rate, endDate: e.target.value }),
              }}
              valid={isValid(v, "endDate")}
              reason={getReason(v, "endDate")}
              required
            />
          </div>
        );
      })}
    </div>
  );
};
FreeRate.displayName = "FreeRate";
