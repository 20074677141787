import { Combobox } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import _ from "lodash";
import { ComboboxSelector } from "./Selector";
import { Port } from "../../types/port";
import { PortQueryKeys, fetchPorts } from "../../services/ports";

interface Props {
  value: string;
  use?: keyof Port;
  onChange: (code: string, id: string) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  label?: React.ReactNode;
  disabled?: boolean;
}

export const PortSelector: React.FC<Props> = (props) => {
  const { value, use, onChange, label, disabled } = props;
  const [filter, setFilter] = React.useState("");

  const useField = use || "portCode";

  const ports = useQuery({
    queryKey: [PortQueryKeys.Ports],
    queryFn: ({ signal }) => fetchPorts({ signal }),
    enabled: !disabled,
  });

  const filteredItems = React.useMemo(() => {
    if (!ports.data) {
      return [];
    }

    const data = _.orderBy([...ports.data], "name").filter((size) => {
      if (!filter) {
        return true;
      }

      return (
        size.name.toLowerCase().includes(filter.toLowerCase()) ||
        size.portCode.toLowerCase().includes(filter.toLowerCase())
      );
    });

    return data.map((port) => {
      return (
        <Combobox.Option
          key={port.id}
          value={port[useField]}
          className="rounded cursor-pointer hover:bg-surface-interactive-accent-3 py-1 px-2"
        >
          {port.name}
        </Combobox.Option>
      );
    });
  }, [ports, useField, filter]);

  return (
    <ComboboxSelector
      label={label || "Port"}
      value={value}
      onChange={(code: string) => {
        onChange(code, ports.data?.find((sl) => sl.portCode === code)?.id || "");
      }}
      disabled={ports.isLoading || disabled}
      onFilter={(f) => setFilter(f)}
      placeholder="Port"
      startAdornment={props.startAdornment}
      endAdornment={props.endAdornment}
      displayValue={(item: string) => {
        return ports.data?.find((sl) => sl[useField] === item)?.name || item;
      }}
    >
      {filteredItems}
    </ComboboxSelector>
  );
};
PortSelector.displayName = "TailwindUI.PortSelector";
