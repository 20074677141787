import { Disclosure } from "@headlessui/react";
import * as React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ComplianceStatus, ContainerViolation, ViolationType } from "../../../../types/dispute";
import { ComplianceBadge } from "../ComplianceBadge";
import { DateSection } from "../Dates";
import { Stat } from "../Stat";
import { ComingSoonBadge } from "../ComingSoonBadge";
import { Summary } from "./Summary";
import { FeatureFlag, useFeatureFlag } from "../../../../lib/permissions/feature-flags";
import { InformationalTooltip } from "../../../../components/InformationalTooltip";

interface Props {
  violations: ContainerViolation[];
}

export const UIIA: React.FC<Props> = ({ violations }) => {
  const invoiceIssueData: ContainerViolation | undefined = violations.filter((v) =>
    [ViolationType.InvoiceIssueDateMissing, ViolationType.InvoiceIssueDateTooLate].includes(v.type)
  )[0];

  const status = invoiceIssueData?.status;

  const uiiaEnabled = useFeatureFlag(FeatureFlag.UIIAValidation);
  if (!uiiaEnabled) {
    return (
      <Stat
        title={
          <div className="flex gap-1.5 items-center">
            <h3 className="font-medium">UIIA Compliance</h3>
            <InformationalTooltip>
              We validate if your invoice meets the Universal Intermodal Interchange and Facilities Access Agreement
              (UIIA) invoice requirements.
            </InformationalTooltip>
          </div>
        }
        value={<ComingSoonBadge />}
      />
    );
  }

  return (
    <Disclosure>
      <Disclosure.Button as="div" className="bg-white py-4 flex items-center justify-between gap-2 cursor-pointer">
        <div className="space-y-2">
          <div className="flex gap-1.5 items-center">
            <h3 className="font-medium">UIIA Compliance</h3>
            <InformationalTooltip>
              We validate if your invoice meets the Universal Intermodal Interchange and Facilities Access Agreement
              (UIIA) invoice requirements.
            </InformationalTooltip>
            <ComplianceBadge status={status || ComplianceStatus.Skipped} />
          </div>
          {invoiceIssueData && <Summary status={status || ComplianceStatus.Skipped} violation={invoiceIssueData} />}
        </div>
        <div className="flex gap-2 items-center">
          <ChevronDownIcon className="h-4 text-icon-interactive-subdued flex-shrink-0 ui-open:rotate-180 ui-open:transform transition" />
        </div>
      </Disclosure.Button>
      <Disclosure.Panel>
        {invoiceIssueData ? (
          <DateSection
            status={invoiceIssueData.status}
            details={invoiceIssueData.details}
            type={invoiceIssueData.type}
          />
        ) : (
          <div className="bg-white p-4">
            <h4 className="font-bold text-text-subdued">No violation data</h4>
          </div>
        )}
      </Disclosure.Panel>
    </Disclosure>
  );
};
UIIA.displayName = "ContainerDispute.Edit.Summary.UIIA";
