import * as React from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useUserContext } from "../../layout/AuthProvider/context";
import { transformUserToEventProperties, transformUserToSegment } from "./transforms";
import { AnalyticsOptions, EventOptions, EventProperties, AnalyticsEvent } from "./types";
import { User } from "../../types";

// eslint-disable-next-line
const SEGMENT_WRITE_KEY = process.env.SEGMENT_KEY || "";

const analytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_WRITE_KEY,
});

/** Hook that automatically identifies the user in segment whenever the user object changes */
export const useIdentifyAnalytics = (user: User | null) => {
  const segmentIdentification = React.useMemo(() => transformUserToSegment(user), [user]);

  React.useEffect(() => {
    if (!user) return;
    const [identify, group] = segmentIdentification;

    analytics.identify(user.id.toString(), identify);
    analytics.group(user.company?.scac || "", group);
  }, [user]);
};

/** Event specific options overrides */
const eventOptionOverrides: Partial<Record<AnalyticsEvent, EventOptions>> = {};

/** The default options to apply to every event; overridden by event option overrides and track options overrides */
const defaultEventOptions: EventOptions = {
  includeUser: true,
  includeCompany: true,
  slack: false,
};

export const useAnalytics = () => {
  const { user } = useUserContext();

  const track = React.useCallback(
    <T extends AnalyticsEvent>(event: T, properties: EventProperties[T], options?: AnalyticsOptions) => {
      // Get the event options based on the default, event specific overrides, and function call specific overrides
      const eventOptions: Partial<EventOptions> = {
        ...defaultEventOptions,
        ...eventOptionOverrides[event],
        ...options?.event,
      };

      // Create the final list of options for the segment call
      const segmentOptions = {
        ...options?.segment,
        ...(eventOptions.slack ? { integrations: { Slack: true } } : {}),
      };

      // Attach user / company properties if applicable
      const additionalProperties = transformUserToEventProperties(user);
      const userProperties = eventOptions.includeUser ? additionalProperties.user : {};
      const companyProperties = eventOptions.includeCompany ? additionalProperties.company : {};

      analytics.track(event, { ...properties, ...userProperties, ...companyProperties }, segmentOptions);
    },
    []
  );

  return track;
};
