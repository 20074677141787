import * as React from "react";
import { InvoiceContainer } from "../../../../types/dispute";
import { IndicatedInput, IndicatedMonetaryInput, Indicator } from "../../../../components/IndicatedInput";
import { ContainerValidity } from "../../types";
import { ContainerSizeSelector } from "../../../../components/Selectors/ContainerSizeSelector";
import { getReason, isValid } from "../../utility";

interface Props {
  invoiceContainer: InvoiceContainer;
  onChange: (invoiceContainer: InvoiceContainer) => void;
  disabled?: boolean;
  validity?: ContainerValidity;
}

export const ContainerForm: React.FC<Props> = ({ invoiceContainer, onChange, disabled, validity }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <IndicatedInput
        label="Container #"
        valid={isValid(validity, "containerNumber")}
        reason={getReason(validity, "containerNumber")}
        inputProps={{
          value: invoiceContainer.containerNumber || "",
          onChange: (e) => onChange({ ...invoiceContainer, containerNumber: e.target.value }),
          placeholder: "############",
          autoFocus: validity !== undefined ? Object.values(validity).every((v) => !v) : false,
          disabled,
        }}
        required
      />
      <ContainerSizeSelector
        value={invoiceContainer.containerType || ""}
        label={
          <span>
            Container Size<span className="text-text-informative-critical">*</span>
          </span>
        }
        onChange={(value) => onChange({ ...invoiceContainer, containerType: value })}
        endAdornment={
          <Indicator valid={isValid(validity, "containerType")} reason={getReason(validity, "containerType")} />
        }
        disabled={disabled}
      />
      <IndicatedInput
        label="BOL #"
        valid={isValid(validity, "bol")}
        reason={getReason(validity, "bol")}
        inputProps={{
          value: invoiceContainer.bol || "",
          onChange: (e) => onChange({ ...invoiceContainer, bol: e.target.value }),
          disabled,
        }}
        required
      />
      <IndicatedMonetaryInput
        label="Container Per Diem ($)"
        valid={isValid(validity, "totalChargesCents")}
        reason={getReason(validity, "totalChargesCents")}
        value={(invoiceContainer.totalChargesCents || 0) / 100}
        onChange={(value) => onChange({ ...invoiceContainer, totalChargesCents: value * 100 })}
        inputProps={{ disabled }}
      />
    </div>
  );
};
ContainerForm.displayName = "Verification.ContainerForm";
