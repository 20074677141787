import * as React from "react";

/** Renders an outline of a document with an exclamation triangle */
export const DocumentWarning: React.FC = () => {
  return (
    <svg width="210" height="272" viewBox="0 0 210 272" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 16.6875C2 8.50678 8.63178 1.875 16.8125 1.875H53.125H79.0625C104.433 1.875 125 22.4419 125 47.8125V68.5625C125 77.5716 132.303 84.875 141.312 84.875H162.062C187.433 84.875 208 105.442 208 130.812V167.125V255.312C208 263.493 201.368 270.125 193.188 270.125H16.8125C8.63178 270.125 2 263.493 2 255.312V16.6875ZM16.8125 0.375H53.125H79.0625H84.25C153.424 0.375 209.5 56.4513 209.5 125.625V130.812V167.125V255.312C209.5 264.322 202.197 271.625 193.188 271.625H16.8125C7.80335 271.625 0.5 264.322 0.5 255.312V16.6875C0.5 7.67835 7.80336 0.375 16.8125 0.375ZM126.5 47.8125C126.5 26.0209 111.806 7.66123 91.7861 2.1007C154.129 5.84573 204.029 55.7458 207.774 118.089C202.214 98.0687 183.854 83.375 162.062 83.375H141.312C133.132 83.375 126.5 76.7432 126.5 68.5625V47.8125ZM115.555 109.799C110.865 101.67 99.1322 101.67 94.4421 109.799L52.1547 183.098C47.4672 191.223 53.3311 201.375 62.7113 201.375H147.286C156.666 201.375 162.53 191.223 157.843 183.098L115.555 109.799ZM93.1428 109.05C98.4101 99.9198 111.587 99.9198 116.855 109.05L159.142 182.348C164.406 191.473 157.821 202.875 147.286 202.875H62.7113C52.1766 202.875 45.591 191.473 50.8554 182.348L93.1428 109.05ZM104.999 141C105.413 141 105.749 141.336 105.749 141.75V163.313C105.749 163.727 105.413 164.063 104.999 164.063C104.584 164.063 104.249 163.727 104.249 163.313V141.75C104.249 141.336 104.584 141 104.999 141ZM107 181C107 182.105 106.105 183 105 183C103.895 183 103 182.105 103 181C103 179.895 103.895 179 105 179C106.105 179 107 179.895 107 181Z"
        fill="#EB7D17"
      />
    </svg>
  );
};
DocumentWarning.displayName = "DocumentWarning";
