import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { DocumentWarning } from "../../assets/DocumentWarning";

export const NoAttachment: React.FC = () => {
  return (
    <div className="flex flex-col items-center p-8 gap-8">
      <div className="flex items-center justify-center">
        <DocumentWarning />
      </div>
      <div className="flex flex-col items-center gap-1">
        <h3 className="text-center font-medium text-lg text-text-interactive-disabled-accent-1">
          No attachment recognized in this email
        </h3>
        <div className="flex items-center gap-1 uppercase text-center text-text-informative-warning">
          <ExclamationTriangleIcon className="h-4 flex-shrink-0" />
          <h4>Select email with an invoice attached</h4>
        </div>
      </div>
    </div>
  );
};
NoAttachment.displayName = "Read.NoAttachment";
