import { AxiosResponse } from "axios";
import { client } from "./client";
import { objectKeysToCamelCase } from "../lib/common";
import { ContainerType } from "../types/container";

export const enum ContainerTypeQueryKeys {
  ContainerTypes = "containerTypes",
}

export async function fetchContainerTypes({ signal = undefined }: { signal: AbortSignal | undefined }) {
  const response: AxiosResponse = await client.get("/core/api/v1/erl/container_type", {
    signal,
  });
  const containerTypes = objectKeysToCamelCase<ContainerType[]>(response.data);
  return containerTypes;
}
