import classNames from "classnames";
import { uniqueId } from "lodash";
import * as React from "react";

interface Props {
  label: React.ReactNode;
  description?: React.ReactNode;
  onCheck: (checked: boolean) => void;
  checked: boolean;
  className?: string;
  classes?: Partial<{
    label: string;
    description: string;
    input: string;
  }>;
}

export const RadioButton: React.FC<Props> = (props) => {
  const { label, description, onCheck, checked, className, classes } = props;
  const id = uniqueId("radio-button");

  const toggle = React.useCallback(() => onCheck(!checked), [checked, onCheck]);

  return (
    <label htmlFor={id} className={`relative flex items-center space-x-2 cursor-pointer! ${className}`}>
      <div className="flex h-full items-center">
        <input
          id={id}
          aria-describedby={description ? `${id}-description` : undefined}
          name={id}
          type="radio"
          className={classNames("h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500", classes?.input)}
          checked={checked}
          onChange={toggle}
        />
      </div>
      <div>
        <div className={classNames("font-medium text-gray-900", classes?.label)}>{label}</div>
        {description && (
          <p id={`${id}-description`} className={classNames("text-gray-500", classes?.description)}>
            {description}
          </p>
        )}
      </div>
    </label>
  );
};
RadioButton.displayName = "RadioButton";
