import * as React from "react";
import { BannerAlert } from "../../../../components/BannerAlert";

const UIIA_URL = "https://uiia.intermodal.org/documents/uiia/newuiia-Home.pdf";

interface Props {
  show: boolean;
  onDismiss: () => void;
}

export const UIIABanner: React.FC<Props> = ({ show, onDismiss }) => {
  if (!show) return null;

  return (
    <BannerAlert variant="informative" title="Disputing with UIIA" onDismiss={() => onDismiss()}>
      <h4>
        According to the UIIA, SSL must invoice carriers within 60 days of equipment return in order to charge them per
        diem, otherwise the charges can be waived.{" "}
        <a href={UIIA_URL} target="_blank" rel="noreferrer" className="underline">
          Learn more about the UIIA here →
        </a>
      </h4>
    </BannerAlert>
  );
};
UIIABanner.displayName = "UIIABanner";
