import { objectKeysToCamelCase } from "../lib/common";
import { User } from "../types";
import { client } from "./client";

export const enum UserQuery {
  SignIn = "signIn",
  Profile = "profile",
}

interface SignInResponse {
  accessToken: string;
  refreshToken: string;
  email: string;
  // there are more fields but they are irrelevant
}

/** Use the login credentials to get the access and refresh tokens */
export const signIn = async (
  data: { email: string; password: string },
  signal?: AbortSignal
): Promise<SignInResponse> => {
  const url = "core/api/v1/auth/signin";
  const response = await client.post(url, data, { signal, headers: { Authorization: "" } });
  return objectKeysToCamelCase(response.data);
};

/** Get the user's profile with their current tokens */
export const fetchProfile = async (signal?: AbortSignal): Promise<User> => {
  const url = "core/api/v1/auth/profile";
  const response = await client.get(url, { signal });
  return objectKeysToCamelCase<User>(response.data);
};
