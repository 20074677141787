import * as React from "react";

/** Renders a large outlined envelope */
export const Envelope: React.FC = () => {
  return (
    <svg width="328" height="253" viewBox="0 0 328 253" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M326.5 39V214C326.5 234.711 309.711 251.5 289 251.5H39C18.2893 251.5 1.5 234.711 1.5 214V39M326.5 39C326.5 18.2893 309.711 1.5 289 1.5H39C18.2893 1.5 1.5 18.2893 1.5 39M326.5 39V43.0452C326.5 56.0675 319.744 68.1574 308.654 74.9824L183.654 151.905C171.601 159.323 156.399 159.323 144.346 151.905L19.3463 74.9824C8.25582 68.1574 1.5 56.0675 1.5 43.0452V39"
        stroke="#0033E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Envelope.displayName = "Envelope";
