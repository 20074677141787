import * as React from "react";

interface Props {
  bodyHeader: string;
  bodyValue: string;
}

export const Range: React.FC<Props> = ({ bodyHeader, bodyValue }) => {
  return (
    <div className="font-medium flex justify-between gap-4">
      <h4 className="text-text-subdued text-left">{bodyHeader}</h4>
      <h4 className="text-text-secondary text-right">{bodyValue}</h4>
    </div>
  );
};
Range.displayName = "OSRA.Rates.Range";
