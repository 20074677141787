import classNames from "classnames";
import * as React from "react";

type BadgeColor = "orange";

interface Props {
  /** Color scheme of badge  */
  color: BadgeColor;
  /** Icon to show */
  icon?: React.ReactNode;
  /** Label to show */
  label: React.ReactNode;
  /** Additional classes */
  className?: string;
}

const COLOR_SCHEME: Record<BadgeColor, { icon: string; border: string; text: string }> = {
  orange: {
    icon: "text-icon-informative-warning",
    border: "border-interactive-secondary",
    text: "text-text-informative-warning",
  },
};

export const IconBadge: React.FC<Props> = (props) => {
  const { color, icon, label, className } = props;

  const colorScheme = COLOR_SCHEME[color];

  return (
    <div className={classNames("border rounded flex items-center gap-1 px-1", colorScheme.border, className)}>
      {icon && <div className={classNames("[&>*]:h-4", colorScheme.icon)}>{icon}</div>}
      <h5 className={classNames("whitespace-nowrap", colorScheme.text)}>{label}</h5>
    </div>
  );
};
IconBadge.displayName = "IconBadge";
