import * as React from "react";
import { Button } from "../../../components/Button";

/* global Office */

interface Props {
  attachment: Office.AttachmentDetails;
  loading?: boolean;
  processInvoice: (attachment: Office.AttachmentDetails) => void;
}

export const SingleAttachment: React.FC<Props> = ({ attachment, loading, processInvoice }) => {
  return (
    <Button className="w-full" onClick={() => processInvoice(attachment)} loading={loading}>
      <h3 className="font-medium text-lg">Process {attachment.name}</h3>
    </Button>
  );
};
SingleAttachment.displayName = "Read.WithAttachments.SingleAttachment";
