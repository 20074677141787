import * as React from "react";
import { User } from "../../types";

interface AuthContextType {
  user: User | null;
  signOut: () => void;
}

export const AuthContext = React.createContext<AuthContextType>({ user: null, signOut: () => {} });

export const useUserContext = () => {
  return React.useContext(AuthContext);
};
