import * as React from "react";
import { NoAttachment } from "./NoAttachment";
import { WithAttachments } from "./WithAttachments";
import { useEmailContext } from "../../lib/email-context";
import UpgradeBanner from "../../components/UpgradeBanner";
import { Permission, useHasPermission } from "../../lib/permissions";

/**
 * Renders the page that is loaded when the user has a message selected
 */
export const Read: React.FC = () => {
  const { attachments, emailId } = useEmailContext();
  const hasAttachments = attachments && attachments.length > 0;
  const hasPermission = useHasPermission([Permission.PerDiemDisputeTracker]);

  if (!hasPermission) {
    return (
      <div className="mx-5 my-4 flex-grow">
        <UpgradeBanner
          title="Upgrade to Pro to start disputing!"
          description="Validate your invoices and dispute your per diem invoices today with BlueCargo's Pro plan."
        />
      </div>
    );
  }

  // If there are no attachments render the no attachment page
  if (!hasAttachments || !emailId)
    return (
      <div className="flex-grow">
        <NoAttachment />
      </div>
    );

  return (
    <div className="flex-grow">
      <WithAttachments emailId={emailId} attachments={attachments} />
    </div>
  );
};
Read.displayName = "Read";
