import classNames from "classnames";
import * as React from "react";

type BadgeColor = "orange" | "green" | "gray";

interface Props {
  /** Color scheme of badge  */
  color: BadgeColor;
  /** Icon to show */
  icon?: React.ReactNode;
  /** Label to show */
  label: React.ReactNode;
  /** Additional classes */
  className?: string;
}

const COLOR_SCHEME: Record<BadgeColor, { icon: string; background: string; text: string }> = {
  orange: {
    icon: "text-text-informative-warning",
    background: "bg-surface-informative-warning",
    text: "text-text-informative-warning",
  },
  green: {
    icon: "text-icon-informative-success",
    background: "bg-surface-informative-success",
    text: "text-text-informative-success",
  },
  gray: {
    icon: "text-interactive-solid-secondary",
    background: "bg-surface-subdued",
    text: "text-interactive-solid-secondary",
  },
};

export const PillBadge: React.FC<Props> = (props) => {
  const { color, icon, label, className } = props;

  const colorScheme = COLOR_SCHEME[color];

  return (
    <div
      className={classNames(
        "rounded-full gap-1 inline-flex items-center px-2.5 py-0.5",
        colorScheme.background,
        className
      )}
    >
      {icon && <div className={classNames("[&>*]:h-4", colorScheme.icon)}>{icon}</div>}
      <h4 className={classNames("whitespace-nowrap text-sm font-medium", colorScheme.text)}>{label}</h4>
    </div>
  );
};
PillBadge.displayName = "PillBadge";
