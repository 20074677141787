import { Combobox } from "@headlessui/react";
import { ByComparator } from "@headlessui/react/dist/types";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import * as React from "react";

interface Props {
  label: React.ReactNode;
  value: string | null;
  onChange: (value: string) => void;
  onFilter: (filter: string) => void;
  children: React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  by?: ByComparator<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayValue?: (item: any) => string;
  className?: string;
  id?: string;
}

export const ComboboxSelector: React.FC<Props> = (props) => {
  const {
    label,
    value,
    onChange,
    disabled,
    placeholder,
    children,
    onFilter,
    startAdornment,
    endAdornment,
    by,
    displayValue,
    className,
    id,
  } = props;

  const handleChange = React.useCallback(
    (v: string) => {
      onChange(v);
      onFilter("");
    },
    [onChange, onFilter]
  );

  return (
    <Combobox
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
      as="div"
      className={classNames("flex flex-col gap-1 relative", className)}
      by={by}
    >
      <Combobox.Label>
        <h4 className="font-medium text-text-accent-1">{label}</h4>
      </Combobox.Label>
      <div className="border overflow-hidden border-default rounded flex items-center focus-within:border-blue-800 bg-white">
        {startAdornment && <div className="flex-shrink-0">{startAdornment}</div>}
        {/* This wonky wrapping of the input in a button allows the dropdown to open on focus
        https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969 */}
        <Combobox.Button as="div" className="flex-grow">
          <Combobox.Input
            className="ring-0 px-0 text-sm border-none w-full h-8 px-2"
            onChange={(e) => onFilter(e.target.value)}
            placeholder={placeholder}
            displayValue={displayValue}
            id={id}
          />
        </Combobox.Button>
        <Combobox.Button>
          <ChevronUpDownIcon className="w-6 h-6" />
        </Combobox.Button>
        {endAdornment && <div className="flex-shrink-0 mr-2">{endAdornment}</div>}
      </div>
      <Combobox.Options className="bg-white rounded border p-1 shadow-md absolute z-[1000] top-16 max-h-[40vh] overflow-y-auto">
        {children}
        {children && (children as React.ReactNode[]).length === 0 && (
          <div className="text-text-accent-4 px-2 py-1">No matches found</div>
        )}
      </Combobox.Options>
    </Combobox>
  );
};
ComboboxSelector.displayName = "TailwindUI.ComboboxSelector";
