import * as React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Tooltip } from "./Tooltip";

interface Props {
  children: React.ReactNode;
  show?: boolean;
  size?: "sm" | "md";
  className?: string;
}

/**
 * This is a stub for the MUI Tooltip component.
 */
export const InformationalTooltip: React.FC<Props> = (props) => {
  const { children, show, size, className } = props;

  let width = 1.3;
  switch (size) {
    case "sm":
      width = 1;
      break;
    default:
      break;
  }

  return (
    <Tooltip tooltip={<h4>{children}</h4>}>
      <QuestionMarkCircleIcon
        width={`${width}em`}
        // Defaults to show
        className={classNames(
          "text-gray-300 hover:text-gray-500 cursor-pointer transition flex-shrink-0",
          {
            "opacity-0 pointer-events-none": show === false,
            "opacity-100 pointer-events-auto": show === undefined || show === true,
          },
          className
        )}
      />
    </Tooltip>
  );
};
InformationalTooltip.displayName = "InformationalTooltip";
