import _ from "lodash";
import { DateTime } from "luxon";
import { InvoiceContainer } from "../types/dispute";

export const objectKeysToSnakeCase = <R = any, S = any>(obj: S, deep = false): R => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.entries(obj).reduce((carry, [key, value]) => {
    if (deep && !_.isArray(value) && !_.isNil(value) && _.isObject(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      carry[_.snakeCase(key)] = objectKeysToSnakeCase(value, deep);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      carry[_.snakeCase(key)] = value;
    }
    return carry;
  }, {}) as R;
};

export const objectKeysToCamelCase = <R = any, S = any>(obj: S): R => {
  if (_.isArray(obj)) {
    return obj.map((item) => objectKeysToCamelCase(item)) as R;
  } else if (!_.isObject(obj)) {
    return obj as unknown as R;
  }
  return Object.entries(obj).reduce((carry, [key, value]) => {
    if (_.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      carry[_.camelCase(key)] = value.map((v) => objectKeysToCamelCase(v));
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      carry[_.camelCase(key)] = objectKeysToCamelCase(value);
    }
    return carry;
  }, {}) as R;
};

export const joinArrayParams = (value?: string[]) => {
  if (Array.isArray(value)) {
    return value.join(",");
  }
  return value;
};

export const calculateInvoiceContainerDateRange = (invoiceContainer: Pick<InvoiceContainer, "rates">) => {
  if (!invoiceContainer?.rates?.length || invoiceContainer.rates.length === 0) {
    return { startDate: undefined, endDate: undefined };
  }

  const startDate = DateTime.min(
    ...invoiceContainer.rates.filter((r) => r.startDate && !r.isFreeTime).map((r) => DateTime.fromISO(r.startDate))
  );
  const endDate = DateTime.max(
    ...invoiceContainer.rates.filter((r) => r.endDate && !r.isFreeTime).map((r) => DateTime.fromISO(r.endDate))
  );

  if (!startDate || !startDate.isValid || !endDate || !endDate.isValid) {
    return { startDate: undefined, endDate: undefined, days: undefined };
  }

  const days =
    invoiceContainer.rates.filter((r) => !r.isFreeTime && r.days).reduce((acc, r) => acc + r.days || 0, 0) ||
    endDate.diff(startDate, "days").days;

  return { startDate, endDate, days };
};
