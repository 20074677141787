import * as React from "react";
import { Footer } from "./Footer/Footer";

/** Wrap your page in this if you want a footer */
export const FooterWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      {children}
      <Footer />
    </>
  );
};
FooterWrapper.displayName = "FooterWrapper";
