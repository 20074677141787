/* global Office */

/** Hash a string with sha256 */
const sha256 = async (value: string) => {
  // Convert to byte array
  const buffer = new TextEncoder().encode(value);
  // Hash the message
  // eslint-disable-next-line no-undef
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  // Convert back to printable hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
};

/** Generate a unique, constant, id for an attachment */
export const uniqueIdForAttachment = async (attachment: Office.AttachmentDetails, emailId: string) => {
  const raw = `${emailId}-${attachment.name}-${attachment.size}`;
  return await sha256(raw);
};
