import * as React from "react";
import { BannerAlert } from "../../../../components/BannerAlert";

const OSRA_URL = "https://www.congress.gov/117/plaws/publ146/PLAW-117publ146.pdf";

interface Props {
  show: boolean;
  onDismiss: () => void;
}

export const OSRABanner: React.FC<Props> = ({ show, onDismiss }) => {
  if (!show) return null;

  return (
    <BannerAlert variant="informative" title="Disputing with OSRA" onDismiss={() => onDismiss()}>
      <h4>
        The OSRA 2022 Act states that D&D invoices must include 13 points in order to charge carriers per diem.
        Otherwise the invoice should be waived.{" "}
        <a href={OSRA_URL} target="_blank" rel="noreferrer" className="underline">
          Learn more about OSRA here →
        </a>
      </h4>
    </BannerAlert>
  );
};
OSRABanner.displayName = "OSRABanner";
