import { SparklesIcon } from "@heroicons/react/20/solid";
import React from "react";

interface Props {
  title: string;
  description: string;
}

const UpgradeBanner: React.FC<Props> = (props) => {
  const { title, description } = props;
  return (
    <div className="gap-4 flex-col flex px-8 py-4 bg-orange-50 rounded shadow-sm border border-orange-500">
      <p className="font-medium text-text-primary text-base">{title}</p>
      <p className="text-sm text-text-secondary leading-snug">{description}</p>
      <a
        className="w-full flex items-center justify-center gap-1.5 py-1 !bg-white hover:!bg-gray-50 rounded-sm shadow-sm text-text-interactive-outline-secondary border border-text-interactive-outline-secondary"
        href="https://erl.bluecargo.io/settings/plans-and-pricing?action=change"
        target="_blank"
        rel="noreferrer"
      >
        <SparklesIcon className="w-4 h-4" />
        <div className="font-medium">Upgrade now</div>
      </a>
    </div>
  );
};

export default UpgradeBanner;
