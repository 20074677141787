import { TruckIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import * as React from "react";

type Variant = "contained" | "outlined" | "text";
type Color = "blue" | "orange" | "gray" | "red";
type Size = "sm" | "md";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
  variant?: Variant;
  color?: Color;
  size?: Size;
}

const COLOR_CLASSES: Record<Variant, Record<Color, string>> = {
  contained: {
    blue: "bg-surface-interactive-accent-1 text-text-interactive-accent-1 hover:bg-blue-600 active:bg-blue-900",
    red: "bg-red-600 hover:bg-red-700 active:bg-red-900",
    orange: "bg-orange-600 hover:bg-orange-700 active:bg-orange-900",
    gray: "border-text-interactive-accent-4 text-text-interactive-accent-4 hover:bg-gray-100 active:bg-gray-200",
  },
  outlined: {
    blue: "border-surface-interactive-accent-1 text-text-interactive-accent-2 hover:bg-gray-100 active:bg-gray-200",
    red: "border-red-600 text-red-600 hover:bg-red-100 active:bg-red-200",
    orange:
      "border-border-interactive-accent-2 text-text-interactive-accent-3 hover:bg-orange-100 active:bg-orange-200",
    gray: "border-text-interactive-accent-4 text-text-interactive-accent-4 hover:bg-gray-100 active:bg-gray-200",
  },
  text: {
    blue: "text-text-interactive-accent-2 hover:bg-gray-100 active:bg-gray-200",
    red: "text-red-600 hover:bg-red-100 active:bg-red-200",
    orange: "text-text-interactive-accent-3 hover:bg-orange-100 active:bg-orange-200",
    gray: "text-text-interactive-accent-4 hover:bg-gray-100 active:bg-gray-200",
  },
};

const VARIANT_CLASSES: Record<Variant, string> = {
  contained:
    "disabled:!bg-gray-300 disabled:!text-gray-600 disabled:cursor-default disabled:shadow-none shadow-sm disabled:border-none",
  outlined:
    "disabled:border-text-interactive-accent-4 disabled:bg-surface-interactive-disabled-accent-1 disabled:text-text-interactive-disabled-accent-1 disabled:cursor-default disabled:shadow-none border shadow-sm",
  text: "",
};

const SIZE_CLASSES: Record<Size, string> = {
  sm: "px-4 py-2",
  md: "px-6 py-3",
};

/**
 * General button component; use in favor of MUI buttons whenever possible
 */
export const Button: React.FC<Props> = (props) => {
  const { children, className, loading, disabled, variant, color, size, ...rest } = props;
  return (
    <button
      type="button"
      disabled={disabled || loading}
      className={classNames(
        VARIANT_CLASSES[variant || "contained"],
        COLOR_CLASSES[variant || "contained"][color || (variant && variant === "outlined" ? "gray" : "blue")],
        variant === "text" ? "p-1" : SIZE_CLASSES[size || "md"],
        "flex items-center justify-center transition-all cursor-pointer select-none rounded",
        className
      )}
      {...rest}
    >
      {loading && <TruckIcon className="h-6 animate-bounce absolute" />}
      <div className={loading ? "opacity-20" : undefined}>{children}</div>
    </button>
  );
};
Button.displayName = "TailwindUI.Button";
