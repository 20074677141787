import { Menu } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { useUserContext } from "../AuthProvider/context";
import classNames from "classnames";
import { AccountModal } from "./AccountModal";
import { AnalyticsEvent, useAnalytics } from "../../lib/segment";

/* global Office */

const MenuItem: React.FC<{ onClick: () => void; disabled?: boolean; children: React.ReactNode }> = ({
  children,
  disabled,
  onClick,
}) => {
  return (
    <Menu.Item
      as="div"
      className={classNames(
        "px-3 py-2 cursor-pointer transition select-none",
        disabled ? "opacity-50 cursor-auto" : "hover:bg-gray-100"
      )}
      onClick={onClick}
    >
      <h4 className="font-medium">{children}</h4>
    </Menu.Item>
  );
};
MenuItem.displayName = "FooterMenu.MenuItem";

const MenuLink: React.FC<{ href: string; children: React.ReactNode; onClick?: () => void }> = ({
  children,
  href,
  onClick,
}) => {
  return (
    <Menu.Item
      as="a"
      href={href}
      target="_blank"
      rel="noreferrer"
      className="block px-3 py-2 cursor-pointer transition select-none hover:bg-gray-100"
      onClick={onClick}
    >
      <h4 className="font-medium">{children}</h4>
    </Menu.Item>
  );
};
MenuLink.displayName = "FooterMenu.MenuLink";

export const FooterMenu: React.FC = () => {
  const { user, signOut } = useUserContext();
  const [isProfileOpen, setIsProfileOpen] = React.useState(false);
  const track = useAnalytics();
  return (
    <>
      <AccountModal open={isProfileOpen} onClose={() => setIsProfileOpen(false)} />
      <Menu>
        <Menu.Button>
          <Cog6ToothIcon className="h-6" />
        </Menu.Button>
        <Menu.Items as="div" className="absolute bottom-12 bg-white rounded border shadow-md py-1">
          <MenuItem onClick={() => setIsProfileOpen((o) => !o)} disabled={!user}>
            Account
          </MenuItem>
          <MenuLink href="https://erl.bluecargo.io" onClick={() => track(AnalyticsEvent.GoToBlueCargo, {})}>
            Go to BlueCargo
          </MenuLink>
          {Office.context.mailbox?.displayNewMessageForm ? (
            <MenuItem
              onClick={() => {
                track(AnalyticsEvent.SendFeedback, undefined);
                Office.context.mailbox.displayNewMessageForm({
                  toRecipients: ["support@bluecargo.io"],
                  subject: "Feedback for BlueCargo",
                });
              }}
            >
              Send feedback
            </MenuItem>
          ) : (
            <MenuLink
              href="mailto:support@bluecargo.io"
              onClick={() => {
                track(AnalyticsEvent.SendFeedback, undefined);
              }}
            >
              Send feedback
            </MenuLink>
          )}
          <MenuItem onClick={signOut} disabled={!user}>
            Log out
          </MenuItem>
        </Menu.Items>
      </Menu>
    </>
  );
};
FooterMenu.displayName = "FooterMenu";
