import * as React from "react";
import { Invoice, InvoiceContainer, InvoiceUploadJob, TaggedInvoiceContainer } from "../../types/dispute";
import { InvoiceValidity } from "./types";
import { isInvoiceValid, validateInvoice } from "./validation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ContainerTypeQueryKeys, fetchContainerTypes } from "../../services/container";
import { ShippingLineQueryKeys, fetchShippingLines } from "../../services/shipping-line";
import { DisputesQueryKey, saveAllInvoiceMappings } from "../../services/disputes";
import {
  AnalyticsEvent,
  invoiceContainerToInvoiceContainerGroup,
  invoiceContainerToShipmentGroup,
  invoiceToInvoiceGroup,
  useAnalytics,
} from "../../lib/segment";

// Custom hook for fetching container sizes.
const useContainerSizes = () => {
  return useQuery([ContainerTypeQueryKeys.ContainerTypes], ({ signal }) => fetchContainerTypes({ signal }));
};

// Custom hook for fetching shipping lines.
const useShippingLines = () => {
  return useQuery([ShippingLineQueryKeys.ShippingLines], ({ signal }) => fetchShippingLines({ signal }));
};

// Custom hook for validating invoices.
export const useValidateInvoices = (invoices: Invoice[]): { validity: InvoiceValidity[]; canSave: boolean } => {
  const containerSizes = useContainerSizes();
  const shippingLines = useShippingLines();

  return React.useMemo(() => {
    const validity = invoices.map((invoice) => validateInvoice(invoice, shippingLines.data, containerSizes.data));

    const canSave = validity.every((invoiceValidity) => {
      return isInvoiceValid(invoiceValidity);
    });

    return { validity, canSave };
  }, [invoices, containerSizes.data, shippingLines.data]);
};

export const useHandleSaveMappings = (
  jobQueryData: InvoiceUploadJob | undefined,
  invoices: Invoice[],
  onSuccess: (containers: InvoiceContainer[]) => void
) => {
  const queryClient = useQueryClient();
  const track = useAnalytics();

  const saveAllMappings = useMutation(saveAllInvoiceMappings, {
    onSuccess: async (results) => {
      queryClient.invalidateQueries({ queryKey: [DisputesQueryKey.InvoiceJob, jobQueryData?.id] });
      // Flatten the results to get all invoice containers
      const allInvoiceContainers = results.flatMap((r) => r.invoiceContainers);
      onSuccess(allInvoiceContainers);
    },
  });

  const save = React.useCallback(() => {
    const pairs = invoices.map((modified) => {
      const original = jobQueryData?.parsedInvoices.find((invoice) => invoice.id === modified.id);
      return { original, modified };
    });

    // Add any original invoices that were deleted
    const deleted =
      jobQueryData?.parsedInvoices
        .filter((invoice) => !invoices.some((i) => i.id === invoice.id))
        .map((i) => ({ original: i })) || [];

    // Fire analytics event
    const invoice = invoices[0];
    track(AnalyticsEvent.VerifiedInvoice, {
      invoiceVerifiedSource: "email plug-in",
      invoice: invoiceToInvoiceGroup(invoice),
      containers: (invoice?.invoiceContainers || []).map((c) => ({
        ...invoiceContainerToInvoiceContainerGroup(c),
        ...invoiceContainerToShipmentGroup(c as TaggedInvoiceContainer),
      })),
    });

    saveAllMappings.mutate([...pairs, ...deleted]);
  }, [jobQueryData, invoices, saveAllMappings]);

  return [save, saveAllMappings.isLoading, saveAllMappings.error] as const;
};
