import * as React from "react";
import { InvoiceUploadJob } from "../../../types/dispute";
import { MultipleAttachments } from "./MultipleAttachments";
import { useMutation } from "@tanstack/react-query";
import { uploadAttachment } from "../../../services/outlook";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "../../../lib/routes";
import { SingleAttachment } from "./SingleAttachment";
import { useJobMapping } from "./hooks";

/* global Office */

interface Props {
  emailId: string;
  attachments: Office.AttachmentDetails[];
  jobs: InvoiceUploadJob[];
  additionalExistingAttachments: Office.AttachmentDetails[];
}

export const NewJob: React.FC<Props> = ({ emailId, attachments, jobs, additionalExistingAttachments }) => {
  const jobMapping = useJobMapping(attachments);

  // Get only the attachments that don't have a job already associated
  const unstartedAttachments = React.useMemo(() => {
    return (jobMapping || [])
      .filter((a) => !jobs.some((j) => j.secondaryExternalId === a.secondaryExternalId))
      .map((a) => a.attachment)
      .filter((a) => !additionalExistingAttachments.includes(a));
  }, [attachments, jobs, jobMapping]);

  const navigate = useNavigate();

  const uploadAttachmentMutation = useMutation({
    mutationFn: uploadAttachment,
    onSuccess: (data) => {
      const to = generatePath(Routes.Process, { invoiceJobId: data.id });
      navigate(to);
    },
  });

  const handleProcessInvoice = (attachment: Office.AttachmentDetails) => {
    uploadAttachmentMutation.mutate({ attachment, emailId });
  };

  if (unstartedAttachments.length === 0) {
    return null;
  }

  let inner = <MultipleAttachments attachments={unstartedAttachments} processInvoice={handleProcessInvoice} />;
  if (unstartedAttachments.length === 1) {
    inner = (
      <SingleAttachment
        attachment={unstartedAttachments[0]}
        loading={uploadAttachmentMutation.isLoading}
        processInvoice={handleProcessInvoice}
      />
    );
  }

  return (
    <div className="w-full space-y-8">
      {inner}
      {uploadAttachmentMutation.isError && (
        <p className="break-words w-full text-text-informative-critical">
          Error while uploading attachment: {JSON.stringify(uploadAttachmentMutation.error)}
        </p>
      )}
    </div>
  );
};
NewJob.displayName = "Read.WithAttachments.NewJob";
